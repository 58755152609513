import { makeStyles, Typography, Box } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, useHistory } from "react-router";
import TalentButton from "./TalentButton";

const useStyle = makeStyles({
  container: {
    backgroundColor: "#dc5145",
    borderRadius: 10,
    //flexGrow: "5",
    textAlign: "center",
    color: "#ffffff",
    margin: "1em",
    padding: "2em",
    //width: "calc(60% - 2em)",
    flex: 2,
    minWidth: "300px",
    boxShadow: "0 2em 4em -2em rgba(34,71,171, 0.2)",
    border: "solid 1px rgba(0,0,0, .2)",
    //maxWidth:"60%",
  },
  line: {
    display: "flex",
    justifyContent: "center",
    margin: "1rem 0 2rem 0",
    "&:after": {
      content: "''",
      width: "30%",
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: "#ffffff",
    },
  },
  title: {
    fontFamily: "EBGaramond",
    fontSize: 36,
    fontWeight: "500",
    fontStyle: "normal",
    letterSpacing: 0,
  },
  subtitle: {
    fontFamily: "OpenSans",
    fontSize: 16,
    fontWeight: "600",
    fontStyle: "normal",
    letterSpacing: 0,
  },
  price: {
    fontFamily: "OpenSans",
    fontSize: 24,
    fontWeight: "bold",
    fontStyle: "normal",
    letterSpacing: 0,
  },
  description: {
    fontFamily: "OpenSans",
    fontSize: 16,
    fontWeight: "normal",
    fontStyle: "italic",
    letterSpacing: 0,
  },
});

const TalentPricingCard = ({ total, deposit, serviceLabel }) => {
  // Load classes
  const classes = useStyle();

  // Load translation
  const { t } = useTranslation();
  const history = useHistory();

  const [redirect, setRedirect] = useState(false);

  return (
    <div className={classes.container}>
      {redirect && <Redirect to="/paiement-cgv" />}
      <Typography className={classes.title}>{t("Pricing")}</Typography>
      <Box margin="1rem 0 1rem 0">
        <Typography className={classes.subtitle}>
          {t("PricingDesc", { serviceLabel: serviceLabel })}
        </Typography>
      </Box>

      <Typography className={classes.price}>
        {total}
        {t("PriceTTC")}
      </Typography>
      <div className={classes.line}></div>
      <Box margin="3rem 0 3rem 0">
        <Typography className={classes.description}>
          {t("PricingCompleteDesc", { amount: deposit })}
        </Typography>
      </Box>

      <Box margin="2rem 0 1rem 0">
        <TalentButton
          variant="contained"
          style={{
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: "#ffffff",
            boxShadow: "none",
          }}
          onClick={() => {
            history.push("/");
            setRedirect(true);
          }}
        >
          {t("Continue")}
        </TalentButton>
      </Box>
    </div>
  );
};

export default TalentPricingCard;
