import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import TalentActionCard from "../core/TalentActionCard";
import TalentDashboardTitle from "../core/TalentDashboardTitle";
import TalentPricingCard from "../core/TalentPricingCard";
import TalentHeaderContainer from "../core/layout/TalentHeaderContainer";
import TalentContentContainer from "../core/layout/TalentContentContainer";

const ReviewPaiement = ({ propal, depositPrice }) => {
  // Load translation
  const { t } = useTranslation();

  const [firstline, setFirstline] = useState(null);

  useEffect(() => {
    if (propal.lines !== null) {
      setFirstline(propal.lines[0]);
    }
  }, [propal]);

  return (
    <>
      <TalentHeaderContainer>
        <TalentDashboardTitle title={t("ReviewPaiementTitle")} />
      </TalentHeaderContainer>

      <TalentContentContainer>
        <TalentActionCard
          btnContent={t("PropalDetail")}
          title={firstline && firstline.label}
          description={firstline && firstline.short_description}
          redirectTo="/detail-flatfee"
        />

        <TalentPricingCard
          total={propal.total}
          deposit={depositPrice}
          serviceLabel={firstline && firstline.label}
        />
      </TalentContentContainer>
    </>
  );
};

export default ReviewPaiement;
