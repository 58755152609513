import React from "react";
import { useTranslation } from "react-i18next";
import sentImg from "../../assets/img/assets-img-cook.svg";
import TalentBlueContainer from "../core/layout/TalentBlueContainer";

const Step2NotConfirmed = () => {
  // Load translation
  const { t } = useTranslation();

  return (
    <TalentBlueContainer
      img={sentImg}
      imgAlt="Waiting for appointment"
      title={t("S2WaitingForAppointment")}
    >
      {t("S2MailReceived")}
    </TalentBlueContainer>
  );
};

export default Step2NotConfirmed;
