import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography, makeStyles } from "@material-ui/core";
import MessageIcon from "@material-ui/icons/Message";
import { useTranslation } from "react-i18next";
import moment from "../../services/moment.js";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    margin: "1rem",
    padding: "0.5rem 2rem",
  },
  details: {
    marginLeft: "2vw",
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
}));

const NotificationCard = (props) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const [formatedDate, setFormatedDate] = useState(null);

  useEffect(() => {
    if (props.date !== undefined) {
      moment.locale(i18n.language);
      setFormatedDate(moment(props.date).fromNow());
    }
  }, [props, i18n]);

  return (
    <Card className={classes.root}>
      <MessageIcon />
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography variant="h6">{t("NewMessage")}</Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {t("Received")}
            {formatedDate}
          </Typography>
        </CardContent>
      </div>
    </Card>
  );
};

export default NotificationCard;
