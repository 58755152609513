import React, { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import TalentDashboardTitle from "../core/TalentDashboardTitle";
import TalentButton from "../core/TalentButton";
import { useTranslation } from "react-i18next";
import TalentActionCard from "../core/TalentActionCard";
import { Switch, Route } from "react-router-dom";
import ReportViewer from "./ReportViewer";
// import DocumentCompletion from "./DocumentComplemention";
import projectService from "../../services/project.service";
import { useAuth } from "../../context/auth-context";
import PropalViewer from "./PropalViewer";
import TalentContentContainer from "../core/layout/TalentContentContainer";
import TalentFooterContainer from "../core/layout/TalentFooterContainer";
import TalentHeaderContainer from "../core/layout/TalentHeaderContainer";

const Step4 = ({ reloadParent }) => {
  // Load translation
  const { t } = useTranslation();

  // Load auth
  const auth = useAuth();

  // State
  const [loading, setLoading] = useState(false);
  const [activeButton, setActiveButton] = useState(false);
  const [report, setReport] = useState(null);
  const [reloadCard, setReloadCard] = useState(true);

  // Component mount + reloadCard state update
  useEffect(() => {
    // Load the report
    projectService
      .getReport(auth.user)
      .then((res) => {
        setReport(res);
        // Issue #58 : Unactivate the document completion
        // if (res.is_document_completed === "1" && res.is_read === "1") {
        if (res.is_read === "1") {
          setActiveButton(true);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [auth, reloadCard]);

  // Handle click button
  const handleClick = () => {
    setLoading(true);
    projectService
      .validateFile(auth.user)
      .then((res) => {
        setLoading(false);
        // Success, we reload the parent
        reloadParent();
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  return (
    <Switch>
      <Route path="/report">
        <ReportViewer
          report={report}
          reloadParent={() => setReloadCard(!reloadCard)}
        />
      </Route>

      <Route path="/propal">
        <PropalViewer
          propal={report && report.propal}
          reloadParent={() => setReloadCard(!reloadCard)}
        />
      </Route>

      {/* Issue #58 : Unactivate the document completion */}
      {/* <Route path="/my-case">
        <DocumentCompletion documents={report && report.documents} />
      </Route> */}

      <Route path={["/", "/login"]} exact>
        <TalentHeaderContainer>
          <TalentDashboardTitle title={t("MyCase")} />
        </TalentHeaderContainer>

        <TalentContentContainer>
          <TalentActionCard
            btnContent={t("Validate")}
            title={t("S4Report")}
            description={t("S4ReportDesc")}
            redirectTo="/report"
            disabled={report && report.is_read === "1"}
          />

          {/* Issue #58 : Unactivate the document completion */}
          {/* <TalentActionCard
            btnContent={t("Complete")}
            title={t("S4Document")}
            description={t("S4DocumentDesc")}
            redirectTo="/my-case"
            disabled={report && report.is_document_completed === "1"}
          /> */}
        </TalentContentContainer>

        <TalentFooterContainer>
          <Box>
            <TalentButton
              onClick={handleClick}
              variant={activeButton ? "contained" : "outlined"}
              disabled={!activeButton || loading}
            >
              {t("Finalize")}
            </TalentButton>
          </Box>
        </TalentFooterContainer>
      </Route>
    </Switch>
  );
};

export default Step4;
