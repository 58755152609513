import React from "react";
import Step2NotConfirmed from "./Step2NotConfirmed";
import Step2Confirmed from "./Step2Confirmed";

const Step2 = (props) => {
  // Method used to display proper content in function of task progress
  const displayContent = () => {
    if (props.progress < 50) {
      return <Step2NotConfirmed />;
    } else {
      return <Step2Confirmed />;
    }
  };

  return displayContent();
};

export default Step2;
