import React from "react";
import {
  makeStyles,
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
} from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { Link } from "react-router-dom";

const useStyle = makeStyles({
  root: {
    padding: "1em",
    display: "flex",
    flexDirection: "column",
    borderRadius: "10px",
    justifyContent: "space-between",
    width: "calc(40% - 2em)",
    margin: "1em",
    minWidth: "300px",
    flex: 1,
    boxShadow: "0 2em 4em -2em rgba(34,71,171, 0.2)",
    border: "solid 1px rgba(0,0,0, .2)",
  },
  contentContainer: {
    flex: 2,
  },
  disabled: {
    opacity: ".5",
  },
  primaryText: {
    marginBottom: "1rem",
  },
  primaryTextDisabled: {
    opacity: ".5",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
});

const TalentActionCard = (props) => {
  // Load styles
  const classes = useStyle();

  return (
    <Card className={`${classes.root} ${props.disabled && classes.disabled}`}>
      <CardContent className={classes.contentContainer}>
        <Typography
          variant="h3"
          component="h2"
          color="primary"
          className={`${classes.primaryText} ${
            props.disabled && classes.primaryTextDisabled
          }`}
        >
          {props.title}
        </Typography>
        {!props.disabled && (
          <Typography variant="subtitle2" component="p" color="textSecondary">
            {props.description}
          </Typography>
        )}
      </CardContent>
      <CardActions className={classes.btnContainer}>
        {props.disabled ? (
          <CheckCircleOutlineIcon color="secondary" fontSize="large" />
        ) : (
          <Link to={props.redirectTo} style={{ textDecoration: "none" }}>
            <Button color="secondary" endIcon={<ArrowForwardIcon />}>
              {props.btnContent}
            </Button>
          </Link>
        )}
      </CardActions>
    </Card>
  );
};

export default TalentActionCard;
