import API from "./api";

class AuthService {
  /**
   * Variable used for encryption
   */
  crypto = require("crypto");
  algorithm = process.env.REACT_APP_CIPHER_ALGORITHM;
  password = process.env.REACT_APP_CIPHER_PASSWORD;
  iv = process.env.REACT_APP_CIPHER_IV;

  /**
   * Method to encrypt the text using algorithm, password and iv set in .env
   *
   * @param {string} text       Text to encrypt
   */
  encrypt = (text) => {
    var cipher = this.crypto.createCipheriv(
      this.algorithm,
      this.password,
      this.iv
    );
    var encrypted = cipher.update(text, "utf8", "hex");
    encrypted += cipher.final("hex");
    var tag = cipher.getAuthTag();
    return {
      content: encrypted,
      tag: tag,
    };
  };

  /**
   * Method to decrypt the object using algorithm, password and iv set in .env
   *
   * @param {Object} encrypted       Encrypted object to decrypt
   */
  decrypt = (encrypted) => {
    var decipher = this.crypto.createDecipheriv(
      this.algorithm,
      this.password,
      this.iv
    );
    decipher.setAuthTag(encrypted.tag);
    var dec = decipher.update(encrypted.content, "hex", "utf8");
    dec += decipher.final("utf8");
    return dec;
  };

  /**
   * Method used to register
   */
  register = async (registerData) => {
    const response = await API.post(
      "/talentdeveloper/register",
      registerData
    ).catch((err) => {
      // We can switch err.response.status for more details
      throw err;
    });
    return response;
  };

  /**
   * Method to authenticate
   */
  connect = async (email, password) => {
    let formData = new FormData();
    formData.append("login", email);
    formData.append("password", password);
    const response = await API.post("/talentdeveloper/login", formData).catch(
      (err) => {
        // We can switch err.response.status for more details
        throw err;
      }
    );
    return response;
  };

  /**
   * Get the token from an user
   *
   * @param {Object} user     User of the context provider
   */
  getToken = (user) => {
    if (user.token.tag.data !== undefined) {
      user.token.tag = user.token.tag.data;
    }
    return this.decrypt(user.token);
  };

  /**
   * Method used to get user informations.
   * Uses the /me endpoint.
   *
   * @param {Object} user       User of the context provider
   */
  getMe = async (user) => {
    const data = await API.get("/talentdeveloper/me", {
      headers: { DOLAPIKEY: this.getToken(user) },
    }).catch((err) => {
      // We can switch err.response.status for more details
      throw err;
    });

    return data;
  };

  /**
   * Method used to update user information.
   * Uses the /me endpoint.
   *
   * @param {Object} user           User of the context provider
   * @param {Object} informations   User informations
   */
  editMe = async (user, informations) => {
    const response = await API.put("/talentdeveloper/me", informations, {
      headers: { DOLAPIKEY: this.getToken(user) },
    }).catch((err) => {
      throw err;
    });

    return response.data.success.me;
  };

  /**
   * Method used to update user password while authenticated.
   * Uses the /me/password endpoint.
   *
   * @param {Object} user           User of the context provider
   * @param {Object} informations   User informations
   */
  editPassword = async (user, informations) => {
    const response = await API.put(
      "/talentdeveloper/me/password",
      informations,
      {
        headers: { DOLAPIKEY: this.getToken(user) },
      }
    ).catch((err) => {
      throw err;
    });

    return response.data.success.me;
  };

  /**
   * Method used to delete user from dolibarr.
   * Uses the /me endpoint.
   *
   * @param {Object} user       User of the context provider
   */
  delete = async (user) => {
    return await API.delete("/talentdeveloper/me", {
      headers: { DOLAPIKEY: this.getToken(user) },
    }).catch((err) => {
      throw new Error(
        "Error " + err.response.status + " : " + err.response.data.error.message
      );
    });
  };

  /**
   * Method used to send a reset password email.
   * Uses the /reset_password endpoint.
   *
   * @param {string} email       Email of user to reset
   */
  sendResetPassLink = async (email) => {
    const response = await API.get(
      "/talentdeveloper/reset_password?email=" + email,
      {}
    ).catch((err) => {
      throw err;
    });

    return response.data.success.message;
  };

  /**
   * Method used to reset the password.
   * Uses the /reset_password endpoint.
   *
   * @param {string} email       Email of user to reset
   * @param {string} password    New password to set
   * @param {string} token       Verification token
   */
  resetPassword = async (email, password, token) => {
    const response = await API.post("/talentdeveloper/reset_password", {
      token: token,
      email: email,
      password: password,
    }).catch((err) => {
      throw err;
    });

    return response.data.success.message;
  };
}

export default new AuthService();
