import React from "react";
import { Typography, makeStyles } from "@material-ui/core";

const useStyle = makeStyles({
  text: {
    width: "100%",
  },
});

const TalentSubtitle = (props) => {
  // Load style
  const classes = useStyle();

  return (
    <Typography variant="h3" component="h1" color="primary" className={classes.text}>
      {props.title}
    </Typography>
  );
};

export default TalentSubtitle;
