import React, { useState } from "react";
import {
  Backdrop,
  Button,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import projectService from "../../services/project.service";
import { useAuth } from "../../context/auth-context";
import Alert from "@material-ui/lab/Alert";
import { useTranslation } from "react-i18next";

const useStyle = makeStyles((theme) => ({
  buttonContainer: {
    width: "100%",
    flexGrow: "1",
    minWidth: "50%",
    padding: ".5em 1em",
    order: "0",
  },
  button: {
    width: "100%",
    padding: "1em",
    margin: "0",
    fontSize: 18,
    fontFamily: "EBGaramond",
    fontWeight: "bold",
    fontStyle: "normal",
    letterSpacing: 0,
    textTransform: "none",
    color: "#2247AB",
    borderColor: "currentColor",
    display: "flex",
    justifyContent: "space-between",
    textAlign: "left",
    "& .MuiButton-endIcon": {
      position: "relative",
    },
    "&:hover": {
      borderColor: "currentColor",
    },
  },
  buttonValidated: {
    color: "#858CA0",
  },
  buttonValidatedContainer: {
    order: "4",
    pointerEvents: "none",
    //display: "none",
  },
  buttonRefused: {
    background: "#DC5145",
    color: "#FFFFFF",
    "&:hover": {
      background: "#FFFFFF",
      color: "#DC5145",
    },
  },
  buttonRefusedContainer: {
    order: "0",
  },
  buttonWaiting: {
    color: "#DC5145",
  },
  buttonWaitingContainer: {
    order: "1",
    pointerEvents: "none",
  },
  buttonRefusedComment: {
    width: "100%",
    marginTop: "1vh",
    fontFamily: "OpenSans",
    fontSize: 12,
    fontWeight: "normal",
    fontStyle: "italic",
    letterSpacing: 0,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "rgba(211, 211, 211, 0.5)",
  },
  alertContainer: {
    marginTop: "1rem",
  },
}));

const DocumentUpload = ({ reloadParent }) => {
  // Load classes
  const classes = useStyle();

  // Load auth
  const auth = useAuth();

  // Load translation
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  // Handle input file change
  const handleChange = (event) => {
    setIsError(false);
    setIsLoading(true);
    const files = event.target.files;
    if (files[0] !== null) {
      console.log(files[0]);
      projectService
        .uploadDocument(auth.user, files[0])
        .then(() => {
          setIsLoading(false);
          reloadParent();
        })
        .catch((err) => {
          console.error(err);
          setIsLoading(false);
          setIsError(true);
        });
    }
  };

  return (
    <div className={`${classes.buttonContainer} td-buttonContainer`}>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="secondary" />
      </Backdrop>
      <input
        accept="application/pdf, image/*, application/msword, .docx, text/plain, application/vnd.ms-excel, application/vnd.ms-powerpoint"
        style={{ display: "none" }}
        id="upload-file-input"
        type="file"
        onChange={handleChange}
      />
      <label htmlFor="upload-file-input">
        <div>
          <Button
            variant="outlined"
            color="primary"
            component="span"
            endIcon={<CloudUploadIcon />}
            className={classes.button}
          >
            {t("UploadDocument")}
          </Button>
        </div>
      </label>
      <div className={classes.alertContainer}>
        {isError && <Alert severity="error">{t("ErrorUploadingImage")}</Alert>}
      </div>
    </div>
  );
};

export default DocumentUpload;
