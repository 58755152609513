import React, { useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { makeStyles, Typography } from "@material-ui/core";
import TalentWhiteContainer from "../core/layout/TalentWhiteContainer";
import TalentHeaderContainer from "../core/layout/TalentHeaderContainer";
import TalentDashboardTitle from "../core/TalentDashboardTitle";
import SigninComponent from "./SigninComponent";
import TalentButton from "../core/TalentButton";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
  loginContainer: {
    order: 2,
    flex: 1,
    margin: "1em",
    display: "flex",
    flexDirection: "column",
  },
  accountContainer: {
    order: 1,
    margin: "1em",
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
});

const SigninOrRegister = () => {
  // Load translation
  const { t } = useTranslation();

  // Load style
  const classes = useStyles();
  const history = useHistory();

  // State to redirect to register page
  const [redirectRegister, setRedirectRegister] = useState(false);

  return (
    <div className={classes.root}>
      {redirectRegister && <Redirect to="/register" />}

      {/* Login part */}
      <div className={classes.loginContainer}>
        <TalentHeaderContainer>
          <TalentDashboardTitle title={t("SCAlreadyRegister")} />
        </TalentHeaderContainer>
        <TalentWhiteContainer
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "space-around",
            flexDirection: "column",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h2" component="h2" color="primary">
            {t("Connect")}
          </Typography>
          <SigninComponent />
        </TalentWhiteContainer>
      </div>

      {/* Create account part*/}
      <div className={classes.accountContainer}>
        <TalentHeaderContainer>
          <TalentDashboardTitle title={t("SCFirstConnexion")} />
        </TalentHeaderContainer>

        <TalentWhiteContainer
          style={{
            background: "#0b1941",
            color: "white",
            display: "flex",
            flex: 1,
            justifyContent: "space-around",
            flexDirection: "column",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h2"
            component="h2"
            style={{
              marginBottom: "1em",
            }}
          >
            {t("SCJoin")}
          </Typography>
          <TalentButton
            variant="contained"
            onClick={() => {
              history.push("/");
              setRedirectRegister(true);
            }}
          >
            {t("SCCreateAccount")}
          </TalentButton>
        </TalentWhiteContainer>
      </div>
    </div>
  );
};

export default SigninOrRegister;
