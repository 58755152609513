import { makeStyles, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { PayPalButton } from "react-paypal-button-v2";
import { useAuth } from "../../context/auth-context";
import projectService from "../../services/project.service";
import TalentLoader from "../core/TalentLoader";
import { Redirect } from "react-router";
import { useAlert } from "react-alert";
import { useTranslation } from "react-i18next";
import Alert from "@material-ui/lab/Alert";
import TalentWhiteContainer from "../core/layout/TalentWhiteContainer";
import TalentHeaderContainer from "../core/layout/TalentHeaderContainer";
import TalentGoBackButton from "../core/TalentGoBackButton";
import TalentButton from "../core/TalentButton";
import TalentDividerLine from "../core/TalentDividerLine";
import TalentIcon from "../core/TalentIcon";

const useStyle = makeStyles({
  paypalButtonContainer: {
    minWidth: "60vw",
  },
  cmcicButtonContainer: {
    marginTop: "1em",
  },
});

const PaypalPaiement = ({ deposit, reloadParent }) => {
  // Load auth
  const auth = useAuth();

  // Load translation
  const { t } = useTranslation();

  // Load alert
  const alert = useAlert();

  // State
  const [loading, setLoading] = useState(false);
  const [btnReady, setBtnReady] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [internalError, setInternalError] = useState(false);

  // Function called after success
  const handlePaiementSuccess = (details, data) => {
    setLoading(true);
    data.paiementMethod = "paypal";
    const resume = JSON.stringify(data, null, 2);
    projectService
      .markDepositAsPaid(auth.user, resume)
      .then(() => {
        reloadParent();
        setRedirect(true);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        setInternalError(true);
        alert.show(t("MarkDepositAsPaidKo"), { type: "error" });
      });
  };

  // Function called after error
  const handlePaiementError = (err) => {
    console.error(err);
    alert.show(t("PaypalPaiementKo") + err.message, { type: "error" });
    setLoading(false);
  };

  const redirectCmcicPayment = () => {
    setLoading(true);
    projectService
      .getDepositLink(auth.user)
      .then((link) => {
        console.log(link);
        if (link) {
          window.location.href = link;
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        setInternalError(true);
        alert.show(t("GetPaiementLinkKo"), { type: "error" });
      });
  };

  const classes = useStyle();

  return (
    <>
      {redirect && <Redirect to="/" />}
      <TalentGoBackButton />
      <TalentWhiteContainer>
        {loading ? (
          <TalentLoader />
        ) : (
          <>
            <div className={classes.paypalButtonContainer}>
              <TalentHeaderContainer>
                <Typography variant="h2" component="h2">
                  {t("TitlePaypalPaiement")}
                </Typography>
              </TalentHeaderContainer>
              {internalError && (
                <div style={{ marginBottom: "1em" }}>
                  <Alert severity="error">{t("MarkDepositAsPaidKo")}</Alert>
                </div>
              )}
              <PayPalButton
                amount={deposit.total}
                currency={deposit.currency}
                onSuccess={handlePaiementSuccess}
                catchError={handlePaiementError}
                onButtonReady={() => {
                  setBtnReady(true);
                }}
                options={{
                  clientId: process.env.REACT_APP_PAYPAL_CLIENT,
                  currency: deposit.currency,
                }}
              />
              {!btnReady && <TalentLoader />}
            </div>
            <TalentDividerLine />
            <div className={classes.cmcicButtonContainer}>
              <TalentHeaderContainer>
                <Typography variant="h2" component="h2">
                  {t("TitleCMCICPaiement")}
                </Typography>
              </TalentHeaderContainer>
              <TalentButton
                variant="contained"
                style={{ width: "100%" }}
                onClick={redirectCmcicPayment}
              >
                <TalentIcon
                  icon="credit-card"
                  style={{ marginRight: "1em" }}
                ></TalentIcon>
                {t("CMCICPaiementButtonLabel")}
              </TalentButton>
            </div>
          </>
        )}
      </TalentWhiteContainer>
    </>
  );
};

export default PaypalPaiement;
