import { Select, MenuItem } from "@material-ui/core";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import ukFlag from "../../assets/img/flags/united-kingdom.png";
import frFlag from "../../assets/img/flags/france.png";
import esFlag from "../../assets/img/flags/spain.png";

const useStyles = makeStyles((theme) => ({
  img: {
    width: 15,
    marginRight: 5,
    marginLeft: 5,
  },
  select: {
    "& div": {
      padding: 10,
    },
    marginLeft: 15,
  },
}));

const validLang = (lang) => {
  return ["fr-FR", "en-US", "es-ES"].indexOf(lang) >= 0;
};

const TalentSelectLang = ({ reloadFooter }) => {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const [lang, setLang] = useState(
    validLang(i18n.language) ? i18n.language : "en-US"
  );

  const handleChange = (e) => {
    setLang(e.target.value);
    i18n.changeLanguage(e.target.value, () => {
      reloadFooter();
    });
  };

  return (
    <div>
      <Select
        value={lang}
        onChange={handleChange}
        variant="outlined"
        className={classes.select}
      >
        <MenuItem value="en-US" name="en-US">
          <img src={ukFlag} className={classes.img} alt="EN flag"></img>
          English
        </MenuItem>
        <MenuItem value="fr-FR" name="fr-FR">
          <img src={frFlag} className={classes.img} alt="FR flag"></img>
          Français
        </MenuItem>
        <MenuItem value="es-ES" name="es-ES">
          <img src={esFlag} className={classes.img} alt="ES flag"></img>
          Español
        </MenuItem>
      </Select>
    </div>
  );
};

export default TalentSelectLang;
