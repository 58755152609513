import React from "react";

const CGVContentFR = () => {
  return (
    <>
      <h2>
        <span style={{ fontSize: "18pt" }}>
          <span style={{ fontFamily: '"Times New Roman",serif' }}>
            <strong>
              <span style={{ fontSize: "27.0pt" }}>
                <span style={{ fontFamily: '"EB Garamond"' }}>
                  <span style={{ color: "#0b1941" }}>
                    Terme et Conditions et accord légal entre&nbsp;:
                  </span>
                </span>
              </span>
            </strong>
          </span>
        </span>
      </h2>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <strong>
            <span style={{ fontSize: "10.5pt" }}>
              <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                <span style={{ color: "#0b1941" }}>L’entreprise&nbsp;:</span>
              </span>
            </span>
          </strong>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                &nbsp;TALENT DEVELOPER&nbsp;avec un siège social en 36 rue du
                Mont Thabor 75001 Paris le numéro d’immatriculation 889 114 328
                00012 R.C.S. Paris.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>TALENT DEVELOPER</span>
            </span>
          </span>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                &nbsp;sera nommé également T.D.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <strong>
            <span style={{ fontSize: "10.5pt" }}>
              <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                <span style={{ color: "#0b1941" }}>Le client&nbsp;:</span>
              </span>
            </span>
          </strong>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                &nbsp;Une personne physique ou morale, individuelle ou
                collective qui utilise les services ou conseils de
                l’entreprise&nbsp;TALENT DEVELOPER. Le client pourra être&nbsp;:
                un centre de formation, un étudiant, les représentants légaux de
                l’étudiant, etc.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <strong>
            <span style={{ fontSize: "10.5pt" }}>
              <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                <span style={{ color: "#0b1941" }}>
                  Les clients seront nommés&nbsp;:
                </span>
              </span>
            </span>
          </strong>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                &nbsp;école, université, établissement scolaire, école du
                stagiaire, CFA (Centre de Formation en Alternance) (ou tout
                autre nom donné à l’établissement de formation), stagiaire,
                apprentis et/ou étudiant.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                L’étudiant a 18 ans ou plus et il confirme avoir toute la
                capacité pour être responsable de ses actes.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Les conditions générales établies par&nbsp;TALENT DEVELOPER, ont
                pour objet de définir les obligations contractuelles établies
                entre le client et&nbsp;TALENT DEVELOPER, ainsi que les
                conditions de vente. Ce document sera disponible, à tout moment,
                sur le site internet de T.D.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Nous vous prions de lire attentivement ce document puis de
                confirmer la lecture par l’acceptation dudit contrat.
              </span>
            </span>
          </span>
        </span>
      </span>
      <h2>
        <span style={{ fontSize: "18pt" }}>
          <span style={{ fontFamily: '"Times New Roman",serif' }}>
            <strong>
              <span style={{ fontSize: "27.0pt" }}>
                <span style={{ fontFamily: '"EB Garamond"' }}>
                  <span style={{ color: "#0b1941" }}>
                    Termes et Conditions de TALENT DEVELOPER :
                  </span>
                </span>
              </span>
            </strong>
          </span>
        </span>
      </h2>
      <h3>
        <span style={{ fontSize: "13.5pt" }}>
          <span style={{ fontFamily: '"Times New Roman",serif' }}>
            <strong>
              <span style={{ fontSize: "16.5pt" }}>
                <span style={{ fontFamily: '"EB Garamond"' }}>
                  <span style={{ color: "#0b1941" }}>Général&nbsp;:</span>
                </span>
              </span>
            </strong>
          </span>
        </span>
      </h3>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Les conditions générales établies par&nbsp;TALENT
                DEVELOPER&nbsp;ont pour objet de définir les obligations
                contractuelles établies entre le client et TD, ainsi que les
                conditions de vente.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Les conditions générales sont disponibles via votre espace
                client, le site internet et elles peuvent également être
                demandées à tout moment.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Veuillez lire attentivement ces conditions générales.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Tous les services, offres et/ou réservations à T.D. sont soumis
                aux présentes conditions générales. La réalisation d’un stage ou
                d’un contrat d’apprentissage en entreprise, ou tout autre
                service qui est demandé à T.D. implique l’acceptation des
                conditions générales suivantes.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Tous les clients de T.D. sont tenus d’agir conformément aux
                règles et réglementations établies par cet accord juridique.
              </span>
            </span>
          </span>
        </span>
      </span>
      <h3>
        <span style={{ fontSize: "13.5pt" }}>
          <span style={{ fontFamily: '"Times New Roman",serif' }}>
            <strong>
              <span style={{ fontSize: "16.5pt" }}>
                <span style={{ fontFamily: '"EB Garamond"' }}>
                  <span style={{ color: "#0b1941" }}>Définitions&nbsp;:</span>
                </span>
              </span>
            </strong>
          </span>
        </span>
      </h3>
      <h4>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: '"Times New Roman",serif' }}>
            <strong>
              <u>
                <span style={{ fontSize: "13.5pt" }}>
                  <span style={{ fontFamily: '"EB Garamond"' }}>
                    <span style={{ color: "#0b1941" }}>
                      Organisme d’Accueil ou Entreprise d’Accueil :
                    </span>
                  </span>
                </span>
              </u>
            </strong>
          </span>
        </span>
      </h4>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                L’organisme d’accueil ou l’entreprise d’accueil désigne toute
                entité juridique dans laquelle le client effectue sa période en
                entreprise. L’organisme d’accueil peut être une entreprise, une
                Organisation Gouvernementale (OG), une Organisation Non
                Gouvernementale (ONG) ou toute organisation ayant un statut
                légal l’autorisant à accueillir le client.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Il s’agira toujours d’établissements en relation avec tous les
                domaines de compétences liés à l’hôtellerie, à la restauration
                et/ou aux arts culinaires et ceux dans tous les services
                (opérationnel, exploitation, relation client, marketing, RH,
                finances, etc.).
              </span>
            </span>
          </span>
        </span>
      </span>
      <h4>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: '"Times New Roman",serif' }}>
            <strong>
              <u>
                <span style={{ fontSize: "13.5pt" }}>
                  <span style={{ fontFamily: '"EB Garamond"' }}>
                    <span style={{ color: "#0b1941" }}>
                      Période en entreprise&nbsp;: Stage ou Contrat
                      d’Apprentissage en entreprise :
                    </span>
                  </span>
                </span>
              </u>
            </strong>
          </span>
        </span>
      </h4>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                La période en entreprise désigne une expérience d’apprentissage
                pratique à laquelle participent l’organisme d’accueil et
                l’étudiant. Le stage ou contrat d’apprentissage en entreprise
                doit être une partie intégrante du programme de formation et une
                composante obligatoire de la progression pédagogique de l’année
                en cours de l’étudiant.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                La période en entreprise a une durée allant de 2 à 6 mois pour
                le stage, elle sera annuelle pour le contrat d’apprentissage, et
                il est réalisé dans les domaines de l’hôtellerie, la
                restauration, les arts culinaires ou tout autre service en
                relation avec ces trois nommés&nbsp;: exploitation, services
                supports, services administratifs…).
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                L’objectif principal de la période en entreprise est de
                permettre aux étudiants de vivre une expérience dans un contexte
                professionnel, de développer son savoir-être et son
                savoir-faire, de pratiquer les techniques acquises en cours
                théoriques, de communiquer en français et d’intégrer et de
                développer un réseau de professionnels.
              </span>
            </span>
          </span>
        </span>
      </span>
      <h4>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: '"Times New Roman",serif' }}>
            <strong>
              <u>
                <span style={{ fontSize: "13.5pt" }}>
                  <span style={{ fontFamily: '"EB Garamond"' }}>
                    <span style={{ color: "#0b1941" }}>
                      Convention de Stage :
                    </span>
                  </span>
                </span>
              </u>
            </strong>
          </span>
        </span>
      </h4>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                La convention de stage fait référence au contrat tripartite
                établi entre&nbsp;: l’entreprise d’accueil (et non pas par
                T.D.), l’école du stagiaire et le stagiaire.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Le stage n’est pas régi par un contrat de travail.&nbsp;Le
                stagiaire n’est pas un salarié de l’entreprise et ne peut se
                substituer à celui-ci.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                En France, un stage n’implique pas une rémunération mais peut
                toutefois prévoir une gratification. Cette gratification devient
                automatique et obligatoire dès lors que le stage est d’une durée
                supérieure à 2 mois.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Le stage est encadré par les textes de loi suivants&nbsp;:
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Réforme du statut des stagiaires par la loi n°2014 -788 du 10
                juillet 2014.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>Textes de référence :</span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                – &nbsp;Loi n° 2013-660 du 22 juillet 2013 relative à
                l’enseignement supérieur et à la recherche
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                – &nbsp;Loi n°2014-788 du 10 juillet 2014
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                – &nbsp;Décret n°2014-1420 du 27 novembre 2014 relatif à
                l’encadrement des périodes de formation en milieu professionnel
                et des stages.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                – Arrêté du 29 décembre 2014 relatif aux conventions de stage
                dans l’enseignement supérieur
              </span>
            </span>
          </span>
          <strong>
            <span style={{ fontSize: "10.5pt" }}>
              <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                <span style={{ color: "#0b1941" }}>.</span>
              </span>
            </span>
          </strong>
        </span>
      </span>
      <h4>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: '"Times New Roman",serif' }}>
            <strong>
              <u>
                <span style={{ fontSize: "13.5pt" }}>
                  <span style={{ fontFamily: '"EB Garamond"' }}>
                    <span style={{ color: "#0b1941" }}>
                      Contrat d’apprentissage :
                    </span>
                  </span>
                </span>
              </u>
            </strong>
          </span>
        </span>
      </h4>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Le contrat d’apprentissage fait référence au contrat de travail
                conclue entre une entreprise d’accueil (et non pas par T.D.) et
                un salarié qui suit parallèlement une formation en
                apprentissage. Il est régi par le code du travail et par la
                convention collective de la branche de l’entreprise
                d’accueil.&nbsp; Il est signé par l’entreprise d’accueil (et non
                par T.D.) et par l’étudiant ; sous la vérification de son centre
                de formation.
              </span>
            </span>
          </span>
        </span>
      </span>
      <h4>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: '"Times New Roman",serif' }}>
            <strong>
              <u>
                <span style={{ fontSize: "13.5pt" }}>
                  <span style={{ fontFamily: '"EB Garamond"' }}>
                    <span style={{ color: "#0b1941" }}>
                      Offre en entreprise :
                    </span>
                  </span>
                </span>
              </u>
            </strong>
          </span>
        </span>
      </h4>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                L’offre en entreprise fait référence à la description du
                poste/fiche de poste pour laquelle une entreprise d’accueil
                recherche des candidats. Elle comprend la description des tâches
                et missions du poste ainsi que les compétences et les
                qualifications requises par l’entreprise d’accueil pour occuper
                le poste.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                L’offre en entreprise sera proposée à l’étudiant, en fonction de
                ses compétences, du niveau de français demandé par l’entreprise
                d’accueil (qui sera au minimum de B1 selon les normes
                européennes), de la progression pédagogique de la formation que
                l’étudiant suit et du projet professionnel de l’étudiant.
                L’objectif de T.D est d’adapter l’offre en entreprise au projet
                et au profil de l’étudiant.
              </span>
            </span>
          </span>
        </span>
      </span>
      <h4>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: '"Times New Roman",serif' }}>
            <strong>
              <u>
                <span style={{ fontSize: "13.5pt" }}>
                  <span style={{ fontFamily: '"EB Garamond"' }}>
                    <span style={{ color: "#0b1941" }}>
                      Description de Package en entreprise :
                    </span>
                  </span>
                </span>
              </u>
            </strong>
          </span>
        </span>
      </h4>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Le Package en entreprise fait référence aux différents services
                fournis par T.D. et/ou ses partenaires. T.D. agit comme
                intermédiaire entre l’entreprise d’accueil et le client.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Le package, nommé également Prestation de Service, s’articule en
                trois périodes distinctes&nbsp;: avant, pendant et après la
                période en entreprise.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                T.D. propose 3 packages disponibles dans tous ces documents
                commerciaux&nbsp;:
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>Forfait Future Talent</span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Forfait Future Talent Premium
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Forfait Future Talent Premium Plus
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <em>
            <strong>
              <span style={{ fontSize: "10.5pt" }}>
                <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                  <span style={{ color: "#0b1941" }}>
                    «&nbsp;Forfait Futur Talent
                  </span>
                </span>
              </span>
            </strong>
          </em>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <em>
            <span style={{ fontSize: "10.5pt" }}>
              <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                <span style={{ color: "#0b1941" }}>
                  Pour qui&nbsp;: Les étudiants français en recherche de stage
                  ou d’apprentissage
                </span>
              </span>
            </span>
          </em>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <em>
            <u>
              <span style={{ fontSize: "10.5pt" }}>
                <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                  <span style={{ color: "#0b1941" }}>
                    Vous accompagner avant votre stage ou votre contrat
                    d’apprentissage
                  </span>
                </span>
              </span>
            </u>
          </em>
        </span>
      </span>
      <ol>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Premier entretien, sans engagement de votre part,
                      pour&nbsp;: Comprendre vos attentes, votre personnalité,
                      vos capacités de travail et le plus important, votre
                      projet professionnel. À l’issu de ce rendez-vous, nous
                      vous proposons un plan de carrière pour atteindre au mieux
                      vos objectifs.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Pour continuer dans le processus, vous devez&nbsp;:
                      Compléter votre profil en ligne, remplir et poster les
                      documents demandés et payer l’acompte correspondant au
                      forfait sélectionné.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Étude de votre programme de formation dans le détail
                      pour&nbsp;: Comprendre les compétences que vous
                      développerez pendant la période en entreprise, nous
                      prenons ensuite contact avec le responsable de votre
                      formation, si nécessaire.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Contenu pédagogique exclusif&nbsp;: Un accès au catalogue
                      de cours en ligne pour vous aider à réaliser&nbsp;votre
                      CV, votre lettre de motivation et développer vos
                      connaissances en matière de culture professionnelle.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Test de personnalité&nbsp;:&nbsp;Á&nbsp;compléter dans
                      votre espace personnel pour nous aider à identifier
                      l’entreprise et le profil du tuteur qui vous correspond le
                      mieux.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Candidature en entreprise&nbsp;: –&nbsp;Étude et
                      correction de votre CV et de votre lettre de motivation,
                      pour qu’ils correspondent parfaitement aux attentes des
                      recruteurs – Nous sélectionnons l’entreprise de notre
                      réseau qui convient le mieux aux besoins que nous
                      identifions pour vous – Nous présentons votre candidature
                      à l’entreprise en mettant en avant votre savoir-être et
                      vos savoir-faire – L’entreprise nous confirme son intérêt
                      pour votre profil, nous vous demandons de régler le solde
                      du forfait – Nous vous communiquons les coordonnées de
                      l’entreprise et vous proposons un entretien avec leur
                      recruteur – Nous vous aidons à vous préparer à l’entretien
                      en vous communiquant une liste de questions types ainsi
                      que la philosophie et les attentes propres à cet
                      établissement.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
      </ol>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <em>
            <u>
              <span style={{ fontSize: "10.5pt" }}>
                <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                  <span style={{ color: "#0b1941" }}>
                    Vous guider pendant votre stage ou contrat
                    d’apprentissage&nbsp;:
                  </span>
                </span>
              </span>
            </u>
          </em>
        </span>
      </span>
      <ol>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Votre futur réseau&nbsp;: Nous vous invitons à partager un
                      petit déjeuner avec l’ensemble des étudiants débutants à
                      la même période que vous. Cela vous permettra de
                      constituer un réseau de futurs talents internationaux et
                      français et de vivre une expérience enrichissante et
                      inoubliable.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Visite en entreprise&nbsp;: Nous organisons une visite
                      dans votre entreprise d’accueil, avec votre tuteur afin de
                      faire le point sur votre évolution dans l’entreprise.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Nous vous accompagnons tout au long de votre période en
                      entreprise&nbsp;: Nous vous contactons au début, au milieu
                      et à la fin pour faire un point puis nous restons à votre
                      entière disposition pendant tout votre période en
                      entreprise.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
      </ol>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <em>
            <u>
              <span style={{ fontSize: "10.5pt" }}>
                <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                  <span style={{ color: "#0b1941" }}>
                    Vous faire évoluer après votre stage ou contrat
                    d’apprentissage
                  </span>
                </span>
              </span>
            </u>
          </em>
        </span>
      </span>
      <ol>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Le rendez-vous de fin de la formation en entreprise&nbsp;:
                      Organisé dans nos locaux, pour débriefer de votre
                      expérience, nous vous aidons à extraire les compétences
                      acquises et les valoriser pour votre futur professionnel
                      puis nous vous orientons dans l’évolution de votre
                      carrière et vos objectifs professionnels à venir.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
      </ol>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <em>
            <span style={{ fontSize: "10.5pt" }}>
              <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                <span style={{ color: "#0b1941" }}>
                  La plateforme&nbsp;TALENT DEVELOPER&nbsp;: Vous gardez un
                  accès privilégié à votre profil personnel et à la
                  plateforme&nbsp;TALENT DEVELOPER&nbsp;et accédez ainsi à nos
                  offres d’emploi exclusives.&nbsp;
                </span>
              </span>
            </span>
          </em>
          <strong>
            <em>
              <span style={{ fontSize: "10.5pt" }}>
                <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                  <span style={{ color: "#0b1941" }}>»</span>
                </span>
              </span>
            </em>
          </strong>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <em>
            <strong>
              <span style={{ fontSize: "10.5pt" }}>
                <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                  <span style={{ color: "#0b1941" }}>
                    «&nbsp;Forfait Futur Talent Premium
                  </span>
                </span>
              </span>
            </strong>
          </em>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <em>
            <span style={{ fontSize: "10.5pt" }}>
              <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                <span style={{ color: "#0b1941" }}>
                  Pour qui :&nbsp;Les étudiants étrangers ayant un niveau de
                  français supérieur au B1
                </span>
              </span>
            </span>
          </em>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <em>
            <u>
              <span style={{ fontSize: "10.5pt" }}>
                <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                  <span style={{ color: "#0b1941" }}>
                    Vous accompagner avant votre stage ou votre contrat
                    d’apprentissage
                  </span>
                </span>
              </span>
            </u>
          </em>
        </span>
      </span>
      <ol>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Premier entretien, sans engagement de votre part,
                      pour&nbsp;: Comprendre vos attentes, votre personnalité,
                      vos capacités de travail et le plus important, votre
                      projet professionnel. À l’issu de ce rendez-vous, nous
                      vous proposons un plan de carrière pour atteindre au mieux
                      vos objectifs.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Pour continuer dans le processus, vous devez&nbsp;:
                      Compléter votre profil en ligne, remplir et poster les
                      documents demandés et payer l’acompte correspondant au
                      forfait sélectionné.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Étude de votre programme de formation dans le détail
                      pour&nbsp;: Comprendre les compétences que vous
                      développerez pendant la période en entreprise, nous
                      prenons ensuite contact avec le responsable de votre
                      formation, si nécessaire.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Contenu pédagogique exclusif&nbsp;: Un accès au catalogue
                      de cours en ligne pour vous aider à réaliser&nbsp;votre
                      CV, votre lettre de motivation et développer vos
                      connaissances en matière de culture professionnelle puis
                      un livret «&nbsp;Bienvenue à Paris&nbsp;» pour préparer au
                      mieux votre séjour (bonnes adresses, informations
                      pratiques…).
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Test de personnalité&nbsp;:&nbsp;Á&nbsp;compléter dans
                      votre espace personnel pour nous aider à identifier
                      l’entreprise et le profil du tuteur qui vous correspond le
                      mieux.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Candidature en entreprise&nbsp;: –&nbsp;Étude et
                      correction de votre CV et de votre lettre de motivation,
                      pour qu’ils correspondent parfaitement aux attentes des
                      recruteurs – Nous sélectionnons l’entreprise de notre
                      réseau qui convient le mieux aux besoins que nous
                      identifions pour vous – Nous présentons votre candidature
                      à l’entreprise en mettant en avant votre savoir-être et
                      vos savoir-faire – L’entreprise nous confirme son intérêt
                      pour votre profil, nous vous demandons de régler le solde
                      du forfait – Nous vous communiquons les coordonnées de
                      l’entreprise et vous proposons un entretien avec leur
                      recruteur – Nous vous aidons à vous préparer à l’entretien
                      en vous communiquant une liste de questions types ainsi
                      que la philosophie et les attentes propres à cet
                      établissement.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
      </ol>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <em>
            <u>
              <span style={{ fontSize: "10.5pt" }}>
                <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                  <span style={{ color: "#0b1941" }}>
                    Vous guider pendant votre stage ou contrat
                    d’apprentissage&nbsp;:
                  </span>
                </span>
              </span>
            </u>
          </em>
        </span>
      </span>
      <ol>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Votre futur réseau&nbsp;: Nous vous invitons à partager un
                      petit déjeuner avec l’ensemble des étudiants débutants à
                      la même période que vous. Cela vous permettra de
                      constituer un réseau de futurs talents internationaux et
                      français et de vivre une expérience enrichissante et
                      inoubliable.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Visite en entreprise&nbsp;: Nous organisons deux visites
                      dans votre entreprise d’accueil, avec votre tuteur afin de
                      faire le point sur votre évolution dans l’entreprise.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Accompagnement pendant votre période en entreprise&nbsp;:
                      Nous vous contactons au début, au milieu et à la fin de
                      votre période en entreprise pour faire un point puis nous
                      restons à votre entière disposition et un membre de
                      l’équipe de&nbsp;TALENT DEVELOPER, est toujours à votre
                      écoute en cas d’urgence.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
      </ol>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <em>
            <u>
              <span style={{ fontSize: "10.5pt" }}>
                <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                  <span style={{ color: "#0b1941" }}>
                    Vous faire évoluer après votre stage ou contrat
                    d’apprentissage :
                  </span>
                </span>
              </span>
            </u>
          </em>
        </span>
      </span>
      <ol>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Le rendez-vous de fin de la formation en entreprise&nbsp;:
                      Organisé dans nos locaux, pour débriefer de votre
                      expérience, nous vous aidons à extraire les compétences
                      acquises et les valoriser pour votre futur professionnel,
                      nous vous orientons dans l’évolution de votre carrière et
                      vos objectifs professionnels à venir puis nous vous
                      remettons un certificat de stage, attestant de votre
                      expérience à Paris.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
      </ol>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <em>
            <span style={{ fontSize: "10.5pt" }}>
              <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                <span style={{ color: "#0b1941" }}>
                  La plateforme&nbsp;TALENT DEVELOPER&nbsp;: Vous gardez un
                  accès privilégié à votre profil personnel et à la
                  plateforme&nbsp;TALENT DEVELOPER&nbsp;et accédez ainsi à nos
                  offres d’emploi exclusives.&nbsp;
                </span>
              </span>
            </span>
          </em>
          <strong>
            <em>
              <span style={{ fontSize: "10.5pt" }}>
                <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                  <span style={{ color: "#0b1941" }}>»</span>
                </span>
              </span>
            </em>
          </strong>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <em>
            <strong>
              <span style={{ fontSize: "10.5pt" }}>
                <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                  <span style={{ color: "#0b1941" }}>
                    «&nbsp;Forfait Futur Talent Premium Plus
                  </span>
                </span>
              </span>
            </strong>
          </em>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <em>
            <span style={{ fontSize: "10.5pt" }}>
              <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                <span style={{ color: "#0b1941" }}>
                  Pour qui&nbsp;: Les étudiants étrangers ayant un niveau de
                  français inférieur au niveau B1
                </span>
              </span>
            </span>
          </em>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <em>
            <span style={{ fontSize: "10.5pt" }}>
              <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                <span style={{ color: "#0b1941" }}>
                  Si&nbsp;vous n’êtes pas trop familier avec la langue de
                  Molière, notre forfait Premium Plus a été pensé pour
                  vous&nbsp;!
                </span>
              </span>
            </span>
          </em>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <em>
            <u>
              <span style={{ fontSize: "10.5pt" }}>
                <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                  <span style={{ color: "#0b1941" }}>
                    Vous accompagner avant votre stage :
                  </span>
                </span>
              </span>
            </u>
          </em>
        </span>
      </span>
      <ol>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Premier entretien, sans engagement de votre part,
                      pour&nbsp;: Comprendre vos attentes, votre personnalité,
                      vos capacités de travail et le plus important, votre
                      projet professionnel. À l’issu de ce rendez-vous, nous
                      vous proposons un plan de carrière pour atteindre au mieux
                      vos objectifs.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Pour continuer dans le processus, vous devez&nbsp;:
                      Compléter votre profil en ligne, remplir et poster les
                      documents demandés et payer l’acompte correspondant au
                      forfait sélectionné.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Étude de votre programme de formation dans le détail
                      pour&nbsp;: Comprendre les compétences que vous
                      développerez pendant la période en entreprise, nous
                      prenons ensuite contact avec le responsable de votre
                      formation, si nécessaire.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Contenu pédagogique exclusif&nbsp;: Un accès au catalogue
                      de cours en ligne pour vous aider à réaliser&nbsp;votre
                      CV, votre lettre de motivation et développer vos
                      connaissances en matière de culture professionnelle puis
                      un livret «&nbsp;Bienvenue à Paris&nbsp;» pour préparer au
                      mieux votre séjour (bonnes adresses, informations
                      pratiques…).
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Test de personnalité&nbsp;:&nbsp;Á&nbsp;compléter dans
                      votre espace personnel pour nous aider à identifier
                      l’entreprise et le profil du tuteur qui vous correspond le
                      mieux.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Candidature en entreprise&nbsp;: –&nbsp;Étude et
                      correction de votre CV et de votre lettre de motivation,
                      pour qu’ils correspondent parfaitement aux attentes des
                      recruteurs – Nous sélectionnons l’entreprise de notre
                      réseau qui convient le mieux aux besoins que nous
                      identifions pour vous – Nous présentons votre candidature
                      à l’entreprise en mettant en avant votre savoir-être et
                      vos savoir-faire – L’entreprise nous confirme son intérêt
                      pour votre profil, nous vous demandons de régler le solde
                      du forfait – Nous vous communiquons les coordonnées de
                      l’entreprise et vous proposons un entretien avec leur
                      recruteur – Nous vous aidons à vous préparer à l’entretien
                      en vous communiquant une liste de questions types ainsi
                      que la philosophie et les attentes propres à cet
                      établissement.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
      </ol>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <em>
            <u>
              <span style={{ fontSize: "10.5pt" }}>
                <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                  <span style={{ color: "#0b1941" }}>
                    Vous guider pendant votre stage :
                  </span>
                </span>
              </span>
            </u>
          </em>
        </span>
      </span>
      <ol>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Votre futur réseau&nbsp;: Nous vous invitons à partager un
                      petit déjeuner avec l’ensemble des étudiants débutants à
                      la même période que vous. Cela vous permettra de
                      constituer un réseau de futurs talents internationaux et
                      français et de vivre une expérience enrichissante et
                      inoubliable.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Cours de français&nbsp;: Vous suivrez deux semaines très
                      intensives de cours de FLE (français langue étrangère)
                      pour atteindre le niveau de compréhension et de
                      communication nécessaires à la bonne exécution de votre
                      stage.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Visite en entreprise&nbsp;: Nous organisons deux visites
                      dans votre entreprise d’accueil, avec votre tuteur afin de
                      faire le point sur votre évolution dans l’entreprise.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Accompagnement pendant votre période en entreprise&nbsp;:
                      Nous vous contactons au début, au milieu et à la fin de
                      votre période en entreprise pour faire un point puis nous
                      restons à votre entière disposition et un membre de
                      l’équipe de&nbsp;TALENT DEVELOPER, est toujours à votre
                      écoute en cas d’urgence.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
      </ol>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <em>
            <u>
              <span style={{ fontSize: "10.5pt" }}>
                <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                  <span style={{ color: "#0b1941" }}>
                    Vous faire évoluer après votre stage :
                  </span>
                </span>
              </span>
            </u>
          </em>
        </span>
      </span>
      <ol>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Le rendez-vous de fin de la formation en entreprise&nbsp;:
                      Organisé dans nos locaux, pour débriefer de votre
                      expérience, nous vous aidons à extraire les compétences
                      acquises et les valoriser pour votre futur professionnel,
                      nous vous orientons dans l’évolution de votre carrière et
                      vos objectifs professionnels à venir puis nous vous
                      remettons un certificat de stage, attestant de votre
                      expérience à Paris.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
      </ol>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <em>
            <span style={{ fontSize: "10.5pt" }}>
              <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                <span style={{ color: "#0b1941" }}>
                  La plateforme&nbsp;TALENT DEVELOPER&nbsp;: Vous gardez un
                  accès privilégié à votre profil personnel et à la
                  plateforme&nbsp;TALENT DEVELOPER&nbsp;et accédez ainsi à nos
                  offres d’emploi exclusives.&nbsp;
                </span>
              </span>
            </span>
          </em>
          <strong>
            <em>
              <span style={{ fontSize: "10.5pt" }}>
                <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                  <span style={{ color: "#0b1941" }}>»</span>
                </span>
              </span>
            </em>
          </strong>
        </span>
      </span>
      <h4>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: '"Times New Roman",serif' }}>
            <strong>
              <u>
                <span style={{ fontSize: "13.5pt" }}>
                  <span style={{ fontFamily: '"EB Garamond"' }}>
                    <span style={{ color: "#0b1941" }}>École de langues :</span>
                  </span>
                </span>
              </u>
            </strong>
          </span>
        </span>
      </h4>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Les écoles de langue font référence à toute école de langue
                habilitée qui collabore avec T.D. pour fournir des cours de
                langue au client. T.D. a sélectionné une école de langues
                pendant deux semaines et a imposé un cahier de charges très
                précis, pour que l’étudiant bénéficie de la formation intensive
                et pertinente pendant ces deux semaines.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                T.D. exige un niveau de français B1 ou équivalent justifié par
                un certificat. Le niveau B1 permet à l’étudiant de communiquer
                de façon simple avec son interlocuteur. Il est primordial pour
                l’étudiant de bien communiquer avec son entourage, avec son
                tuteur et avec les équipes de l’établissement d’accueil. La
                communication permettra à l’étudiant de comprendre parfaitement
                les attentes du tuteur et de développer un maximum de
                compétences professionnelles pendant cette période en
                entreprise. Il profitera également du réseau professionnel de
                manière beaucoup plus active en communiquant dans la même
                langue.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Si le niveau de français de l’étudiant n’est pas suffisant pour
                communiquer de façon fluide, mais qu’il dispose d’une base de
                français, les cours de FLE sont obligatoires.&nbsp; Deux
                semaines de cours intensif sont imposées à l’étudiant dès son
                arrivée à Paris et avant de commencer sa période en entreprise.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Le montant des cours est inclus dans la prestation Forfait
                Future Talent Premium&nbsp;Plus.
              </span>
            </span>
          </span>
        </span>
      </span>
      <h4>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: '"Times New Roman",serif' }}>
            <strong>
              <u>
                <span style={{ fontSize: "13.5pt" }}>
                  <span style={{ fontFamily: '"EB Garamond"' }}>
                    <span style={{ color: "#0b1941" }}>
                      Partenaire ou Entreprise de Services :
                    </span>
                  </span>
                </span>
              </u>
            </strong>
          </span>
        </span>
      </h4>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Le Partenaire fait référence à toute société, organisation ou
                personne physique ou morale employée ou utilisée par T.D pour
                ses services. L’objectif étant d’aider l’étudiant dans son
                installation et intégration en France et de faciliter sa vie
                quotidienne en dehors de l’entreprise d’accueil. T.D n’est pas
                responsable de ces prestations, conditions, ni tarifs. Les
                services proposés sont : agence de logement, transfert
                aéroports, souscription de police d’assurance et société de
                service de ménage/nettoyage, carte Sim, abonnement internet,
                carte de transports, cours de cuisine, cours de pâtisserie, etc.
              </span>
            </span>
          </span>
        </span>
      </span>
      <h4>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: '"Times New Roman",serif' }}>
            <strong>
              <u>
                <span style={{ fontSize: "13.5pt" }}>
                  <span style={{ fontFamily: '"EB Garamond"' }}>
                    <span style={{ color: "#0b1941" }}>Tuteur :</span>
                  </span>
                </span>
              </u>
            </strong>
          </span>
        </span>
      </h4>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Le tuteur désigne toute personne physique désignée au sein de
                l’entreprise d’accueil qui suivra et encadrera l’étudiant tout
                au long de sa période en entreprise. Le tuteur assignera des
                tâches et des missions à l’étudiant et lui apportera son
                soutien, lui expliquera les techniques et lui fera des retours
                réguliers sur son travail afin de lui permettre d’évoluer et de
                gagner en compétences.
              </span>
            </span>
          </span>
        </span>
      </span>
      <h3>
        <span style={{ fontSize: "13.5pt" }}>
          <span style={{ fontFamily: '"Times New Roman",serif' }}>
            <strong>
              <span style={{ fontSize: "16.5pt" }}>
                <span style={{ fontFamily: '"EB Garamond"' }}>
                  <span style={{ color: "#0b1941" }}>Acceptation :</span>
                </span>
              </span>
            </strong>
          </span>
        </span>
      </h3>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Le client reconnaît avoir lu, compris et accepté les termes et
                conditions.
              </span>
            </span>
          </span>
        </span>
      </span>
      <h4>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: '"Times New Roman",serif' }}>
            <strong>
              <u>
                <span style={{ fontSize: "13.5pt" }}>
                  <span style={{ fontFamily: '"EB Garamond"' }}>
                    <span style={{ color: "#0b1941" }}>Articles</span>
                  </span>
                </span>
              </u>
            </strong>
          </span>
        </span>
      </h4>
      <h5>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: '"Calibri Light",sans-serif' }}>
            <span style={{ color: "#2f5496" }}>
              <strong>
                <span style={{ fontSize: "13.5pt" }}>
                  <span style={{ fontFamily: '"EB Garamond"' }}>
                    <span style={{ color: "#0b1941" }}>
                      1.Inscription du client
                    </span>
                  </span>
                </span>
              </strong>
            </span>
          </span>
        </span>
      </h5>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                1.1. Inscription gratuite, sans obligation d’achat, via le site
                internet de T.D. ou l’établissement scolaire.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                1.2. L’étudiant doit fournir son CV et une lettre de motivation
                dans un format modifiable (Word) en français ou anglais et
                suivre les étapes indiquées dans son espace client sur le site
                internet.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                1.3. Le niveau de français du client doit être suffisant pour
                communiquer pendant sa période en entreprise en France. Le
                client doit fournir un justificatif de moins de 2 ans prouvant
                son niveau de français (DELF B1 ou DALF, TCF, TFI ou TEF niveau
                B1). A défaut, le client devra contracter l’option incluant les
                cours de FLE obligatoires pour une mise à niveau B1. T.D ne
                pourra garantir le stage si le client n’atteint pas le niveau de
                français demandé et ce même si le client a contracté l’option
                incluant les cours de FLE.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                1.4. Le client s’engage à fournir toutes les informations
                nécessaires à T.D pour effectuer sa mission et à communiquer
                toute information pouvant affecter le bon déroulement du stage
                ou période d’apprentissage en entreprise, du séjour ou du
                processus de placement.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                1.5. Une attestation d’assurance santé et une attestation
                d’assurance responsabilité civile, une copie du passeport, du
                visa, de la carte de séjour et/ou sticker OFFI en cours de
                validité ainsi que tout autre document officiel peuvent être
                exigés. Les étudiants hors communauté Européenne doivent être en
                possession d’un Visa VLS-TS leur permettant de réaliser leur
                stage en France. Dès leur arrivée sur le territoire français et
                en fonction du type de visa en leur possession, ces étudiants
                doivent s’inscrire à la Préfecture de Police pour valider leur
                visa en suivant la procédure OFII.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Chaque étudiant est responsable de ce processus légal et
                officiel et devra s’informer et suivre les obligations fournies
                par son ambassade et par les entités officielles françaises
                concernées.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Un étudiant ne suivant pas les obligations légales liées au visa
                ne pourra débuter sa période en entreprise en France.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Un étudiant ne s’occupant pas de valider son visa dans les 3
                mois suivant son arrivée sera considéré en situation illégale et
                sera redevable d’une amende.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                1.6. T.D. se réserve le droit de refuser les candidatures si : –
                le client ne fournit pas les informations demandées dans un
                délai acceptable – le client a fait l’objet d’une condamnation
                pénale pour une infraction grave ou incompatible avec son entrée
                en France ou sa période en entreprise dans les établissements
                d’accueil proposés ou toute autre raison rendant le client
                inapte à faire la période en entreprise – le client ne parle pas
                suffisamment le français pour pouvoir communiquer et ce même si
                le client a contracté l’option incluant les cours de FLE.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                1.7. T.D. se réserve le droit de rejeter la candidature de toute
                personne jugée inapte au programme de pratiques en entreprise,
                et ce, à sa seule discrétion.
              </span>
            </span>
          </span>
        </span>
      </span>
      <h5>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: '"Calibri Light",sans-serif' }}>
            <span style={{ color: "#2f5496" }}>
              <strong>
                <span style={{ fontSize: "13.5pt" }}>
                  <span style={{ fontFamily: '"EB Garamond"' }}>
                    <span style={{ color: "#0b1941" }}>
                      2.Engagements et responsabilités de TALENT DEVELOPER
                      (T.D.)
                    </span>
                  </span>
                </span>
              </strong>
            </span>
          </span>
        </span>
      </h5>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                2.1. T.D. agit en tant qu’intermédiaire entre les entreprises
                d’accueil, les partenaires et le client.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                2.2. T.D. s’engage à mettre le client en contact avec les
                entreprises d’accueil, en fonction de ses attentes, de son
                profil, de son domaine de compétences, du programme d’étude
                suivi, de ses compétences et de son projet professionnel mais
                également, en fonction des exigences, du profil et des attentes
                des entreprises d’accueil.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                2.3. T.D. s’engage à faire de son mieux pour aider le client à
                obtenir son entreprise d’accueil, en menant des actions telles
                que : – la formation sur la réalisation du CV, de la lettre de
                motivation et les attentes dans les entreprises en France, -la
                révision et la correction du CV et de la lettre de motivation du
                client, – l’envoi de la candidature du client aux entreprises
                d’accueil, – la programmation d’entretiens avec les entreprises
                d’accueil intéressées par le profil du client et disposées à lui
                faire passer un entretien, à condition que le premier paiement
                d’acompte correspondant au forfait sélectionné ait été effectué
                par le client, – la révision et correction de tout travail
                spécifique requis par les entreprises d’accueil avant de faire
                passer un entretien au client ou en remplacement de l’entretien,
                – le cas échéant, l’apport de conseils sur la façon de préparer
                un entretien professionnel afin de maximiser les chances du
                client d’obtenir un stage ou un contrat d’apprentissage (séance
                de coaching et/ou livret de conseils).
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                2.4. T.D. assiste le client avec la signature de sa convention
                de stage.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                2.5. Une fois que l’étudiant débute dans l’entreprise d’accueil
                T.D. s’engage à assurer le suivi de l’étudiant tout au long de
                la période en entreprise en répondant à ses éventuelles
                questions et à le conseiller au mieux.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                2.6. T.D. ne peut être tenu responsable du comportement des
                employés ou des conditions de travail dans l’entreprise
                d’accueil. Toutefois, T.D. s’engage à aider le client à
                résoudre, au mieux de ses possibilités, les potentiels problèmes
                de communication qui pourraient survenir avec l’entreprise
                d’accueil et en particulier concernant : les missions et/ou les
                conditions de travail (Cf Article 7).
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                2.7. T.D. ne peut être tenu responsable des services fournis par
                ses partenaires. Cependant, T.D. s’engage à aider le client à
                résoudre les potentiels problèmes qui pourraient survenir avec
                ses partenaires, au mieux de ses capacités.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                2.8. T.D. s’engage à être disponible pour toute demande
                d’informations pertinentes de la part du client.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                2.9. T.D. ne peut garantir aucun placement en entreprise dans un
                délai défini. Des facteurs externes (tels que l’intérêt ou la
                disponibilité des entreprises d’accueil, le comportement et le
                discours du client pendant les entretiens, la disponibilité du
                logement, l’obtention et l’approbation du visa, etc.) empêchent
                T.D. de garantir une date ou un délai précis pour le placement.
                T.D. conseille à ses clients de prendre leurs dispositions au
                niveau du temps octroyé pour cette recherche et d’anticiper les
                démarches pour assurer toutes les chances de réussite.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                2.10. T.D. ne peut garantir la gratification des stages par les
                entreprises d’accueil mais s’engage à aider le client à résoudre
                les potentiels problèmes de communication avec les entreprises
                d’accueil à ce sujet.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                2.11. T.D. se réserve le droit de cesser toute coopération avec
                le client dans le cas où son profil, son comportement, ses
                attentes ou son projet ne correspondraient pas aux attentes et
                aux exigences des entreprises d’accueil.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                2.12. Une fois que le client a accepté l’offre d’entreprise et
                payé le solde du Package forfait correspondant, T.D. s’engage à
                ne pas proposer cette offre à un autre client.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                2.13. T.D. s’engage à ne pas divulguer les informations
                personnelles du client à des institutions commerciales ou à
                toute autre personne ou entité sans son consentement.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                2.14. T.D. n’est pas légalement responsable du client, ni dans
                les locaux de l’entreprise d’accueil, ni à l’extérieur des
                locaux de l’entreprise d’accueil.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                2.15. T.D. décline toute responsabilité relative aux accidents,
                blessures, vols, dommages, maladies, pertes, dépenses ou coûts
                engendrés ou risques pouvant survenir pendant le stage ou le
                contrat d’apprentissage en entreprise ou à la résidence d’un
                tiers, y compris, mais sans s’y limiter, aux locaux de
                l’entreprise d’accueil et au logement occupé par l’étudiant.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                2.16. La responsabilité maximale de T.D. est limitée au montant
                payé par le client pour le service fourni par T.D. &nbsp;T.D.
                n’est pas responsable des frais supplémentaires qui pourraient
                être engagés par Le client avec des organismes externes avant,
                pendant ou après son stage ou son contrat d’apprentissage au
                sein de l&nbsp;‘entreprise d’accueil ou en dehors de celle-ci.
              </span>
            </span>
          </span>
        </span>
      </span>
      <h5>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: '"Calibri Light",sans-serif' }}>
            <span style={{ color: "#2f5496" }}>
              <strong>
                <span style={{ fontSize: "13.5pt" }}>
                  <span style={{ fontFamily: '"EB Garamond"' }}>
                    <span style={{ color: "#0b1941" }}>
                      3. Engagement, responsabilité et comportement du client
                    </span>
                  </span>
                </span>
              </strong>
            </span>
          </span>
        </span>
      </h5>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                3.1. Processus de placement en stage ou en contrat
                d’apprentissage :
              </span>
            </span>
          </span>
        </span>
      </span>
      <ul>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.1.1. Le client comprend et accepte qu’en contractant T.D.
                    pour la recherche d’un stage ou en contrat d’apprentissage
                    et tout autre service lié, le client s’engage à payer le
                    Package auquel il a souscrit en respectant les délais prévus
                    par le contrat pour le temps passé à réaliser la mission
                    commanditée à T.D.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.1.2. Le client s’engage à suivre le processus étape par
                    étape tel que décrit par T.D.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.1.3. Le client s’engage à régler chaque facture reçue de
                    la part de T.D. en temps et heure et dans les délais définis
                    ensemble, et ce, pour chaque service fourni.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.1.4. Le client reconnaît que tout délai prévisionnel
                    fourni par T.D. pour son placement reste à titre indicatif.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.1.5. Le client s’engage à informer T.D. des dates de début
                    et de fin de stage ou de contrat d’apprentissage prévues par
                    sa formation lors de son inscription et/ou lors du premier
                    entretien avec une personne de l’équipe T.D. En cas de
                    changement ultérieur de ces dates, T.D ne peut garantir
                    l’offre initialement trouvé et ne sera pas tenu responsable
                    en cas de refus de l’entreprise d’accueil des nouvelles
                    dates. T.D ne procédera à aucun remboursement.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.1.6. Le client s’engage à ne pas passer outre le processus
                    de recrutement de T.D. pour obtenir un stage ou un contrat
                    d’apprentissage directement auprès des entreprises d’accueil
                    du réseau de T.D dans un délai de 5 ans. Ce délai s’applique
                    au client écoles tout comme aux étudiants.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.1.7. Le client s’engage à ne pas contacter directement
                    l’entreprise d’accueil sans le consentement de T.D. avant
                    d’avoir payé le montant total dû à T.D. pour ses services.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.1.8. T.D. comprend que le client peut être amené à
                    partager certaines informations relatives à son stage, à son
                    contrat d’apprentissage ou à son logement avec des tiers
                    (par exemple : le centre de formation du client et/ou ses
                    parents). Toutefois, le client ne peut en aucun cas
                    divulguer les coordonnées des entreprises d’accueil ou des
                    partenaires à un tiers, à d’autres fins que celles
                    nécessaires au bon déroulement du stage ou du contrat
                    d’apprentissage (ex. : amis, camarades de classe,
                    publication sur les réseaux sociaux) et ce, que ce soit
                    avant, durant ou après le stage, sa période d’apprentissage
                    ou le séjour dans le logement. Dans le cas contraire, T.D.
                    se réserve le droit d’annuler le contrat qui le lie au
                    client et d’informer ses partenaires pour les inviter à
                    faire de même pour les services commandités par le client
                    auprès d’eux (en particulier pour le logement). T.D se
                    réserve par ailleurs le droit d’entreprendre toute action en
                    justice nécessaire pour préserver les intérêts de
                    l’entreprise.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.1.9. Une fois que le client accepte le stage, il s’engage
                    à envoyer sa convention de stage en français ou en anglais
                    par email à T.D. dans les 2 jours suivants son acceptation
                    par l’entreprise d’accueil, porque T.D. puisse l’assister à
                    la signature. Dans le cas contraire, T.D. ne pourra être
                    tenu responsable en cas d’annulation du stage par
                    l’entreprise d’accueil ou de non-signature de la convention
                    de stage par l’entreprise d’accueil. Si l’étudiant ou
                    l’école ne disposent pas de convention de stage, T.D. peut
                    leur proposer une convention type en français/anglais.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.1.10. Si le client (ou son école ou université ou tout
                    autre tiers représentant) envoie directement la convention
                    de stage à l’entreprise d’accueil (par e-mail ou par
                    courrier), T.D. ne peut être tenu responsable de la
                    non-signature de la convention de stage ou de l’annulation
                    du stage par l’entreprise d’accueil.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.1.11. Le client comprend et accepte que certains retards
                    puissent survenir lors du renvoi de la convention de stage
                    par l’entreprise d’accueil. L’entreprise d’accueil peut
                    mettre du temps pour lire la convention, pour la faire
                    relire par un avocat, pour la faire accepter par le
                    directeur ou pour confirmer les dates définitives de stage
                    etc. T.D. ne peut pas être considéré comme responsable de
                    ces délais.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.1.12. Une fois la convention de stage signée par
                    l’entreprise d’accueil, il est de la responsabilité du
                    client de faire signer sa convention de stage auprès de son
                    université, école ou organisme d’enseignement et de la
                    déposer sur son profil personnel scannée. T.D. ne pourra en
                    aucun cas être tenu responsable d’un quelconque manquement
                    de la part du client ou de son école concernant la
                    non-signature complète de la convention de stage.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>3.2&nbsp;</span>
            </span>
          </span>
          <em>
            <span style={{ fontSize: "10.5pt" }}>
              <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                <span style={{ color: "#0b1941" }}>
                  Ressources financières du client :
                </span>
              </span>
            </span>
          </em>
        </span>
      </span>
      <ul>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.2.1. Il est de la responsabilité du client d’avoir les
                    ressources financières nécessaires pour régler les services
                    de T.D. Le client doit s’assurer d’avoir suffisamment de
                    ressources financières avant de commencer le processus avec
                    T.D.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.2.2. Il incombe au client d’être conscient que voyager à
                    l’étranger engendre certains coûts et qu’il est nécessaire
                    d’avoir les ressources financières pour vivre dans le pays.
                    Il est conseillé de se renseigner à l’avance sur les
                    ressources financières nécessaires pour vivre à Paris.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.2.3. Le client accepte que T.D. ne puisse être tenu
                    responsable de tout inconvénient ou perte de revenu en
                    raison du processus de placement.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.2.4. Il appartient au client de s’assurer de l’obtention
                    de toute gratification mentionnée dans l’offre de
                    l’entreprise, au cours de l’entretien et/ou lors de la
                    signature de la convention de stage ou signature du contrat
                    d’apprentissage, auprès de l’entreprise d’accueil et non de
                    T.D.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.2.5. Dans le cas de la demande d’un Visa, et en fonction
                    de différentes nationalités, une attestation de ressources
                    suffisante pourra vous être demandée à l’étudiant par les
                    autorités compétentes.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                3.3. Assurance et comportement du client :
              </span>
            </span>
          </span>
        </span>
      </span>
      <ul>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.3.1. Il est de la responsabilité du client de souscrire
                    une assurance responsabilité civile, une assurance médicale,
                    une assurance voyage et/ou une assurance tous risques. Il
                    est également de la responsabilité du client de souscrire
                    une assurance habitation en cas de location d’appartement
                    avec l’un de nos partenaires.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.3.2. Le client est responsable de sa propre sécurité au
                    cours du voyage et durant son séjour.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.3.3. Le client s’engage à se comporter respectueusement
                    pendant sa période en entreprise en participant activement
                    au développement de l’entreprise en adoptant une attitude
                    professionnelle, efficace et productive et en représentant
                    au mieux les intérêts de l’entreprise d’accueil en réalisant
                    toutes les missions définies dans l’offre.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.3.4. Le client doit se conformer au code de conduite de
                    l’entreprise d’accueil pendant toute la durée de son stage
                    ou de son contrat d’apprentissage. L’étudiant est soumis à
                    la discipline et aux clauses du règlement intérieur
                    applicables dans l’entreprise d’accueil et qui sont portées
                    à sa connaissance avant le début du stage ou du contrat
                    d’apprentissage, notamment en ce qui concerne les horaires
                    et les règles d’hygiène et de sécurité en vigueur dans
                    l’organisme d’accueil. Par ailleurs, le client est tenu de
                    respecter la culture d’entreprise et ses politiques
                    internes, qui peuvent être consignées dans un manuel du
                    personnel ou communiquées verbalement.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.3.5 L’étudiant s’engage à réaliser sa mission et à être
                    disponible pour les tâches qui lui sont confiées, respecter
                    les règles de l’entreprise ainsi que ses codes, sa culture,
                    et les exigences de confidentialité fixées par l’entreprise.
                    L’étudiant s’engage à ne pas faire de copie de documents
                    internes appartenant à l’entreprise sans avoir obtenu
                    l’autorisation et à ne pas diffuser les informations
                    recueillies par lui, sauf accord explicite de l’entreprise.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.3.6. Il va de la responsabilité de l’étudiant de respecter
                    le planning transmis par l’entreprise d’accueil et de le
                    suivre. Celui-ci peut être mis à jour régulièrement et
                    modifié en fonction des besoins de l’entreprise. L’étudiant
                    est préparé à être flexible et conscient des exigences du
                    métier. L’entreprise d’accueil pourra lui demander de
                    changer de planning ou se s’adapter aux besoins en
                    travaillant quelques heures supplémentaires au besoin et
                    dans des délais d’information courts.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.3.7. L’étudiant est entièrement responsable de ses actes
                    pendant toute la durée de sa période en entreprise et/ou du
                    séjour dans le logement. Il est responsable de toute perte
                    ou dommage résultant de ses actes. L’étudiant s’engage à
                    indemniser T.D., l’entreprise d’accueil, les propriétaires,
                    les locataires principaux, les agences de logement, les
                    autres prestataires de services (taxis, services de
                    nettoyage…) ou tout autre tiers qui pourrait subir des
                    pertes ou dommages dus à la conduite de l’étudiant.
                    L’étudiant doit être assuré pour tout dommage ou perte qui
                    pourrait être causé pendant sa présence en entreprise ou le
                    séjour.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.3.8. L’étudiant s’engage à respecter la loi française et à
                    ne pas se mettre en danger ou se causer du tort à lui-même
                    et/ou mettre en danger ou causer du mal un tiers. Si
                    l’étudiant n’agit pas en conformité avec les faits énoncés
                    ci-dessus, il sera considéré comme entièrement responsable
                    des conséquences de ses actes.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>3.4. Documents :</span>
            </span>
          </span>
        </span>
      </span>
      <ul>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.4.1. Il est de la responsabilité de l’étudiant d’acheter
                    son billet pour voyager et d’obtenir tous les documents
                    nécessaires pour voyager à l’étranger (un visa et/ou un
                    permis de séjour et une assurance) et d’être disponible à la
                    date de début du stage ou du contrat d’apprentissage. T.D.
                    ne peut être tenu responsable si le client n’obtient pas son
                    visa ou tout autre document nécessaire pour voyager /
                    résider à l’étranger et aucun remboursement des frais de
                    T.D. ne sera effectué.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <h5>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: '"Calibri Light",sans-serif' }}>
            <span style={{ color: "#2f5496" }}>
              <strong>
                <span style={{ fontSize: "13.5pt" }}>
                  <span style={{ fontFamily: '"EB Garamond"' }}>
                    <span style={{ color: "#0b1941" }}>
                      4. L’Entreprise d’Accueil
                    </span>
                  </span>
                </span>
              </strong>
            </span>
          </span>
        </span>
      </h5>
      <ul>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    4.1. L’entreprise d’accueil est le seul décideur en ce qui
                    concerne le choix des candidats pour les entretiens. Afin
                    d’être éligible au stage ou contrat d’apprentissage,
                    l’étudiant doit avoir rempli tous les critères demandés par
                    l’entreprise qui va le recevoir.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    4.2. L’entreprise d’accueil doit fournir des missions de
                    travail et des tâches correspondant à celles prévues dans la
                    convention de stage ou contrat d’apprentissage établie avant
                    le commencement dans l’entreprise d’accueil.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    4.3. Les entreprises d’accueil sont soumises aux lois et
                    doivent s’y conformer. Les avantages fournis par les
                    entreprises d’accueil (gratification, hébergement,
                    remboursement du titre de transport, repas…) diffèrent et ne
                    peuvent être garantis par T.D avant la signature de la
                    convention de stage ou contrat d’apprentissage.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    4.4. La responsabilité civile de l’entreprise d’accueil est
                    engagée pour tous dommages (corporels ou matériels) subis
                    par l’étudiant pendant ses heures de travail ou sur le
                    trajet domicile-entreprise. Si le client est à l’origine du
                    dommage causé, c’est sa propre responsabilité civile qui est
                    engagée. Certains établissements scolaires peuvent également
                    fournir une couverture responsabilité civile à l’étudiant.
                    Dans le cadre d’un contrat d’apprentissage, le client est
                    couvert par la même couverture sociale qu’un salarié de
                    l’entreprise d’accueil qui est soumise aux cotisations
                    sociales prévues par la Loi. L’entreprise d’accueil engage
                    sa responsabilité civile envers l’apprentis que celui-ci
                    soit ou non à l’origine du dommage causé.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    4.5. Toutes négociations concernant les horaires, les bonus,
                    les vacances se tiennent directement entre le client et
                    l’entreprise d’accueil. T.D. demande toutefois à être
                    informé avant toutes négociations pour s’assurer de la
                    conformité de la requête et conseiller le client le cas
                    échéant.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    4.6. L’étudiant est responsable de son uniforme pendant la
                    durée du stage ou contrat d’apprentissage. Les étudiants
                    doivent se présenter dans l’entreprise d’accueil avec
                    l’uniforme complet et propre de leur école (sauf si
                    l’entreprise d’accueil fournit son propre uniforme).
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    4.7. L’entreprise d’accueil et T.D. confirment qu’aucune
                    absence ne sera tolérée pendant la période en entreprise. Si
                    l’étudiant est vraiment dans l’incapacité de se rendre dans
                    l’entreprise d’accueil, que ce soit pour des raisons
                    médicales ou des circonstances exceptionnelles, il est
                    impératif de prévenir l’entreprise le plus tôt possible afin
                    que celle-ci trouve une solution de remplacement. Tout motif
                    médical doit être justifié par une visite chez le médecin et
                    par un certificat médical envoyé à l’entreprise dans les
                    plus brefs délais. Des absences répétées, non autorisées ou
                    injustifiées seront vues de façon très négative par
                    l’entreprise d’accueil et des sanctions pourront être
                    décidées, telle que l’arrêt du stage ou du contrat
                    d’apprentissage. Dans ces conditions, l’étudiant ne pourra
                    prétendre à aucun remboursement de la part de T.D.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <h5>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: '"Calibri Light",sans-serif' }}>
            <span style={{ color: "#2f5496" }}>
              <strong>
                <span style={{ fontSize: "13.5pt" }}>
                  <span style={{ fontFamily: '"EB Garamond"' }}>
                    <span style={{ color: "#0b1941" }}>5. Prix</span>
                  </span>
                </span>
              </strong>
            </span>
          </span>
        </span>
      </h5>
      <ul>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    5.1. T.D. se réserve le droit de modifier ses tarifs.
                    Toutefois, les services sont facturés sur la base des tarifs
                    en vigueur à la date de signature du contrat du client.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    5.2. Les tarifs sont affichés en Euros et toutes taxes
                    comprises.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    5.3. Les tarifs n’incluent pas les billets d’avion, les
                    assurances, les frais de visa, les excédents de bagages, les
                    taxes d’aéroport, la nourriture, les transports publics, les
                    excursions, les activités supplémentaires, les transferts en
                    taxi, le loyer mensuel du logement, l’argent de poche et
                    toute autre dépense non indiqué sur le forfait de T.D.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    5.4. L’étudiant s’engage à effectuer les paiements selon un
                    échéancier précis&nbsp;: un acompte correspondant au forfait
                    sélectionné devra être versé à T.D à la signature du
                    contrat. Le solde du forfait sélectionné est dû avant la
                    communication des coordonnées de l’entreprise d’accueil
                    choisie pour le client et la prise de rendez-vous pour
                    l’entretien avec ladite entreprise.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    5.5. Tarifs de T.D. :
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Forfait Futur Talent : 500 Euros TTC&nbsp;
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Forfait Futur Talent Premium : 900 Euros TTC&nbsp;
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Forfait Futur Talent Premium Plus : 1500 Euros TTC&nbsp;
              </span>
            </span>
          </span>
        </span>
      </span>
      <h5>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: '"Calibri Light",sans-serif' }}>
            <span style={{ color: "#2f5496" }}>
              <strong>
                <span style={{ fontSize: "13.5pt" }}>
                  <span style={{ fontFamily: '"EB Garamond"' }}>
                    <span style={{ color: "#0b1941" }}>
                      &nbsp;6. Paiement du Forfait et/ou Package : l’acompte et
                      le solde
                    </span>
                  </span>
                </span>
              </strong>
            </span>
          </span>
        </span>
      </h5>
      <ul>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    6.1. Processus
                  </span>
                </span>
              </span>
            </span>
          </span>
          <ul style={{ listStyleType: "disc" }}>
            <li>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#0b1941" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontSize: "10.5pt" }}>
                      <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                        6.1.1. T.D. reçoit la demande de recherche de stage ou
                        un contrat d’apprentissage du client par son inscription
                        à la plateforme T.D. ou via l’école ou le CFA de
                        l’étudiant. L’étudiant reçoit une proposition de
                        rendez-vous avec T.D pour parler de ses attentes et pour
                        que T.D propose le service le plus adapté au client. Si
                        le client est intéressé par le service, il règle
                        l’acompte correspondant au forfait sélectionné après son
                        entretien. Le client devra également compléter les
                        étapes et fournir les documents demandés sur son profil
                        personnel.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#0b1941" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontSize: "10.5pt" }}>
                      <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                        6.1.2. T.D. corrige le CV et la lettre de motivation du
                        client, prépare et envoi la candidature du client aux
                        entreprises d’accueil correspondant aux offres en
                        entreprise identifiées et sélectionnées pour le client.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#0b1941" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontSize: "10.5pt" }}>
                      <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                        6.1.3. Lorsqu’une première entreprise d’accueil se
                        montre intéressée par la candidature du client et pour
                        permettre la mise en relation, T.D. demande le versement
                        du solde du forfait correspondant au Package sélectionné
                        pour et par le client. T.D ne divulguera pas les
                        coordonnées ou le nom de l’entreprise d’accueil
                        présélectionnée pour le client avant d’avoir reçu le
                        solde dû pour le forfait.
                        <br />
                        L’entreprise d’accueil peut manifester son intérêt pour
                        le profil du client et demander l’organisation d’un
                        premier entretien en face à face ou à distance (Skype,
                        Whatsapp, téléphone…) avec le client. L’entreprise
                        d’accueil peut également demander d’autres entretiens en
                        fonction de son processus de recrutement interne ou une
                        vidéo de présentation, un test écrit ou tout autre test
                        de connaissance ou de compétence au client avant de
                        prendre sa décision.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#0b1941" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontSize: "10.5pt" }}>
                      <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                        6.1.4. Si plusieurs entreprises sont intéressées par le
                        profil du client, T.D s’engage à sélectionner
                        l’entreprise correspondant au meilleur intérêt du client
                        en fonction des informations fournies par ce dernier
                        lors des différentes étapes du service.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#0b1941" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontSize: "10.5pt" }}>
                      <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                        6.1.5. Certaines entreprises d’accueil acceptent la
                        candidature des clients sans entretien préalable. Dans
                        ce cas, T.D. demande le règlement du solde au client dès
                        que la candidature du client a retenu l’attention de
                        l’entreprise d’accueil. Une fois le paiement effectué
                        par le client, T.D communique les informations
                        concernant l’entreprise favorable à sa candidature. Ces
                        informations permettront au client de prendre une
                        décision concernant l’offre de l’entreprise d’accueil.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#0b1941" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontSize: "10.5pt" }}>
                      <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                        6.1.6. Le client s’engage à payer les frais d’acompte et
                        le solde à réception de la facture de T.D.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#0b1941" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontSize: "10.5pt" }}>
                      <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                        6.1.7. T.D. envoie toutes les informations nécessaires
                        au client pour lui permettre de préparer son entretien
                        avec l’entreprise d’accueil (livret de conseils, nom des
                        contacts RH et tuteur, site internet et contact de
                        l’entreprise d’accueil) après réception du paiement du
                        solde du client.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#0b1941" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontSize: "10.5pt" }}>
                      <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                        6.1.8. Il est de la responsabilité du client de poser
                        toutes les questions nécessaires concernant son stage ou
                        contrat d’apprentissage (missions, dates de début et fin
                        en entreprise, gratification, conditions de travail,
                        horaires de travail, lieu de travail) pendant
                        l’entretien avec l’entreprise d’accueil ou avant
                        d’accepter le stage.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    6.2. Paiement et remboursement de l’acompte et/ou du solde
                    du Package&nbsp;:
                  </span>
                </span>
              </span>
            </span>
          </span>
          <ul style={{ listStyleType: "disc" }}>
            <li>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#0b1941" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontSize: "10.5pt" }}>
                      <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                        6.2.1. Tous les paiements sont sécurisés et doivent être
                        effectués par via le portail étudiant ou virements
                        bancaire. Les chèques bancaires où les espèces ne sont
                        pas acceptés.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#0b1941" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontSize: "10.5pt" }}>
                      <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                        6.2.2. Le paiement des frais d’acompte puis du solde
                        doit être effectué dans les deux jours (48h) suivant la
                        réception de la facture.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#0b1941" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontSize: "10.5pt" }}>
                      <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                        6.2.3. Il est de la responsabilité du client de
                        s’assurer que le paiement est possible et peut être
                        effectué à temps.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#0b1941" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontSize: "10.5pt" }}>
                      <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                        6.2.4. Les tarifs des services proposés étant en Euros
                        (€), les variations de taux de change ne sont pas
                        remboursables ou imputables à T.D.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#0b1941" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontSize: "10.5pt" }}>
                      <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                        6.2.5. T.D. peut utiliser les services d’une entreprise
                        de recouvrement pour assurer le paiement de ses services
                        dans le cas où le client ne s’acquitte pas des factures
                        envoyées par T.D et des sommes dues.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#0b1941" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontSize: "10.5pt" }}>
                      <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                        6.2.6. Une fois le solde payé, si le client échoue au
                        premier entretien ou test requis par l’entreprise
                        d’accueil, T.D. pourra organiser un maximum de 3
                        entretiens ou tests avec les entreprises d’accueil.
                        Cependant, le client comprend et accepte que T.D. ne
                        peut s’engager et garantir l’organisation d’autres
                        entretiens ou d’autres tests, car cela dépend de la
                        volonté des entreprises d’accueil et de leur intérêt
                        pour le profil du client.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#0b1941" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontSize: "10.5pt" }}>
                      <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                        6.2.7. T.D. ne peut être tenu responsable si aucune
                        entreprise d’accueil n’est intéressée par le profil du
                        client (pour un entretien ou un test), mais n’ayant pas
                        été capable de fournir la prestation, T.D. s’engage à
                        rembourser le montant de l’acompte perçu.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#0b1941" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontSize: "10.5pt" }}>
                      <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                        6.2.8. Si le client décide de ne pas se présenter à
                        l’entretien prévu avec l’entreprise d’accueil, après
                        réception du message de T.D. pour paiement du solde, le
                        solde reste dû à T.D. part le client.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#0b1941" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontSize: "10.5pt" }}>
                      <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                        6.2.9. Si le client décide d’abandonner le processus de
                        placement, pour quelque raison que ce soit (y compris
                        parce qu’il a obtenu un stage ou un contrat
                        d’apprentissage en dehors du processus de placement de
                        T.D.), avant le paiement du solde, le solde à payer
                        reste dû à T.D. part le client.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#0b1941" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontSize: "10.5pt" }}>
                      <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                        6.2.10. Si le client change d’avis après avoir été
                        accepté par une entreprise d’accueil (après ou sans
                        entretien préalable) et refuse l’offre, le remboursement
                        des sommes déjà versées ne sera effectué sous aucune
                        condition.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#0b1941" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontSize: "10.5pt" }}>
                      <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                        6.2.11. L’annulation de la candidature du client sur la
                        base des motifs suivants, mais non limités à ceux-ci :
                        changement d’avis, perte d’intérêt pour l’offre, attente
                        d’entretiens avec des entreprises non liées à T.D.,
                        recherche d’une entreprise d’accueil par lui-même,
                        changement de dates, changement de domaine d’études,
                        manque de ressources financières, non-obtention de
                        crédits ou de toute subvention, bourse, ou autres
                        documents administratifs (tels que, mais non limités à :
                        visa, permis de séjour, etc.) ou toute autre raison ne
                        figurant pas dans la liste ci-dessus, ne sera pas
                        considérée comme un motif valable pour ne pas
                        régler&nbsp;l’acompte ou pour en demander le
                        remboursement.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#0b1941" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontSize: "10.5pt" }}>
                      <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                        6.2.12. Si le comportement du client est jugé non
                        professionnel, et ce, à n’importe quelle étape du
                        processus (ex. : envoie de documents hors délais,
                        comportement incorrect envers T.D, l’entreprise
                        d’accueil ou tout autre partenaire), T.D. peut décider
                        de rompre son engagement avec le client et ce sans
                        qu’aucun remboursement ne puisse être demandé par Le
                        client.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
        </li>
      </ul>
      <h5>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: '"Calibri Light",sans-serif' }}>
            <span style={{ color: "#2f5496" }}>
              <strong>
                <span style={{ fontSize: "13.5pt" }}>
                  <span style={{ fontFamily: '"EB Garamond"' }}>
                    <span style={{ color: "#0b1941" }}>
                      7. Changement d’Entreprise d’Accueil ou remboursement du
                      Package :
                    </span>
                  </span>
                </span>
              </strong>
            </span>
          </span>
        </span>
      </h5>
      <ul>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    7.1. En cas de problème avec l’entreprise d’accueil, le
                    client doit contacter T.D. rapidement afin de lui permettre
                    de trouver des solutions.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <ul>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    7.2. Lorsque le client n’a pas encore commencé son stage ou
                    son contrat d’apprentissage&nbsp;:
                  </span>
                </span>
              </span>
            </span>
          </span>
          <ul style={{ listStyleType: "disc" }}>
            <li>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#0b1941" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontSize: "10.5pt" }}>
                      <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                        7.2.1. L’annulation du stage ou contrat d’apprentissage
                        du client sur la base des motifs suivants, mais non
                        limités à ceux-ci : changement d’avis, perte d’intérêt
                        pour l’offre, attente d’entretiens avec des entreprises
                        non liées à T.D., avoir trouvé une autre entreprise
                        d’accueil par lui-même, changement de dates, changement
                        de domaine d’études, renvoi ou exclusion de
                        l’établissement d’enseignement, manque de ressources
                        financières, non-obtention de crédits ou toute autre
                        subvention, bourse, visa, ou toute autre raison ne
                        figurant pas dans la liste ci-dessus ne sera pas
                        considérée comme un motif valable pour ne pas régler la
                        facture du Package ou pour en demander le remboursement.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#0b1941" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontSize: "10.5pt" }}>
                      <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                        7.2.2. Le client comprend et accepte qu’en contractant
                        T.D. pour la recherche d’une entreprise d’accueil et/ou
                        pour tout autre service, T.D. prévoit du temps pour
                        réaliser le travail demandé par le client. Par
                        conséquent, T.D. demande au client de payer le package
                        sélectionné pour le travail accompli et le temps passé.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
        </li>
      </ul>
      <ul>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    7.3. Lorsque le client a déjà commencé dans l’entreprise
                    d’accueil :
                  </span>
                </span>
              </span>
            </span>
          </span>
          <ul style={{ listStyleType: "disc" }}>
            <li>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#0b1941" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontSize: "10.5pt" }}>
                      <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                        7.3.1. La demande de changement d’Entreprise d’Accueil
                        d’un client, ne sera prise en compte que dans les cas
                        reconnus par la loi, tels que : – Harcèlement sexuel ou
                        moral. – Diffamation raciale ou religieuse. –
                        Impossibilité de la part de l’entreprise d’accueil de
                        poursuivre la formation en entreprise. – Déclaration de
                        faillite de l’entreprise d’accueil. – Non-respect des
                        mesures de sécurité ou de santé susceptible de nuire ou
                        d’affecter le client. – Défaut de corrélation entre les
                        missions et tâches confiées au client et celles
                        spécifiées dans la convention de stage ou le contrat
                        d’apprentissage signée entre le client et l’Entreprise
                        d’accueil.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#0b1941" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontSize: "10.5pt" }}>
                      <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                        7.3.2. En cas d’insatisfaction du client vis-à-vis de
                        l’entreprise d’accueil, T.D. s’engage à écouter le(s)
                        motif(s) et à faire de son mieux pour proposer des
                        solutions viables pour le client et pour l’entreprise
                        d’accueil.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#0b1941" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontSize: "10.5pt" }}>
                      <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                        7.3.3. Si le client rencontre des problèmes durant son
                        stage ou sa période d’apprentissage en entreprise avec
                        son logement ou de tout autre service souscrit par
                        l’intermédiaire de T.D, il doit d’abord contacter T.D.,
                        T.D. étudiera la situation, donnera des conseils pour
                        que l’étudiant puisse contacter son tuteur dans
                        l’entreprise d’accueil ou le partenaire ayant fourni un
                        service au client. T.D. communiquera avec l’entreprise
                        d’accueil ou les partenaires et proposera d’organiser
                        une rencontre ou une conférence téléphonique entre T.D.,
                        le client et l’établissement d’accueil ou le partenaire
                        pour trouver des solutions appropriées. Seules les
                        réclamations communiquées sous forme écrite (courrier ou
                        courrier électronique), seront prises en compte.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#0b1941" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontSize: "10.5pt" }}>
                      <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                        7.3.4 Si l’étudiant a le sentiment que l’employeur le
                        traite injustement, il doit demander au tuteur ou
                        manager un rendez-vous pour discuter de ses performances
                        dans l’entreprise. Il faut penser qu’en fonction de la
                        culture de chaque personne les choses peuvent être
                        perçues de façon très différente. L’étudiant décide de
                        venir à Paris pour apprendre un métier, mais également
                        pour comprendre la culture et apprivoiser une façon de
                        manager différente à celle de son pays. L’étudiant doit
                        être à l’écoute des commentaires en les prenant toujours
                        de façon positive et accepter la multi culturalité en
                        gardant un esprit ouvert. Pendant le rendez-vous,
                        l’étudiant &nbsp;demandera à son tuteur s’il y a des
                        domaines sur lesquels il doit s’améliorer et de lui
                        expliquer/démontrer les procédures sur lesquelles il ne
                        se sent pas à l’aise. Si la situation ne s’améliore pas,
                        l’étudiant préviendra T.D. afin qu’il puisse lui
                        apporter de l’aide.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#0b1941" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontSize: "10.5pt" }}>
                      <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                        7.3.5 Avant d’entreprendre toute action au sein de
                        l’entreprise, l’étudiant contactera T.D. afin de lui
                        demander conseil. Les équipes de T.D. ne peuvent pas
                        aider leur client à résoudre un problème dont ils ne
                        seraient pas au courant au préalable&nbsp;!
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#0b1941" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontSize: "10.5pt" }}>
                      <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                        7.3.6. Si le client n’est pas satisfait de l’entreprise
                        d’accueil pour une raison valable et justifiée (voir
                        section 7.3.1) et que des solutions ne peuvent être
                        trouvées, T.D. s’engage à faire de son mieux pour
                        trouver une autre entreprise d’accueil correspondant au
                        profil du client et à son domaine d’activité (selon la
                        disponibilité des entreprises d’accueil), à la durée
                        restante (minimum 3 mois) et au niveau de français du
                        client.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#0b1941" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontSize: "10.5pt" }}>
                      <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                        7.3.7. Dans le cas où le client décide de mettre fin à
                        son stage ou contrat d’apprentissage de lui-même sans en
                        discuter ou en aviser T.D et sans validation préalable
                        de T.D., ou si l’entreprise d’accueil &nbsp;décide de
                        mettre fin, en raison d’un comportement inapproprié
                        (comportement abusif verbalement ou physiquement,
                        comportement raciste, consommation de drogues, d’alcool,
                        mauvaise conduite, insubordination, comportement jugé
                        inacceptable par le tuteur ou les responsables de
                        l’entreprise d’accueil , négligence professionnelle des
                        missions de travail ou non-respect des règles de
                        l’entreprise d’accueil ), T.D se réserve le droit de ne
                        pas rembourser le package ou les services «&nbsp;à la
                        carte&nbsp;» payé par le client et de ne pas replacer le
                        client dans une autre entreprise d’accueil.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#0b1941" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontSize: "10.5pt" }}>
                      <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                        7.3.8. L’étudiant s’engage à respecter la législation
                        française et ne pas se mettre ni mettre un tiers en
                        danger.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
        </li>
      </ul>
      <h5>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: '"Calibri Light",sans-serif' }}>
            <span style={{ color: "#2f5496" }}>
              <strong>
                <span style={{ fontSize: "13.5pt" }}>
                  <span style={{ fontFamily: '"EB Garamond"' }}>
                    <span style={{ color: "#0b1941" }}>
                      8. Partenaires ou Entreprises de Services de TALENT
                      DEVELOPER
                    </span>
                  </span>
                </span>
              </strong>
            </span>
          </span>
        </span>
      </h5>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Selon la situation, T.D. peut parfois travailler avec différents
                partenaires ou entreprises de services sélectionnés pour aider
                l’étudiant dans son installation à Paris et lui procurer
                d’autres services facilitant sa vie quotidienne en France. Vous
                trouvez l’intégralité de nos «&nbsp;services à la carte&nbsp;»
                sur le site internet de T.D.
              </span>
            </span>
          </span>
        </span>
      </span>
      <ul>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    8.1. Partenaire : Le partenaire fait référence à toute
                    société, organisation ou personne physique ou morale
                    employée ou utilisée par T.D. pour ses services (ex : agence
                    de logement, compagnie de transfert, société de service de
                    nettoyage, cours de cuisine et/ou pâtisserie, visites de
                    Paris, cours de vin, et tout autre fournisseur de services
                    avec lequel T.D. peut collaborer afin de fournir des
                    services supplémentaires au client).
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    8.2. Le client comprend et accepte qu’en fonction des
                    services requis, il pourra également être mis en relation
                    avec ces partenaires.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    8.3. Le logement : L’agence de logement désigne toute
                    société offrant des services d’hébergement au client. T.D.
                    n’inclut le logement des étudiants dans aucune de ses
                    prestations mais propose de vous mettre en contact avec ses
                    partenaires et de vous faire bénéficier de conditions
                    avantageuses. Soyez attentif aux tarifs des logements à
                    Paris, qui peuvent être plus ou moins onéreux en fonctions
                    du type de logement et de sa localisation. Dans la
                    prestation de recherche d’un logement à Paris, ces deux
                    partenaires s’engagent à&nbsp;:
                  </span>
                </span>
              </span>
            </span>
          </span>
          <ul style={{ listStyleType: "disc" }}>
            <li>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#0b1941" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontSize: "10.5pt" }}>
                      <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                        La recherche d’une chambre privée ou d’une chambre
                        partagée dans un appartement ou une maison en
                        colocation, d’une chambre privée ou d’une chambre
                        partagée dans une Famille d’Accueil ou d’une location
                        privée.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#0b1941" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontSize: "10.5pt" }}>
                      <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                        La coordination entre le client et le responsable de
                        l’appartement ou de la maison (propriétaire, locataire
                        principal, agence de logement ou famille d’accueil)
                        avant que le client n’entre dans le logement.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    8.4. Agence de courtage d’assurance&nbsp;: Pour la sécurité
                    de l’étudiant et celle des autres, l’étudiant a l’obligation
                    d’être en possession d’une assurance responsabilité civile,
                    d’une assurance habitation/locative et d’une assurance
                    santé/médicale comme indiqué dans les paragraphes
                    précédents.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    8.5. Transfert aéroports/Paris A/R : La compagnie de
                    transfert fait référence à toute entreprise qui collabore
                    avec T.D. afin de fournir des services de transport au
                    client.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    8.6. École de Français : L’école de français fait référence
                    à toute école de langue habilitée qui collabore avec T.D.
                    pour fournir des cours de français FLE (Français langue
                    Étrangère) au client.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <h5>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: '"Calibri Light",sans-serif' }}>
            <span style={{ color: "#2f5496" }}>
              <strong>
                <span style={{ fontSize: "13.5pt" }}>
                  <span style={{ fontFamily: '"EB Garamond"' }}>
                    <span style={{ color: "#0b1941" }}>
                      9. Circonstances inévitables
                    </span>
                  </span>
                </span>
              </strong>
            </span>
          </span>
        </span>
      </h5>
      <ul>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    9.1. T.D. et ses partenaires ou fournisseurs sont libérés de
                    leurs obligations contractuelles en cas de survenance d’un
                    cas de force majeure, ou de tout acte imputable à un tiers,
                    ou de toute autre circonstance ou cause externe indépendante
                    du personnel de T.D., empêchant directement ou indirectement
                    T.D. de respecter ses obligations contractuelles. Outre les
                    cas reconnus par les tribunaux, les cas de force majeure
                    comprennent, entre autres, les cas suivants : toutes
                    catastrophes naturelles, actes de guerre, troubles de
                    l’ordre public, épidémies, incendies, inondations et autres
                    catastrophes, actes de tout gouvernement, toutes grèves sous
                    quelque forme que ce soit (internes, externes …) et tout
                    dysfonctionnement d’Internet ou des réseaux. Les services
                    fournis par T.D. et ses partenaires ou fournisseurs ne
                    seront alors pas remboursables.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <h5>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: '"Calibri Light",sans-serif' }}>
            <span style={{ color: "#2f5496" }}>
              <strong>
                <span style={{ fontSize: "13.5pt" }}>
                  <span style={{ fontFamily: '"EB Garamond"' }}>
                    <span style={{ color: "#0b1941" }}>
                      10. Droit applicable et résolution des litiges
                    </span>
                  </span>
                </span>
              </strong>
            </span>
          </span>
        </span>
      </h5>
      <ul>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    10.1. Ces termes et conditions sont valables à partir du 01
                    octobre 2020 et sont soumis à la Loi Française. Si des
                    modifications sont apportées aux termes et conditions, elles
                    seront valides au moment de leur publication sur le site
                    internet de T.D.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    10.2. Ce contrat est soumis à la Loi Française. La
                    juridiction compétente en cas de litige est au tribunal de
                    Paris.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    10.3. Ces termes et conditions représentent un contrat entre
                    le client et T.D.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Conditions&nbsp;TALENT DEVELOPER&nbsp;01 octobre 2020
              </span>
            </span>
          </span>
        </span>
      </span>
    </>
  );
};

export default CGVContentFR;
