import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core";

const useStyle = makeStyles({
  loaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "2vh 2vw 2vh 2vw",
  },
});

const TalentLoader = () => {
  // Load style
  const classes = useStyle();

  return (
    <div className={classes.loaderContainer}>
      <CircularProgress color="primary" />
    </div>
  );
};

export default TalentLoader;
