import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, TextField, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import TalentButton from "../core/TalentButton";
import { useAuth } from "../../context/auth-context";
import TalentLoader from "../core/TalentLoader";
import { Link } from "react-router-dom";

const useStyle = makeStyles({
  textField: {
    /*borderBottomColor: "#ffffff",
    "& .MuiInput-underline": {
      color: "#ffffff",
    },
    "& label": {
      color: "#ffffff",
    },
    "& label.Mui-focused": {
      color: "#ffffff",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#ffffff",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottomColor: "#ffffff",
    },*/
  },
});

const SigninComponent = () => {
  // Load auth
  const auth = useAuth();

  // Load style
  const classes = useStyle();

  // Load translations
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  // Field values
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // Error check
  const [emailError, setEmailError] = useState(false);
  const [emailMsgError, setEmailMsgError] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordMsgError, setPasswordMsgError] = useState("");
  const [connectionError, setConnectionError] = useState(false);

  // Connect function
  const handleConnect = async () => {
    if (email === "") {
      setEmailError(true);
      setEmailMsgError(t("RequiredField"));
    }

    if (password === "") {
      setPasswordError(true);
      setPasswordMsgError(t("RequiredField"));
    }

    // Try to connect
    if (password !== "" && email !== "") {
      setLoading(true);
      try {
        // Signin the user
        await auth.signin(email, password);
      } catch (err) {
        setEmailError(true);
        setPasswordError(true);
        setConnectionError(true);
        setLoading(false);
      }
    }
  };

  return (
    <>
      <TextField
        label={t("Email")}
        onChange={(e) => {
          setEmailError(false);
          setConnectionError(false);
          setEmailMsgError("");
          setEmail(e.target.value);
        }}
        defaultValue={email}
        required
        fullWidth
        error={emailError}
        helperText={emailError ? emailMsgError : null}
        className={classes.textField}
        data-testid="login-input-email"
      />

      <TextField
        label={t("Password")}
        type="password"
        onChange={(e) => {
          setPasswordError(false);
          setConnectionError(false);
          setPasswordMsgError("");
          setPassword(e.target.value);
        }}
        defaultValue={password}
        required
        fullWidth
        error={passwordError}
        helperText={passwordError ? passwordMsgError : null}
        className={classes.textField}
        data-testid="login-input-password"
      />

      {loading && <TalentLoader />}
      {connectionError && (
        <Alert severity="error">{t("WrongEmailOrPass")}</Alert>
      )}

      <TalentButton
        variant="contained"
        onClick={handleConnect}
        style={{
          backgroundColor: "white",
          border: "1px solid #062d84",
          color: "#062d84",
          boxShadow: "none",
        }}
        disabled={loading}
        data-testid="login-btn-connect"
      >
        {t("Connect")}
      </TalentButton>

      <Link
        to="/forgot_password"
        style={{ textDecoration: "none", marginTop: "1em" }}
      >
        <Typography
          variant="subtitle1"
          color="secondary"
          className={classes.root}
        >
          {t("ForgotPassword")}
        </Typography>
      </Link>
    </>
  );
};

export default SigninComponent;
