import { makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TalentSelectLang from "../TalentSelectLang";

const useStyle = makeStyles({
  footer: {
    position: "sticky",
    zIndex: "1",
    padding: "1.5rem",
    height: "2.5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    opacity: "O.5",
    "& a": {
      textDecoration: "none",
      color: "#0b1941",
    },
  },
  iframeContainer: {
    zIndex: "1200",
    display: "flex",
    flexDirection: "column",
  },
  iframe: {
    width: "80vw",
    height: "80vh",
  },
});

const frLinks = {
  site: "https://www.talent-developer.com/fr/accueil/",
  privacy: "https://www.talent-developer.com/fr/politique-de-confidentialite/",
  cgv: "https://www.talent-developer.com/fr/contrat/",
};

const enLinks = {
  site: "https://www.talent-developer.com/en/home-page/",
  privacy: "https://www.talent-developer.com/en/privacy-policy/",
  cgv: "https://www.talent-developer.com/en/terms-of-sale/",
};

const esLinks = {
  site: "https://www.talent-developer.com/es/inicio/",
  privacy: "https://www.talent-developer.com/es/politica-de-privacidad/",
  cgv: "https://www.talent-developer.com/es/condiciones-generales-de-venta/",
};

const AppFooter = ({ children }) => {
  // Load styles
  const classes = useStyle();

  // Load translations and langs
  const { t, i18n } = useTranslation();

  const [links, setLinks] = useState(enLinks);

  const [reloadParent, setReloadParent] = useState(false);

  // Load the correct document
  useEffect(() => {
    switch (i18n.language) {
      case "fr-FR":
        setLinks(frLinks);
        break;
      case "es-ES":
        setLinks(esLinks);
        break;
      default:
        setLinks(enLinks);
        break;
    }
  }, [i18n, reloadParent]);

  return (
    <footer id="footer" className={classes.footer}>
      {children}
      <Typography variant="body2" component="p">
        {t("FooterAllRightReserved")}
        {" / "}
        <a href={links.site} target="_blank" rel="noreferrer">
          {t("FooterSite")}
        </a>
        {" / "}
        <a href={links.privacy} target="_blank" rel="noreferrer">
          {t("FooterPrivacy")}
        </a>
        {" / "}
        <a href={links.cgv} target="_blank" rel="noreferrer">
          {t("FooterCGV")}
        </a>
      </Typography>

      <TalentSelectLang reloadFooter={() => setReloadParent(!reloadParent)} />
    </footer>
  );
};

export default AppFooter;
