import React from "react";
import { Stepper, Step, StepLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  invisible: {
    display: "none",
  },
  step: {
    "& .MuiStepConnector-root": {
      top: "9px",
      left: "calc(-50% + 9px)",
      right: "calc(50% + 9px)",
      "& .MuiStepConnector-line": {
        borderColor: "rgba(#858ca0, 0.5)",
      },
    },
  },
  stepLabel: {
    "& .MuiSvgIcon-root": {
      width: "18px",
      height: "18px",
      borderRadius: "50%",
      /* DISABLE (Global) */
      fill: "#f2f4f8",
      stroke: "#dc5145",
      strokeWidth: "4px",
      /* CURRENT */
      '&[class*="active"]': {
        fill: "#dc5145",
        stroke: "none",
      },
      /* COMPLETE */
      '&[class*="completed"]': {
        stroke: "none",
        fill: "#858ca0",
      },
      "& .MuiStepIcon-text": {
        display: "none",
      },
    },
  },
});

const TalentStepper = (props) => {
  const classes = useStyles();

  const getSteps = () => {
    let steps = [];
    for (let i = 1; i < props.stepsTotal + 1; i++) {
      steps.push(i);
    }
    return steps;
  };

  const steps = getSteps();

  return (
    <Stepper
      activeStep={props.activeStep - 1}
      alternativeLabel
      style={{
        flex: 1,
      }}
    >
      {steps.map((label) => (
        <Step key={label} className={classes.step}>
          <StepLabel
            className={classes.stepLabel}
            classes={{ labelContainer: classes.invisible }}
          >
            {label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default TalentStepper;
