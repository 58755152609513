import {
  TextField,
  makeStyles,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import TalentContentContainer from "../core/layout/TalentContentContainer";
import TalentFooterContainer from "../core/layout/TalentFooterContainer";
import { Link, Redirect } from "react-router-dom";
import TalentButton from "../core/TalentButton";
import TalentIcon from "../core/TalentIcon";
import { validatePassword } from "../../services/validation.utils";
import TalentLoader from "../core/TalentLoader";
import { useAuth } from "../../context/auth-context";
import authService from "../../services/auth.service";
import Alert from "@material-ui/lab/Alert";
import { useAlert } from "react-alert";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const useStyle = makeStyles({
  btnIcon: {
    marginRight: "0.5em",
  },
});

const EditPassword = () => {
  // Load translation
  const { t } = useTranslation();

  // Load style
  const classes = useStyle();

  // Load auth
  const auth = useAuth();

  // Load alert manager
  const alert = useAlert();

  // Input state
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  // Error state
  const [oldPasswordError, setOldPasswordError] = useState(false);
  const [oldPasswordErrorMsg, setOldPasswordErrorMsg] = useState("");
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [newPasswordErrorMsg, setNewPasswordErrorMsg] = useState("");
  const [rePasswordError, setRePasswordError] = useState(false);
  const [rePasswordErrorMsg, setRePasswordErrorMsg] = useState("");
  const [apiError, setApiError] = useState(false);
  const [apiErrorMsg, setApiErrorMsg] = useState("");

  // Loading state
  const [loading, setLoading] = useState(false);

  // Redirection state
  const [redirect, setRedirect] = useState(false);

  /**
   * Handle show password click
   */
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  /**
   * Handle down password click
   */
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  /**
   * Method called after save button click
   */
  const handleClick = () => {
    setLoading(true);
    const informations = { oldPassword: oldPassword, newPassword: newPassword };
    authService
      .editPassword(auth.user, informations)
      .then((res) => {
        setLoading(false);
        alert.show(t("PasswordChanged"), { type: "success" });
        setRedirect(true);
      })
      .catch((err) => {
        setLoading(false);
        let errormsg = "";
        switch (err.response.status) {
          case 400:
            errormsg = t("PasswordValidationError");
            break;
          case 403:
            errormsg = t("WrongOldPassword");
            break;
          default:
            errormsg = t("ResetPasswordNotWorking");
            break;
        }
        console.error(err);
        setApiError(true);
        setApiErrorMsg(errormsg);
        alert.show(errormsg, { type: "error" });
      });
  };

  /**
   * Method to reset api error on input change
   */
  const resetApiError = () => {
    if (apiError) {
      setApiError(false);
      setApiErrorMsg("");
    }
  };

  /**
   * Manage disable state for the button
   *
   * @returns   boolean
   */
  const manageDisabledState = () => {
    return (
      rePasswordError ||
      rePassword === "" ||
      oldPasswordErrorMsg ||
      oldPassword === "" ||
      newPasswordError ||
      newPassword === "" ||
      rePassword !== newPassword ||
      apiError ||
      loading
    );
  };

  return (
    <>
      {redirect && <Redirect to="/profile" />}
      <TalentContentContainer
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <TextField
          label={t("OldPassword")}
          onChange={(e) => {
            const value = e.target.value;
            if (value === "") {
              setOldPasswordError(true);
              setOldPasswordErrorMsg(t("RequiredField"));
            } else {
              setOldPasswordError(false);
              setOldPasswordErrorMsg("");
            }
            resetApiError();
            setOldPassword(value);
          }}
          defaultValue={oldPassword}
          required
          fullWidth
          type={showPassword ? "text" : "password"}
          error={oldPasswordError}
          helperText={oldPasswordErrorMsg ? oldPasswordErrorMsg : null}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label={t("NewPassword")}
          onChange={(e) => {
            const value = e.target.value;
            if (value === "") {
              setNewPasswordError(true);
              setNewPasswordErrorMsg(t("RequiredField"));
            } else if (!validatePassword(value)) {
              setNewPasswordError(true);
              setNewPasswordErrorMsg(t("PasswordValidationError"));
            } else {
              setNewPasswordError(false);
              setNewPasswordErrorMsg("");
            }
            resetApiError();
            setNewPassword(value);
          }}
          defaultValue={newPassword}
          required
          fullWidth
          type={showPassword ? "text" : "password"}
          error={newPasswordError}
          helperText={newPasswordErrorMsg ? newPasswordErrorMsg : null}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label={t("RePassword")}
          onChange={(e) => {
            const value = e.target.value;
            if (value === "") {
              setRePasswordError(true);
              setRePasswordErrorMsg(t("RequiredField"));
            } else if (value !== newPassword) {
              setRePasswordError(true);
              setRePasswordErrorMsg(t("RePasswordNotCorresponding"));
            } else {
              setRePasswordError(false);
              setRePasswordErrorMsg("");
            }
            resetApiError();
            setRePassword(value);
          }}
          defaultValue={rePassword}
          required
          fullWidth
          type={showPassword ? "text" : "password"}
          error={rePasswordError}
          helperText={rePasswordErrorMsg ? rePasswordErrorMsg : null}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </TalentContentContainer>
      <TalentFooterContainer>
        {loading && (
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <TalentLoader />
          </div>
        )}
        {apiError && (
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <Alert severity="error" style={{ marginBottom: "1em" }}>
              {apiErrorMsg}
            </Alert>
          </div>
        )}
        <Link to="/profile" style={{ textDecoration: "none" }}>
          <TalentButton
            data-testid="edit-password-btn-cancel"
            style={{ margin: "0.5em" }}
          >
            <TalentIcon icon="x-circle" className={classes.btnIcon} />
            {t("Cancel")}
          </TalentButton>
        </Link>
        <TalentButton
          data-testid="edit-password-btn-save"
          style={{ margin: "0.5em" }}
          disabled={manageDisabledState()}
          onClick={() => {
            handleClick();
          }}
        >
          <TalentIcon icon="check-circle" className={classes.btnIcon} />
          {t("Save")}
        </TalentButton>
      </TalentFooterContainer>
    </>
  );
};

export default EditPassword;
