import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useAuth } from "../context/auth-context";
import API from "../services/api";
import AuthService from "../services/auth.service";
import TalentHeader from "./core/TalentHeader";
import TalentScrollToTop from "./core/TalentScrollToTop";
import NotificationList from "./profile/NotificationList";
import Profile from "./profile/Profile";
import ProjectService from "../services/project.service";
import MessageComponent from "./profile/MessageComponent";
import Step2 from "./step-2/Step2";
import Step3 from "./step-3/Step3";
import Step4 from "./step-4/Step4";
import Step5 from "./step-5/Step5";
import AppHeader from "./core/layout/AppHeader";
import AppContainer from "./core/layout/AppContainer";
import AppFooter from "./core/layout/AppFooter";
import Dashboard from "./dashboard/Dashboard";
import TalentLoader from "./core/TalentLoader";
import { useAlert } from "react-alert";
import Document from "./document/Document";

const AuthenticatedApp = () => {
  // Load auth
  const auth = useAuth();

  // Load alert
  const alert = useAlert();

  // Current task state
  const [task, setTask] = useState(null);

  // Define messages & notification
  const [messages, setMessages] = useState(null);
  const [notifications, setNotifications] = useState(null);
  const [reloadComponent, setReloadComponent] = useState(false);

  const handleMessageChange = (newList) => {
    setMessages([...newList]);
  };

  const cleanNotifications = () => {
    setNotifications(null);
  };

  // Component mount
  useEffect(() => {
    const user = auth.user;

    // Get the current task
    API.get(`/talentdeveloper/project/${user.info.project}/tasks/current`, {
      headers: { DOLAPIKEY: AuthService.getToken(user) },
    })
      .then((response) => {
        setTask(response.data.success.current_task);
        // Get the notifications
        ProjectService.getNotifications(user)
          .then((res) => {
            if (res.length > 0) {
              // Set messages
              setMessages(res);

              // Set notifications (which are unread sent messages)
              let tmpNotifications = [];
              for (let i = 0; i < res.length; i++) {
                // Unread messages have a percent of 50
                if (
                  (res[i].code === "AC_MSG_SEND" ||
                    res[i].code === "AC_MSG_AUTO") &&
                  res[i].percent === "50"
                ) {
                  tmpNotifications.push(res[i]);
                }
              }
              setNotifications(tmpNotifications);
            }
          })
          .catch((err) => {
            console.error(err);
            alert.show(err.message, { type: "error" });
          });
      })
      .catch((err) => {
        console.error(err);
        alert.show(err.message, { type: "error" }); // TODO : improve error message
      });
  }, [auth, reloadComponent, alert]);

  // Method to display the write component in function of task
  const displayComponent = () => {
    if (task) {
      switch (task.step) {
        case "1":
          return <h1>Step 1</h1>;
        case "2":
          return <Step2 progress={task.progress} />;
        case "3":
          return <Step3 />;
        case "4":
          return (
            <Step4 reloadParent={() => setReloadComponent(!reloadComponent)} />
          );
        case "5":
          return (
            <Step5 reloadParent={() => setReloadComponent(!reloadComponent)} />
          );
        case "6":
          return <Dashboard />;
        default:
          return <h1>Not implemented</h1>;
      }
    } else {
      return <TalentLoader />;
    }
  };

  return (
    <div className="App">
      <Router>
        <TalentScrollToTop />
        <AppHeader>
          <TalentHeader notifications={notifications} />
        </AppHeader>

        <AppContainer>
          <Switch>
            <Route path="/notifications">
              <NotificationList notifications={notifications} />
            </Route>
            <Route path="/messages">
              <MessageComponent
                messages={messages}
                setMessages={handleMessageChange}
                cleanNotifications={cleanNotifications}
              />
            </Route>
            <Route path="/profile">
              <Profile />
            </Route>
            <Route path="/documents">
              <Document />
            </Route>
            <Route path="*">{displayComponent()}</Route>
          </Switch>
        </AppContainer>

        <AppFooter />
      </Router>
    </div>
  );
};

export default AuthenticatedApp;
