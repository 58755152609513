import React from "react";

const CGVContentES = () => {
  return (
    <>
      <h2>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: "Calibri,sans-serif" }}>
            <span style={{ fontSize: "27.0pt" }}>
              <span style={{ fontFamily: '"EB Garamond"' }}>
                <span style={{ color: "#0b1941" }}>
                  Términos y condiciones y acuerdo legal entre:
                </span>
              </span>
            </span>
          </span>
        </span>
      </h2>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <strong>
            <span style={{ fontSize: "10.5pt" }}>
              <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                <span style={{ color: "#0b1941" }}>La empresa:</span>
              </span>
            </span>
          </strong>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                &nbsp;Talent Developer con domicilio social en 36 rue du Mont
                Thabor 75001 Paris, número de registro 889 114 328 00012 R.C.S.
                Paris.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Talent Developer también se denominará T.D.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <strong>
            <span style={{ fontSize: "10.5pt" }}>
              <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                <span style={{ color: "#0b1941" }}>El cliente:</span>
              </span>
            </span>
          </strong>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                &nbsp;Persona física o jurídica, individual o colectiva que
                utiliza los servicios o asesoramiento de la empresa Talent
                Developer. El cliente puede ser: un centro de formación, un
                alumno, los representantes legales del alumno, etc.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <strong>
            <span style={{ fontSize: "10.5pt" }}>
              <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                <span style={{ color: "#0b1941" }}>
                  Los clientes serán denominados:
                </span>
              </span>
            </span>
          </strong>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                &nbsp;escuela, universidad, establecimiento educativo, escuela
                del aprendiz, Centro de Capacitación Alternativa (CFA, por sus
                siglas en francés) (o cualquier otro nombre que se le dé al
                establecimiento de formación), aprendiz, aprendiz y/o alumno.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                El alumno tiene 18 años o más y confirma que tiene la capacidad
                de ser responsable de sus actos.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Las condiciones generales establecidas por Talent Developer
                tienen como finalidad definir las obligaciones contractuales
                establecidas entre el cliente y Talent Developer, así como las
                condiciones de venta. Este documento estará disponible en todo
                momento en el sitio web de T.D.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Le pedimos que lea este documento detenidamente y luego confirme
                la lectura aceptando dicho contrato.
              </span>
            </span>
          </span>
        </span>
      </span>
      <h2>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: "Calibri,sans-serif" }}>
            <span style={{ fontSize: "27.0pt" }}>
              <span style={{ fontFamily: '"EB Garamond"' }}>
                <span style={{ color: "#0b1941" }}>
                  Términos y condiciones de Talent Developer:
                </span>
              </span>
            </span>
          </span>
        </span>
      </h2>
      <h3>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: "Calibri,sans-serif" }}>
            <span style={{ fontSize: "19.5pt" }}>
              <span style={{ fontFamily: '"EB Garamond"' }}>
                <span style={{ color: "#0b1941" }}>General:</span>
              </span>
            </span>
          </span>
        </span>
      </h3>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Las condiciones generales establecidas por Talent Developer
                tienen como finalidad definir las obligaciones contractuales
                establecidas entre el cliente y T.D., así como las condiciones
                de venta.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Las condiciones generales están disponibles a través de su área
                de clientes en el sitio web y también se pueden solicitar en
                cualquier momento.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Por favor lea estas condiciones generales cuidadosamente.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Todos los servicios, ofertas y/o reservas en T.D. están sujetos
                a estas condiciones generales. La realización de prácticas o un
                contrato de aprendizaje en una empresa, o cualquier otro
                servicio solicitado a T.D. implica la aceptación de las
                siguientes condiciones generales.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Todos los clientes de T.D. deben actuar de acuerdo con las
                reglas y la normativa establecidas por este acuerdo legal.
              </span>
            </span>
          </span>
        </span>
      </span>
      <h3>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: "Calibri,sans-serif" }}>
            <span style={{ fontSize: "19.5pt" }}>
              <span style={{ fontFamily: '"EB Garamond"' }}>
                <span style={{ color: "#0b1941" }}>Definiciones:</span>
              </span>
            </span>
          </span>
        </span>
      </h3>
      <h4>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: "Calibri,sans-serif" }}>
            <span style={{ fontSize: "16.5pt" }}>
              <span style={{ fontFamily: '"EB Garamond"' }}>
                <span style={{ color: "#0b1941" }}>
                  Organización de acogida o empresa de acogida:
                </span>
              </span>
            </span>
          </span>
        </span>
      </h4>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                La organización de acogida o la empresa de acogida designa a
                cualquier entidad jurídica en la que el cliente realice su
                período en la empresa. La organización de acogida puede ser una
                empresa, una organización gubernamental (OG), una organización
                no gubernamental (ONG) o cualquier organización con personalidad
                jurídica que le autorice a recibir al cliente.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Siempre serán establecimientos en relación con todas las áreas
                de especialización relacionadas con la hostelería, la
                restauración y/o las artes culinarias y las de todos los
                servicios (operativos, explotación, relaciones con el cliente,
                marketing, RRHH, finanzas, etc.).
              </span>
            </span>
          </span>
        </span>
      </span>
      <h4>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: "Calibri,sans-serif" }}>
            <span style={{ fontSize: "16.5pt" }}>
              <span style={{ fontFamily: '"EB Garamond"' }}>
                <span style={{ color: "#0b1941" }}>
                  Periodo en empresa: Prácticas o contrato de aprendizaje en
                  empresa:
                </span>
              </span>
            </span>
          </span>
        </span>
      </h4>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                El período en una empresa se refiere a una experiencia de
                aprendizaje práctica en la que participan la organización de
                acogida y el alumno. Las prácticas o contrato de aprendizaje en
                una empresa debe ser parte integral del programa de formación y
                un componente obligatorio de la progresión educativa del curso
                actual del alumno.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                El período en una empresa es de 2 a 6 meses para las prácticas,
                será anual para el contrato de aprendizaje, y se lleva a cabo en
                los ámbitos de la hostelería, la restauración, las artes
                culinarias o cualquier otro servicio relacionado con estos tres
                denominados: explotación, servicios de apoyo, servicios
                administrativos).
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                El objetivo principal del período en una empresa es permitir a
                los alumnos tener una experiencia en un contexto profesional,
                desarrollar sus habilidades y conocimientos, practicar las
                técnicas adquiridas en lecciones teóricas, comunicarse en
                francés e integrar y desarrollar una red de profesionales.
              </span>
            </span>
          </span>
        </span>
      </span>
      <h4>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: "Calibri,sans-serif" }}>
            <span style={{ fontSize: "16.5pt" }}>
              <span style={{ fontFamily: '"EB Garamond"' }}>
                <span style={{ color: "#0b1941" }}>Convenio de prácticas:</span>
              </span>
            </span>
          </span>
        </span>
      </h4>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                El convenio de prácticas se refiere al contrato tripartito
                establecido entre: la empresa de acogida (y no por T.D.), la
                escuela del aprendiz y el aprendiz.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Las prácticas no se rigen por un contrato de trabajo. El
                aprendiz no es un empleado de la empresa y no puede sustituirlo.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                En Francia, unas prácticas no implican remuneración, pero pueden
                proporcionar una bonificación. Esta bonificación se vuelve
                automática y obligatoria tan pronto como el aprendiz dure más de
                2 meses.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Las prácticas se rigen por los siguientes textos legales:
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Reforma de la condición de aprendices por ley n° 2014-788 de 10
                de julio de 2014.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>Textos de referencia:</span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                – &nbsp;Ley n° 2013-660 de 22 de julio de 2013 sobre la
                educación superior y la investigación
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                – &nbsp;Ley n°2014-788 de 10 julio de 2014
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                – &nbsp;Decreto n° 2014-1420 de 27 de noviembre de 2014 relativo
                a la supervisión de periodos de formación en el ámbito
                profesional y las prácticas.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                – Orden de 29 de diciembre de 2014 relativa a los convenios de
                prácticas en la educación superior.
              </span>
            </span>
          </span>
        </span>
      </span>
      <h4>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: "Calibri,sans-serif" }}>
            <span style={{ fontSize: "16.5pt" }}>
              <span style={{ fontFamily: '"EB Garamond"' }}>
                <span style={{ color: "#0b1941" }}>
                  Contrato de aprendizaje:
                </span>
              </span>
            </span>
          </span>
        </span>
      </h4>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                El contrato de aprendizaje se refiere al contrato de trabajo
                celebrado entre una empresa de acogida (y no por T.D.) y un
                empleado que también está en formación de aprendizaje. Se rige
                por el código laboral y por el convenio colectivo de la sucursal
                de la empresa de acogida. Está firmado por la empresa de acogida
                (y no por T.D.) y por el alumno; sujeto a la verificación de su
                centro de formación.
              </span>
            </span>
          </span>
        </span>
      </span>
      <h4>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: "Calibri,sans-serif" }}>
            <span style={{ fontSize: "16.5pt" }}>
              <span style={{ fontFamily: '"EB Garamond"' }}>
                <span style={{ color: "#0b1941" }}>Oferta de la empresa:</span>
              </span>
            </span>
          </span>
        </span>
      </h4>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                La oferta de la empresa se refiere a la descripción del
                puesto/ficha del puesto para el que una empresa de acogida está
                buscando candidatos. Incluye la descripción de las tareas y
                misiones del puesto, así como las competencias y las
                calificaciones requeridas por la empresa de acogida para ocupar
                el puesto.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                La oferta de la empresa se ofrecerá al alumno, según sus
                competencias, el nivel de francés solicitado por la empresa de
                acogida (que será al menos B1 según los estándares europeos), el
                progreso educativo de la formación que sigue el alumno y el
                proyecto profesional del alumno. El objetivo de T.D. es adaptar
                la oferta empresarial al proyecto y al perfil del alumno.
              </span>
            </span>
          </span>
        </span>
      </span>
      <h4>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: "Calibri,sans-serif" }}>
            <span style={{ fontSize: "16.5pt" }}>
              <span style={{ fontFamily: '"EB Garamond"' }}>
                <span style={{ color: "#0b1941" }}>
                  Descripción del Paquete de la empresa:
                </span>
              </span>
            </span>
          </span>
        </span>
      </h4>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                El Paquete de la empresa se refiere a los diversos servicios
                prestados por T.D. y/o sus socios. T.D. actúa como intermediario
                entre la empresa de acogida y el cliente.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                El paquete, también denominado Provisión de servicios, se divide
                en tres períodos distintos: antes, durante y después del período
                en la empresa.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                T.D. ofrece 3 paquetes disponibles en todos estos documentos
                comerciales:
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>Paquete Future Talent</span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Paquete Future Talent Premium
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Paquete Future Talent Premium Plus
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <strong>
            <em>
              <u>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    <span style={{ color: "#0b1941" }}>
                      «Paquete Future Talent
                    </span>
                  </span>
                </span>
              </u>
            </em>
          </strong>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <em>
            <span style={{ fontSize: "10.5pt" }}>
              <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                <span style={{ color: "#0b1941" }}>
                  Para quién: alumnos franceses en busca de prácticas y
                  aprendizaje.
                </span>
              </span>
            </span>
          </em>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <em>
            <u>
              <span style={{ fontSize: "10.5pt" }}>
                <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                  <span style={{ color: "#0b1941" }}>
                    Apoyarlo antes de sus prácticas o su contrato de aprendizaje
                  </span>
                </span>
              </span>
            </u>
          </em>
        </span>
      </span>
      <ol>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Primera entrevista, sin compromiso por su parte, para:
                      Comprender sus expectativas, su personalidad, sus
                      capacidades laborales y lo más importante, su proyecto
                      profesional. Al final de esta reunión, le proponemos un
                      plan de carrera para lograr mejor sus objetivos.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Para continuar en el proceso, debe: Completar su perfil en
                      línea, rellenar y enviar por correo los documentos
                      solicitados y pagar el depósito correspondiente al paquete
                      seleccionado.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Estudia al detalle su programa de formación para:
                      Comprender las competencias que desarrollará durante el
                      periodo en la empresa, luego contactamos con el
                      responsable de su formación, si es necesario.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Contenido educativo exclusivo: Acceso al catálogo de
                      cursos en línea para ayudarlo a crear su CV, su carta de
                      presentación y desarrollar su conocimiento en materia de
                      la cultura profesional.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Test de personalidad: A completar en su espacio personal
                      para ayudarnos a identificar la empresa y el perfil del
                      tutor que más se corresponde a usted.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Solicitud de empresa: – Estudio y corrección de su CV y
                      ​​su carta de presentación, para que se correspondan
                      perfectamente con las expectativas de los reclutadores –
                      Seleccionamos de nuestra red la empresa que más conviene a
                      las necesidades que identificamos para usted – Presentamos
                      su solicitud a la empresa destacando su saber estar y su
                      saber hacer – La empresa confirma su interés en su perfil,
                      le pedimos que pague el saldo del paquete – Le
                      proporcionamos los datos de contacto de la empresa y le
                      ofrecemos una entrevista con su reclutador – Le ayudamos a
                      prepararse para la entrevista proporcionándole una lista
                      de preguntas típicas, así como la filosofía y las
                      expectativas específicas de este establecimiento.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
      </ol>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <em>
            <u>
              <span style={{ fontSize: "10.5pt" }}>
                <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                  <span style={{ color: "#0b1941" }}>
                    Guiarle durante sus prácticas o contrato de aprendizaje:
                  </span>
                </span>
              </span>
            </u>
          </em>
        </span>
      </span>
      <ol>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Su futura red: Le invitamos a compartir un desayuno con
                      todos los alumnos que son nuevos en el mismo período que
                      usted. Esto le permitirá construir una red de futuros
                      talentos internacionales y franceses y vivir una
                      experiencia enriquecedora e inolvidable.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Visita a la empresa: Organizamos una visita a su empresa
                      de acogida, con su tutor para hacer un balance de su
                      progreso en la empresa.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Le apoyamos durante todo su periodo en la empresa: Lo
                      contactamos al principio, en el medio y al final para
                      hacer un balance y luego quedamos a su entera disposición
                      durante todo su periodo en la empresa.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
      </ol>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <em>
            <u>
              <span style={{ fontSize: "10.5pt" }}>
                <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                  <span style={{ color: "#0b1941" }}>
                    Desarrollar después de sus prácticas o contrato de
                    aprendizaje:
                  </span>
                </span>
              </span>
            </u>
          </em>
        </span>
      </span>
      <ol>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <u>
                    <span style={{ fontSize: "10.5pt" }}>
                      <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                        La reunión final de la formación en la empresa
                      </span>
                    </span>
                  </u>
                </em>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      : Organizada en nuestras instalaciones para dar cuenta de
                      su experiencia; lo ayudamos a extraer las competencias
                      adquiridas y valorarlas para su futuro profesional, luego
                      lo guiamos en el desarrollo de su carrera y sus futuras
                      metas profesionales.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      La plataforma TALENT DEVELOPER: Mantiene acceso
                      privilegiado a su perfil personal y a la plataforma TALENT
                      DEVELOPER para acceder a nuestras ofertas de trabajo
                      exclusivas.»
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
      </ol>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <strong>
            <em>
              <u>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    <span style={{ color: "#0b1941" }}>
                      «Paquete Future Talent Premium
                    </span>
                  </span>
                </span>
              </u>
            </em>
          </strong>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <em>
            <span style={{ fontSize: "10.5pt" }}>
              <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                <span style={{ color: "#0b1941" }}>¿Para quién?</span>
              </span>
            </span>
          </em>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <em>
            <span style={{ fontSize: "10.5pt" }}>
              <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                <span style={{ color: "#0b1941" }}>
                  Alumnos extranjeros con un nivel de francés superior a B1
                </span>
              </span>
            </span>
          </em>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <em>
            <u>
              <span style={{ fontSize: "10.5pt" }}>
                <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                  <span style={{ color: "#0b1941" }}>
                    Apoyarlo antes de sus prácticas o su contrato de aprendizaje
                  </span>
                </span>
              </span>
            </u>
          </em>
        </span>
      </span>
      <ol>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Primera entrevista, sin compromiso por su parte, para:
                      Comprender sus expectativas, su personalidad, sus
                      capacidades laborales y lo más importante, su proyecto
                      profesional. Al final de esta reunión, le ofrecemos un
                      plan de carrera para lograr mejor sus objetivos.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Para continuar en el proceso, debe: Completar su perfil en
                      línea, rellenar y enviar por correo los documentos
                      solicitados y pagar el depósito correspondiente al paquete
                      seleccionado.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Estudia al detalle su programa de formación para:
                      Comprender las competencias que desarrollará durante el
                      periodo en la empresa, luego contactamos con el
                      responsable de su formación, si es necesario.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Contenido educativo exclusivo: Acceso al catálogo de
                      cursos en línea para ayudarlo a redactar su CV, su carta
                      de presentación y desarrollar su conocimiento en materia
                      de la cultura profesional, seguido de un folleto
                      «Bienvenido a París» para preparar mejor su estadía
                      (buenas direcciones, informaciones prácticas…).
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Test de personalidad: A completar en su espacio personal
                      para ayudarnos a identificar la empresa y el perfil del
                      tutor que más se corresponde a usted.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Solicitud de empresa: – Estudio y corrección de su CV y
                      ​​su carta de presentación, para que se correspondan
                      perfectamente con las expectativas de los reclutadores –
                      Seleccionamos de nuestra red la empresa que más se adapte
                      a las necesidades que identificamos para usted –
                      Presentamos su solicitud a la empresa destacando su saber
                      estar y su saber hacer – La empresa confirma su interés en
                      su perfil, le pedimos que pague el saldo del paquete – Le
                      proporcionamos los datos de contacto de la empresa y le
                      ofrecemos una entrevista con su reclutador – Le ayudamos a
                      prepararse para la entrevista proporcionándole una lista
                      de preguntas típicas, así como la filosofía y las
                      expectativas específicas de este establecimiento.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
      </ol>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <em>
            <u>
              <span style={{ fontSize: "10.5pt" }}>
                <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                  <span style={{ color: "#0b1941" }}>
                    Guiarle durante sus prácticas o contrato de aprendizaje:
                  </span>
                </span>
              </span>
            </u>
          </em>
        </span>
      </span>
      <ol>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Su futura red: Le invitamos a compartir un desayuno con
                      todos los alumnos nuevos en el mismo período que usted.
                      Esto le permitirá construir una red de futuros talentos
                      internacionales y franceses y vivir una experiencia
                      enriquecedora e inolvidable.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Visita a la empresa: Organizamos dos visitas a su empresa
                      de acogida, con su tutor, para hacer un balance de su
                      progreso en la empresa.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Apoyo durante su período en la empresa: Lo contactamos al
                      principio, en el medio y al final de su período en la
                      empresa para hacer un balance, luego quedamos a su entera
                      disposición y un miembro del equipo de TALENT DEVELOPER
                      estará siempre a su disposición en caso de emergencia.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
      </ol>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <em>
            <u>
              <span style={{ fontSize: "10.5pt" }}>
                <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                  <span style={{ color: "#0b1941" }}>
                    Desarrollar después de sus prácticas o contrato de
                    aprendizaje
                  </span>
                </span>
              </span>
            </u>
          </em>
        </span>
      </span>
      <ol>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <u>
                    <span style={{ fontSize: "10.5pt" }}>
                      <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                        La reunión final de formación en la empresa
                      </span>
                    </span>
                  </u>
                </em>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      : Organizada en nuestras instalaciones, para dar cuenta de
                      su experiencia, le ayudamos a extraer las competencias
                      adquiridas y valorarlas para su futuro profesional, le
                      orientamos en el desarrollo de su carrera y sus futuras
                      metas profesionales, luego le daremos un certificado de
                      prácticas que acredite su experiencia en París.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      La plataforma TALENT DEVELOPER: Mantiene acceso
                      privilegiado a su perfil personal y a la plataforma TALENT
                      DEVELOPER para acceder a nuestras ofertas de trabajo
                      exclusivas.»
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
      </ol>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <strong>
            <em>
              <u>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    <span style={{ color: "#0b1941" }}>
                      «Paquete Future Talent Premium Plus
                    </span>
                  </span>
                </span>
              </u>
            </em>
          </strong>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <em>
            <span style={{ fontSize: "10.5pt" }}>
              <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                <span style={{ color: "#0b1941" }}>
                  ¿Para quién? Alumnos extranjeros con un nivel de francés
                  inferior al nivel B1
                </span>
              </span>
            </span>
          </em>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <em>
            <span style={{ fontSize: "10.5pt" }}>
              <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                <span style={{ color: "#0b1941" }}>
                  Si no está demasiado familiarizado con el idioma de Moliere,
                  ¡nuestro paquete Premium Plus ha sido diseñado para usted!
                </span>
              </span>
            </span>
          </em>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <em>
            <u>
              <span style={{ fontSize: "10.5pt" }}>
                <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                  <span style={{ color: "#0b1941" }}>
                    Apoyarlo antes de sus prácticas o su contrato de aprendizaje
                  </span>
                </span>
              </span>
            </u>
          </em>
        </span>
      </span>
      <ol>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Primera entrevista, sin compromiso por su parte, para:
                      Comprender sus expectativas, su personalidad, sus
                      capacidades laborales y lo más importante, su proyecto
                      profesional. Al final de esta reunión, le proponemos un
                      plan de carrera para lograr mejor sus objetivos.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Para continuar en el proceso, debe: Completar su perfil en
                      línea, rellenar y enviar por correo los documentos
                      solicitados y pagar el depósito correspondiente al paquete
                      seleccionado.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Estudia&nbsp;al detalle su programa de formación para:
                      Comprender las competencias que desarrollará durante el
                      periodo en la empresa, luego contactamos con el
                      responsable de su formación, si es necesario.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Contenido educativo exclusivo: acceso al catálogo de
                      cursos en línea para ayudarlo a redactar su CV, su carta
                      de presentación y desarrollar su conocimiento en materia
                      de la cultura profesional, seguido de un folleto
                      «Bienvenido a París» para preparar mejor su estadía
                      (buenas direcciones, informaciones prácticas…).
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Test de personalidad: A completar en su espacio personal
                      para ayudarnos a identificar la empresa y el perfil de
                      tutor que más se corresponde a usted.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Solicitud de empresa: – Estudio y corrección de su CV y
                      ​​su carta de presentación, para que se correspondan
                      perfectamente con las expectativas de los reclutadores –
                      Seleccionamos de nuestra red la empresa que más se adapte
                      a las necesidades que identificamos para usted –
                      Presentamos su solicitud a la empresa destacando su saber
                      estar y su saber hacer – La empresa confirma su interés en
                      su perfil, le pedimos que pague el saldo del paquete – Le
                      proporcionamos los datos de contacto de la empresa y le
                      ofrecemos una entrevista con su reclutador – Le ayudamos a
                      prepararse para la entrevista proporcionándole una lista
                      de preguntas típicas, así como la filosofía y las
                      expectativas específicas de este establecimiento.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
      </ol>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <em>
            <u>
              <span style={{ fontSize: "10.5pt" }}>
                <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                  <span style={{ color: "#0b1941" }}>
                    Guiarle durante sus prácticas o contrato de aprendizaje:
                  </span>
                </span>
              </span>
            </u>
          </em>
        </span>
      </span>
      <ol>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Su futura red: Le invitamos a compartir un desayuno con
                      todos los alumnos nuevos en el mismo período que usted.
                      Esto le permitirá construir una red de futuros talentos
                      internacionales y franceses y vivir una experiencia
                      enriquecedora e inolvidable.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Cursos de francés: Seguirá dos semanas muy intensivas (30
                      horas semanales) de cursos FLE (francés como lengua
                      extranjera) para alcanzar el nivel de comprensión y
                      comunicación necesarios para la correcta ejecución de sus
                      prácticas.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Visita a la empresa: Organizamos dos visitas a su empresa
                      de acogida, con su tutor, para hacer un balance de su
                      progreso en la empresa.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Apoyo durante su período en la empresa: Lo contactamos al
                      principio, en el medio y al final de su período en la
                      empresa para hacer un balance, luego quedamos a su entera
                      disposición y un miembro del equipo de TALENT DEVELOPER,
                      está siempre a su disposición en caso de emergencia.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
      </ol>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <em>
            <u>
              <span style={{ fontSize: "10.5pt" }}>
                <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                  <span style={{ color: "#0b1941" }}>
                    Desarrollar después de sus prácticas o contrato de
                    aprendizaje
                  </span>
                </span>
              </span>
            </u>
          </em>
        </span>
      </span>
      <ol>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <u>
                    <span style={{ fontSize: "10.5pt" }}>
                      <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                        La reunión final de formación en la empresa
                      </span>
                    </span>
                  </u>
                </em>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      : Organizada en nuestras instalaciones, para dar cuenta de
                      su experiencia, le ayudamos a extraer las competencias
                      adquiridas y valorarlas para su futuro profesional, le
                      orientamos en el desarrollo de su carrera y sus futuras
                      metas profesionales, luego le daremos un certificado de
                      prácticas que acredite su experiencia en París.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      La plataforma TALENT DEVELOPER: Mantiene acceso
                      privilegiado a su perfil personal y a la plataforma TALENT
                      DEVELOPER para acceder a nuestras ofertas de trabajo
                      exclusivas.»
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
      </ol>
      <h4>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: "Calibri,sans-serif" }}>
            <u>
              <span style={{ fontSize: "13.5pt" }}>
                <span style={{ fontFamily: '"EB Garamond"' }}>
                  <span style={{ color: "#0b1941" }}>Escuela de idiomas:</span>
                </span>
              </span>
            </u>
          </span>
        </span>
      </h4>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Las escuelas de idiomas se refieren a cualquier escuela de
                idiomas acreditada que trabaje con T.D. para proporcionar
                lecciones de idiomas al cliente. T.D. ha seleccionado una
                escuela de idiomas que imparte 30 horas de clase/semana, durante
                dos semanas, y ha impuesto unas especificaciones muy precisas,
                para que el alumno pueda beneficiarse de una formación intensiva
                y relevante durante estas dos semanas.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                T.D. requiere un nivel de francés B1 o equivalente justificado
                por un certificado. El nivel B1 permite al alumno comunicarse
                fácilmente con su interlocutor. Es fundamental que el alumno se
                comunique bien con su entorno, con su tutor y con los equipos de
                la institución de acogida. La comunicación permitirá al alumno
                comprender plenamente las expectativas del tutor y a desarrollar
                el máximo de competencias profesionales durante este período en
                la empresa. También se beneficiará de la red profesional de una
                manera mucho más activa al comunicarse en el mismo idioma.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Si el nivel de francés del alumno no es suficiente para
                comunicarse con fluidez, pero tiene una base de francés, los
                cursos FLE son obligatorios. Se imponen al alumno dos semanas de
                curso intensivo a su llegada a París y antes de iniciar su etapa
                en una empresa.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                El importe del curso está incluido en el servicio Paquete Future
                Talent Premium Plus.
              </span>
            </span>
          </span>
        </span>
      </span>
      <h4>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: "Calibri,sans-serif" }}>
            <u>
              <span style={{ fontSize: "13.5pt" }}>
                <span style={{ fontFamily: '"EB Garamond"' }}>
                  <span style={{ color: "#0b1941" }}>
                    Socio o Empresa de servicios:
                  </span>
                </span>
              </span>
            </u>
          </span>
        </span>
      </h4>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                El Socio se refiere a cualquier empresa, organización o persona
                física o jurídica empleada o utilizada por T.D. para sus
                servicios. El objetivo es ayudar al alumno en su instalación e
                integración en Francia y facilitar su vida diaria fuera de la
                empresa de acogida. T.D. no se hace responsable de estos
                servicios, condiciones o tarifas. Los servicios que se ofrecen
                son: agencia de alojamiento, traslado del aeropuerto,
                suscripción de póliza de seguro y empresa de servicio de
                hogar/limpieza, tarjeta SIM, suscripción a internet, tarjeta de
                transporte, clases de cocina, clases de pastelería, etc.
              </span>
            </span>
          </span>
        </span>
      </span>
      <h4>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: "Calibri,sans-serif" }}>
            <span style={{ fontSize: "16.5pt" }}>
              <span style={{ fontFamily: '"EB Garamond"' }}>
                <span style={{ color: "#0b1941" }}>Tutor:</span>
              </span>
            </span>
          </span>
        </span>
      </h4>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                El tutor designa a cualquier persona física designada dentro de
                la empresa de acogida que seguirá y supervisará al alumno
                durante su periodo en la empresa. El tutor asignará tareas y
                misiones al alumno y le brindará apoyo, le explicará las
                técnicas y le dará retroalimentación periódica sobre su trabajo
                para que pueda desarrollar y adquirir competencias.
              </span>
            </span>
          </span>
        </span>
      </span>
      <h3>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: "Calibri,sans-serif" }}>
            <span style={{ fontSize: "19.5pt" }}>
              <span style={{ fontFamily: '"EB Garamond"' }}>
                <span style={{ color: "#0b1941" }}>Aceptación:</span>
              </span>
            </span>
          </span>
        </span>
      </h3>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                El cliente reconoce haber leído, comprendido y aceptado los
                términos y condiciones.
              </span>
            </span>
          </span>
        </span>
      </span>
      <h4>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: "Calibri,sans-serif" }}>
            <span style={{ fontSize: "16.5pt" }}>
              <span style={{ fontFamily: '"EB Garamond"' }}>
                <span style={{ color: "#0b1941" }}>Artículos</span>
              </span>
            </span>
          </span>
        </span>
      </h4>
      <h5>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: "Calibri,sans-serif" }}>
            <u>
              <span style={{ fontSize: "13.5pt" }}>
                <span style={{ fontFamily: '"EB Garamond"' }}>
                  <span style={{ color: "#0b1941" }}>
                    1. Registro del cliente
                  </span>
                </span>
              </span>
            </u>
          </span>
        </span>
      </h5>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                1.1. Inscripción gratuita, sin obligación de compra, a través
                del sitio web de T.D. o de la escuela.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                1.2. El alumno debe aportar su CV y ​​una carta de presentación
                en formato editable (Word) en francés o inglés y seguir los
                pasos indicados en su área de cliente en el sitio web.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                1.3. El nivel de francés del cliente debe ser suficiente para
                comunicarse durante su periodo en una empresa en Francia. El
                cliente debe proporcionar un documento justificante de menos de
                2 años que acredite su nivel de francés (DELF B1 o DALF, TCF,
                TFI o TEF nivel B1). En caso contrario, el cliente deberá elegir
                la opción que incluye los cursos de francés obligatorios para
                alcanzar al nivel B1. T.D. no podrá garantizar las prácticas si
                el cliente no alcanza el nivel de francés requerido, incluso si
                el cliente ha optado por los cursos FLE incluidos.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                1.4. El cliente se compromete a facilitar toda la información
                necesaria para que T.D. lleve a cabo su misión y a comunicar
                cualquier información que pueda afectar al buen desarrollo de
                las prácticas o periodo de aprendizaje en una empresa, la
                estancia o el proceso de colocación.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                1.5. Es posible que se requiera un certificado de seguro médico
                y un certificado de seguro de responsabilidad civil, una copia
                del pasaporte, visa, permiso de residencia y/o etiqueta OFFI
                válida, así como cualquier otro documento oficial. Los alumnos
                de fuera de la comunidad europea deben estar en posesión de una
                visa VLS-TS que les permita realizar sus prácticas en Francia. A
                su llegada al territorio francés y en función del tipo de visa
                que posean, estos alumnos deben registrarse en la Prefectura de
                Policía para validar su visa siguiendo el procedimiento OFII.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Cada alumno es responsable de este proceso legal y oficial y
                debe informarse y cumplir con las obligaciones previstas por su
                embajada y por las entidades oficiales francesas interesadas.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Un alumno que no cumpla con las obligaciones legales
                relacionadas con la visa no podrá comenzar su período en una
                empresa en Francia.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Un alumno que no se encargue de validar su visa dentro de los 3
                meses posteriores a su llegada será considerado en una situación
                ilegal y estará sujeto a una multa.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                1.6. T.D. se reserva el derecho de rechazar solicitudes si: – el
                cliente no proporciona la información solicitada en un plazo
                aceptable – el cliente ha sido objeto de una condena penal por
                un delito grave o incompatible con su entrada en Francia o su
                período en la empresa en los establecimientos de acogida
                ofrecidos o por cualquier otra razón que haga que el cliente no
                sea apto para realizar el período en la empresa – el cliente no
                habla francés lo suficiente para poder comunicarse, incluso si
                el cliente ha optado por la opción que incluye los cursos de
                FLE.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                1.7. T.D. se reserva el derecho de rechazar la candidatura de
                cualquier persona considerada no apta para el programa de
                prácticas en una empresa, a su sola discreción.
              </span>
            </span>
          </span>
        </span>
      </span>
      <h5>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: "Calibri,sans-serif" }}>
            <u>
              <span style={{ fontSize: "13.5pt" }}>
                <span style={{ fontFamily: '"EB Garamond"' }}>
                  <span style={{ color: "#0b1941" }}>
                    2. Compromisos y responsabilidades de Talent Developer
                    (T.D.)
                  </span>
                </span>
              </span>
            </u>
          </span>
        </span>
      </h5>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                2.1. T.D. actúa como intermediario entre las empresas de
                acogida, los socios y el cliente.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                2.2. T.D. se compromete a poner al cliente en contacto con las
                empresas de acogida, en función de sus expectativas, su perfil,
                su área de especialización, el programa de estudios seguido, sus
                competencias y su proyecto profesional pero también, en función
                de los requisitos, perfil y expectativas de las empresas de
                acogida.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                2.3. T.D. se compromete a hacer todo lo posible para ayudar al
                cliente a obtener una empresa de acogida, mediante la
                realización de acciones como: – formación sobre la realización
                del CV, la carta de presentación y las expectativas en empresas
                en Francia, -la revisión y corrección del CV y ​​la carta de
                presentación del cliente, – el envío de la solicitud del cliente
                a las empresas de acogida, – la programación de entrevistas con
                las empresas de acogida interesadas en el perfil del cliente y
                dispuestos a concederle una entrevista, siempre que el cliente
                haya realizado el primer depósito de 30%, – la revisión y
                corrección de cualquier trabajo específico requerido por las
                empresas de acogida antes de dar una entrevista al cliente o en
                sustitución de la entrevista, – cuando sea apropiado, brindar
                asesoramiento sobre cómo prepararse para una entrevista
                profesional con el fin de maximizar las posibilidades del
                cliente de obtener las prácticas o un contrato de aprendizaje
                (sesión de orientación y/o folleto de consejos).
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                2.4. T.D. asiste al cliente en la firma de su convenio de
                prácticas.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                2.5. Una vez que el alumno comienza en la empresa de acogida,
                T.D. se compromete a realizar un seguimiento del alumno durante
                todo el período en la empresa, respondiendo a sus dudas y
                aconsejándole lo mejor posible.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                2.6. T.D. no se hace responsable del comportamiento de los
                empleados o de las condiciones de trabajo en la empresa de
                acogida. No obstante, T.D. se compromete a ayudar al cliente a
                resolver, en la medida de sus posibilidades, los posibles
                problemas de comunicación que puedan surgir con la empresa de
                acogida y, en particular, en relación con: las misiones y/o
                condiciones de trabajo (Vea Artículo 7).
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                2.7. T.D. no se hace responsable de los servicios prestados por
                sus socios. Sin embargo, T.D. se compromete a ayudar al cliente
                a resolver cualquier problema potencial que pueda surgir con sus
                socios, en la medida de sus posibilidades.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                2.8. T.D. se compromete a estar disponible para cualquier
                solicitud de información relevante por parte del cliente.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                2.9. T.D. no puede garantizar ninguna colocación en una empresa
                dentro de un período definido. Los factores externos (como el
                interés o la disponibilidad de las empresas de acogida, el
                comportamiento y el discurso del cliente durante las
                entrevistas, la disponibilidad de alojamiento, la obtención y
                aprobación de visas, etc.) impiden que T.D. garantice una fecha
                o un plazo específico para la colocación. T.D. aconseja a sus
                clientes que hagan sus arreglos para el tiempo concedido para
                esta búsqueda y que anticipen los pasos para asegurar todas las
                posibilidades de éxito.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                2.10. T.D. no puede garantizar la bonificación de las prácticas
                por parte de las empresas de acogida, pero se compromete a
                ayudar al cliente a resolver cualquier problema potencial de
                comunicación con las empresas de acogida sobre este tema.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                2.11. T.D. se reserva el derecho de cesar toda cooperación con
                el cliente en caso de que su perfil, comportamiento,
                expectativas o proyecto no se correspondan con las expectativas
                y requisitos de las empresas de acogida.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                2.12. Una vez que el cliente ha aceptado la oferta de la empresa
                y abonado el saldo del Paquete correspondiente, T.D. se
                compromete a no proponer esta oferta a otro cliente.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                2.13. T.D. se compromete a no divulgar la información personal
                del cliente a instituciones comerciales ni a ninguna otra
                persona o entidad sin su consentimiento.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                2.14. T.D. no es legalmente responsable del cliente, ni en las
                instalaciones de la empresa de acogida, ni fuera de las
                instalaciones de la empresa de acogida.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                2.15. T.D. no se hace responsable de ningún accidente, lesión,
                robo, daño, enfermedad, pérdida, gasto o costo incurrido o
                riesgo que pueda surgir durante las prácticas o contrato de
                aprendizaje en una empresa o en la residencia de un tercero,
                incluyendo, pero sin limitarse a las instalaciones de la empresa
                de acogida y al alojamiento que ocupe el alumno.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                2.16. La responsabilidad máxima de T.D. se limita al monto
                pagado por el cliente por el servicio brindado por T.D. T.D. no
                es responsable de ningún costo adicional en que pueda incurrir
                el cliente con organizaciones externas antes, durante o después
                de sus prácticas o su contrato de aprendizaje dentro de la
                empresa de acogida o fuera de ella.
              </span>
            </span>
          </span>
        </span>
      </span>
      <h5>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: "Calibri,sans-serif" }}>
            <u>
              <span style={{ fontSize: "13.5pt" }}>
                <span style={{ fontFamily: '"EB Garamond"' }}>
                  <span style={{ color: "#0b1941" }}>
                    3. Compromiso, responsabilidad y comportamiento del cliente
                  </span>
                </span>
              </span>
            </u>
          </span>
        </span>
      </h5>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                3.1. Proceso de colocación en las prácticas o en el contrato de
                aprendizaje:
              </span>
            </span>
          </span>
        </span>
      </span>
      <ul>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.1.1. El cliente comprende y acepta que al contratar a T.D.
                    para la búsqueda de las prácticas o un contrato de
                    aprendizaje y cualquier otro servicio relacionado, el
                    cliente se compromete a pagar el Paquete al que se haya
                    suscrito respetando los plazos previstos en el contrato por
                    el tiempo dedicado a realizar la tarea patrocinada en T.D.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.1.2. El cliente se compromete a seguir el proceso paso a
                    paso según lo descrito por T.D.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.1.3. El cliente se compromete a pagar cada factura
                    recibida de T.D. a tiempo y dentro de los plazos definidos
                    conjuntamente, por cada servicio prestado.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.1.4. El cliente reconoce que cualquier plazo estimado
                    proporcionado por T.D. para su colocación sigue siendo
                    indicativo.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.1.5. El cliente se compromete a informar a T.D. de las
                    fechas de inicio y finalización de las prácticas o un
                    contrato de aprendizaje previstas por su formación al
                    momento de registrarse y/o durante la primera entrevista con
                    una persona del equipo de T.D. En caso de un cambio
                    posterior de estas fechas, T.D. no puede garantizar la
                    oferta encontrada originalmente y no será responsable de
                    ningún rechazo de las nuevas fechas por parte de la empresa
                    de acogida. T.D. no hará ningún reembolso.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.1.6. El cliente se compromete a no pasar por alto el
                    proceso de reclutamiento de T.D. para obtener las prácticas
                    o un contrato de aprendizaje directamente de las empresas de
                    acogida en la red T.D. en un plazo no mayor a 5 años. Este
                    plazo se aplica tanto al cliente escolar como a los alumnos.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.1.7. El cliente se compromete a no contactar directamente
                    a la empresa de acogida sin el consentimiento de T.D. antes
                    de haber pagado el monto total adeudado a T.D. por sus
                    servicios.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.1.8. T.D. entiende que el cliente puede tener que
                    compartir cierta información relacionada con sus prácticas,
                    su contrato de aprendizaje o su alojamiento con terceros
                    (por ejemplo: el centro de formación del cliente y/o sus
                    padres). Sin embargo, el cliente no podrá en ningún caso
                    divulgar los datos de contacto de las empresas de acogida o
                    socios a un tercero, para fines distintos a los necesarios
                    para el buen funcionamiento de las prácticas o el contrato
                    de aprendizaje (por ejemplo: amigos, compañeros de clase,
                    publicación en redes sociales) y esto, ya sea antes, durante
                    o después de las prácticas, su período de aprendizaje o la
                    estancia en el alojamiento. En caso contrario, T.D. se
                    reserva el derecho a cancelar el contrato que lo vincula con
                    el cliente y a informar a sus socios para invitarlos a hacer
                    lo mismo por los servicios patrocinados por el cliente con
                    ellos (en particular para la vivienda.). T.D. se reserva
                    además el derecho de emprender cualquier acción legal
                    necesaria para proteger los intereses de la empresa.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.1.9. Una vez que el cliente acepta las prácticas, éste
                    acepta enviar su convenio de prácticas en francés o en
                    inglés por correo electrónico a T.D. dentro de los 2 días
                    posteriores a su aceptación por parte de la empresa de
                    acogida, para que T.D. pueda asistir a la firma. De lo
                    contrario, T.D. no se hace responsable en caso de
                    cancelación de las prácticas por parte de la empresa de
                    acogida o la no firma del convenio de prácticas por parte de
                    la empresa de acogida. Si el alumno o la escuela no tienen
                    un convenio de prácticas, T.D. puede ofrecerles un convenio
                    estándar en francés/inglés.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.1.10. Si el cliente (o su escuela o universidad o
                    cualquier otro tercero en representación de éste) envía el
                    convenio de prácticas directamente a la empresa de acogida
                    (por correo electrónico o por correo), T.D. no se hace
                    responsable de la no firma del convenio de prácticas o la
                    cancelación de las prácticas por parte de la empresa de
                    acogida.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.1.11. El cliente comprende y acepta que pueden producirse
                    determinados retrasos cuando la empresa de acogida devuelva
                    el convenio de prácticas. La empresa de acogida puede tardar
                    un tiempo en leer el convenio, hacer que un abogado lo
                    vuelva a leer, que el director lo acepte o para confirmar
                    las fechas definitivas de las prácticas, etc. T.D. no se
                    hace responsable de estos retrasos.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.1.12. Una vez firmado el convenio de prácticas por parte
                    de la empresa de acogida, es responsabilidad del cliente
                    hacer firmar su convenio de prácticas con su universidad,
                    colegio u organización educativa y colocar una copia
                    escaneada en su perfil personal. T.D. no se hace responsable
                    de ninguna manera por cualquier incumplimiento por parte del
                    cliente o de su escuela en relación con la no firma del
                    convenio de prácticas.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                3.2&nbsp;<em>Recursos económicos del cliente:</em>
              </span>
            </span>
          </span>
        </span>
      </span>
      <ul>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.2.1. Es responsabilidad del cliente contar con los
                    recursos económicos necesarios para pagar los servicios de
                    T.D. El cliente debe asegurarse de tener suficientes
                    recursos económicos antes de iniciar el proceso con T.D.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.2.2. Es responsabilidad del cliente estar consciente de
                    que viajar al exterior tiene ciertos costos y que es
                    necesario contar con los recursos económicos para vivir en
                    el país. Es aconsejable conocer con antelación los recursos
                    económicos necesarios para vivir en París.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.2.3. El cliente acepta que T.D. no se hace responsable de
                    ningún inconveniente o pérdida de ingresos debido al proceso
                    de colocación.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.2.4. Es responsabilidad del cliente asegurarse de que se
                    obtenga cualquier bonificación mencionada en la oferta de la
                    empresa, durante la entrevista y/o al firmar el convenio de
                    prácticas o al firmar el contrato de aprendizaje, con la
                    empresa de acogida y no con T.D.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.2.5. En el caso de solicitar una visa, y dependiendo de
                    las diferentes nacionalidades, las autoridades competentes
                    podrán solicitar al alumno un certificado de recursos
                    suficientes.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                3.3. Seguro y comportamiento del cliente:
              </span>
            </span>
          </span>
        </span>
      </span>
      <ul>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.3.1. Es responsabilidad del cliente contratar un seguro de
                    responsabilidad civil, un seguro médico, un seguro de viaje
                    y/o un seguro a todo riesgo. También es responsabilidad del
                    cliente contratar un seguro de hogar al alquilar un
                    apartamento con uno de nuestros socios.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.3.2. El cliente es responsable de su propia seguridad
                    durante el viaje y durante su estancia.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.3.3. El cliente se compromete a comportarse con respeto
                    durante su periodo en la empresa participando activamente en
                    el desarrollo de la empresa adoptando una actitud
                    profesional, eficiente y productiva y representando los
                    mejores intereses de la empresa de acogida realizando las
                    misiones definidas en la oferta.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.3.4. El cliente debe cumplir con el código de conducta de
                    la empresa de acogida durante toda la duración de sus
                    prácticas o contrato de aprendizaje. El alumno está sujeto a
                    la disciplina y a las cláusulas de la normativa interna
                    aplicable en la empresa de acogida y que le sean puestas en
                    conocimiento antes del inicio de las prácticas o del
                    contrato de aprendizaje, en particular en lo que se refiere
                    a horarios y normas de seguridad y salud vigentes en la
                    organización de acogida. Además, se requiere que el cliente
                    respete la cultura corporativa y sus políticas internas, las
                    cuales pueden quedar registradas en un manual de personal o
                    estar comunicadas verbalmente.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.3.5 El alumno se compromete a llevar a cabo su misión y a
                    estar disponible para las tareas que le sean encomendadas, a
                    respetar las reglas de la empresa, así como sus códigos, su
                    cultura y los requisitos de confidencialidad establecidos
                    por la empresa. El alumno se compromete a no copiar
                    documentos internos pertenecientes a la empresa sin haber
                    obtenido autorización y a no difundir la información
                    recogida por él, salvo por acuerdo expreso de la empresa.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.3.6. Es responsabilidad del alumno respetar el horario
                    transmitido por la empresa de acogida y seguirlo. Este se
                    puede actualizar periódicamente y modificar según las
                    necesidades de la empresa. El alumno está preparado para ser
                    flexible y consciente de las exigencias de la profesión. La
                    empresa de acogida puede pedirle que cambie su horario o que
                    se adapte a las necesidades trabajando unas horas extra
                    según sea necesario y con plazos de información breves.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.3.7. El alumno es plenamente responsable de sus
                    actuaciones durante su periodo en la empresa y/o estancia en
                    el alojamiento. Él es responsable de cualquier pérdida o
                    daño resultante de sus acciones. El alumno se compromete a
                    compensar a T.D., la empresa de acogida, los propietarios,
                    los inquilinos principales, las agencias de vivienda, otros
                    prestadores de servicios (taxis, servicios de limpieza,
                    etc.) o cualquier otro tercero que pueda sufrir pérdida o
                    daño debido a la conducta del alumno. El alumno deberá estar
                    asegurado por cualquier daño o perjuicio que pudiera
                    ocasionarse mientras está presente en la empresa o durante
                    su estancia.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.3.8. El alumno se compromete a cumplir con la ley francesa
                    y no poner en peligro o causar daño a sí mismo y/o poner en
                    peligro o causar daño a un tercero. Si el alumno no actúa de
                    acuerdo con los hechos mencionados anteriormente, se le
                    considerará completamente responsable de las consecuencias
                    de sus acciones.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>3.4. Documentos:</span>
            </span>
          </span>
        </span>
      </span>
      <ul>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.4.1. Es responsabilidad del alumno comprar su boleto para
                    viajar y obtener todos los documentos necesarios para viajar
                    al extranjero (visa y/o permiso de residencia y seguro) y
                    estar disponible en la fecha de inicio de las prácticas o un
                    contrato de aprendizaje. T.D. no se hace responsable si el
                    cliente no obtiene su visa o cualquier otro documento
                    necesario para viajar/residir en el extranjero y no se
                    reembolsarán los costos de T.D.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <h5>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: "Calibri,sans-serif" }}>
            <u>
              <span style={{ fontSize: "13.5pt" }}>
                <span style={{ fontFamily: '"EB Garamond"' }}>
                  <span style={{ color: "#0b1941" }}>
                    4. La empresa de acogida
                  </span>
                </span>
              </span>
            </u>
          </span>
        </span>
      </h5>
      <ul>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    4.1. La empresa de acogida es la única que toma las
                    decisiones con respecto a la selección de candidatos para
                    las entrevistas. Para ser elegible para las prácticas o
                    contrato de aprendizaje, el alumno debe haber cumplido todos
                    los criterios solicitados por la empresa que lo recibirá.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    4.2. La empresa de acogida deberá aportar las misiones
                    laborales y las tareas correspondientes a las previstas en
                    el convenio de prácticas o contrato de aprendizaje
                    establecido antes del inicio en la empresa de acogida.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    4.3. Las empresas de acogida están sujetas a y deben cumplir
                    con las leyes. Los beneficios que brindan las empresas de
                    acogida (bonificación, alojamiento, reembolso de boletos de
                    transporte, comidas, etc.) son diferentes y no pueden ser
                    garantizados por T.D. antes de firmar el convenio de
                    prácticas o el contrato de aprendizaje.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    4.4. La empresa de acogida es responsable de todos los daños
                    (corporales o materiales) que sufra el alumno durante su
                    jornada laboral o en el viaje de casa a la empresa. Si el
                    cliente es el causante del daño causado, es su propia
                    responsabilidad civil la que incurre. Algunas escuelas
                    también pueden proporcionar cobertura de responsabilidad
                    civil para el alumno. Como parte de un contrato de
                    aprendizaje, el cliente está cubierto por la misma cobertura
                    de seguridad social que un empleado de la empresa de acogida
                    que está sujeto a las cotizaciones sociales previstas por la
                    ley. La empresa de acogida es responsable ante el aprendiz,
                    sea éste o no el causante del daño causado.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    4.5. Todas las negociaciones sobre horarios, bonos, y
                    vacaciones se llevan a cabo directamente entre el cliente y
                    la empresa de acogida. Sin embargo, T.D. solicita ser
                    informado antes de cualquier negociación para garantizar el
                    cumplimiento de la solicitud y asesorar al cliente si es
                    necesario.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    4.6. El alumno es responsable de su uniforme durante la
                    duración de las prácticas o contrato de aprendizaje. Los
                    alumnos deben presentarse a la empresa de acogida con su
                    propio uniforme escolar completo y limpio (a menos que la
                    empresa de acogida proporcione su propio uniforme).
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    4.7. La empresa de acogida y T.D. confirman que no se
                    tolerará ninguna ausencia durante el período en la empresa.
                    Si el alumno realmente no puede acudir a la empresa de
                    acogida, ya sea por motivos médicos o circunstancias
                    excepcionales, es imperativo que lo notifique a la empresa
                    lo antes posible para que encuentre una alternativa.
                    Cualquier motivo médico debe estar justificado mediante una
                    visita al médico y mediante un certificado médico enviado a
                    la empresa lo antes posible. Las ausencias repetidas, no
                    autorizadas o injustificadas serán consideradas muy
                    negativamente por la empresa de acogida y se podrán decidir
                    sanciones, como la terminación de las prácticas o el
                    contrato de aprendizaje. En estas condiciones, el alumno no
                    podrá reclamar ningún reembolso a T.D.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <h5>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: "Calibri,sans-serif" }}>
            <u>
              <span style={{ fontSize: "13.5pt" }}>
                <span style={{ fontFamily: '"EB Garamond"' }}>
                  <span style={{ color: "#0b1941" }}>5. Precios</span>
                </span>
              </span>
            </u>
          </span>
        </span>
      </h5>
      <ul>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    5.1. T.D. se reserva el derecho a modificar sus tarifas. Sin
                    embargo, los servicios se facturan sobre la base de las
                    tarifas vigentes en la fecha de firma del contrato del
                    cliente.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    5.2. Las tarifas se muestran en Euros e incluyen todos los
                    impuestos.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    5.3. Las tarifas no incluyen boletos de avión, seguros,
                    gastos de visa, exceso de equipaje, impuestos de aeropuerto,
                    comida, transporte público, excursiones, actividades
                    adicionales, traslados en taxi, alquiler mensual de la
                    vivienda, dinero de bolsillo ni cualquier otro gasto que no
                    figure en el paquete de T.D.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    5.4. El alumno se compromete a realizar los pagos según un
                    cronograma específico: al firmar el contrato deberá abonar
                    un depósito de 30% a T.D. El saldo del paquete seleccionado
                    es pagadero antes de comunicar los datos de contacto de la
                    empresa de acogida elegida para el cliente y concertar una
                    cita para la entrevista con dicha empresa.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    5.5. Tarifas de T.D.:
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Paquete Future Talent: 500 Euros, impuestos incluidos
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Paquete Future Talent Premium: 900 Euros, impuestos incluidos
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Paquete Future Talent Premium&nbsp;Plus: 1500 Euros, impuestos
                incluidos
              </span>
            </span>
          </span>
        </span>
      </span>
      <h5>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: "Calibri,sans-serif" }}>
            <u>
              <span style={{ fontSize: "13.5pt" }}>
                <span style={{ fontFamily: '"EB Garamond"' }}>
                  <span style={{ color: "#0b1941" }}>
                    &nbsp;6. Pago del Paquete y/o Package: el depósito de 30% y
                    el saldo
                  </span>
                </span>
              </span>
            </u>
          </span>
        </span>
      </h5>
      <ul>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    6.1. Proceso
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    6.1.1. T.D. recibe la solicitud del cliente para una
                    búsqueda de prácticas o un contrato de aprendizaje
                    registrándose en la plataforma T.D. o a través de la escuela
                    o CFA del alumno. El alumno recibe una propuesta para
                    reunirse con T.D. para hablar de sus expectativas y para que
                    T.D. ofrezca el servicio más adecuado para el cliente. Si el
                    cliente está interesado en el servicio, abona el depósito de
                    30% tras su entrevista. El cliente también debe completar
                    los pasos y proporcionar los documentos solicitados en su
                    perfil personal.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    6.1.2. T.D. corrige el CV y ​​la carta de presentación del
                    cliente, prepara y envía la solicitud del cliente a las
                    empresas de acogida correspondientes a las ofertas en las
                    empresas identificadas y seleccionadas para el cliente.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    6.1.3. Cuando una primera empresa de acogida muestra interés
                    en la solicitud del cliente y para habilitar la conexión,
                    T.D. solicita el pago del saldo del paquete correspondiente
                    al Paquete seleccionado para y por el cliente. T.D. no
                    divulgará los datos de contacto o el nombre de la empresa de
                    acogida preseleccionada para el cliente hasta que hayan
                    recibido el saldo adeudado por el paquete. La empresa de
                    acogida puede expresar interés en el perfil del cliente y
                    solicitar la organización de una primera entrevista
                    presencial o remota (Skype, WhatsApp, teléfono, etc.) con el
                    cliente. La empresa de acogida también puede solicitar otras
                    entrevistas en función de su proceso de reclutamiento
                    interno o un video introductorio, prueba escrita u otra
                    prueba de conocimiento o de competencia del cliente antes de
                    tomar su decisión.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    6.1.4. Si varias empresas están interesadas en el perfil del
                    cliente, T.D. se compromete a seleccionar la empresa que
                    corresponda al mejor interés del cliente en función de la
                    información proporcionada por este último durante las
                    distintas etapas del servicio.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    6.1.5. Algunas empresas de acogida aceptan solicitudes de
                    clientes sin una entrevista previa. En este caso, T.D.
                    solicita el pago del saldo al cliente tan pronto como la
                    solicitud del cliente haya llamado la atención de la empresa
                    de acogida. Una vez realizado el pago por parte del cliente,
                    T.D. comunica información sobre la empresa favorable a su
                    solicitud. Esta información permitirá al cliente tomar una
                    decisión con respecto a la oferta de la empresa de acogida.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    6.1.6. El cliente se compromete a pagar los costos del
                    depósito y el saldo al recibir la factura de T.D.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    6.1.7. T.D. envía toda la información necesaria al cliente
                    para que pueda prepararse para su entrevista con la empresa
                    de acogida (folleto de consejos, nombre de los contactos de
                    RR.HH. y tutor, sitio web y contacto de la empresa de
                    acogida) después de recibir el pago del saldo del cliente.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    6.1.8. Es responsabilidad del cliente hacer todas las
                    preguntas necesarias sobre sus prácticas o contrato de
                    aprendizaje (misiones, fechas de inicio y finalización en la
                    empresa, bonificación, condiciones de trabajo, horario de
                    trabajo, lugar de trabajo) durante la entrevista con la
                    empresa de acogida o antes de aceptar las prácticas.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    6.2. Pago y devolución del depósito y/o saldo del Paquete:
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    6.2.1. Todos los pagos son seguros y deben realizarse a
                    través del portal de alumnos o transferencias bancarias. No
                    se aceptan cheques bancarios ni efectivo.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    6.2.2. El pago del depósito y el saldo debe hacerse dentro
                    de los dos días (48 horas) posteriores a la recepción de la
                    factura.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    6.2.3. Es responsabilidad del cliente asegurarse de que el
                    pago sea posible y se pueda realizar a tiempo.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    6.2.4. Dado que las tarifas de los servicios ofrecidos son
                    en Euros (€), las variaciones de los tipos de cambio no son
                    reembolsables ni imputables a T.D.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    6.2.5. T.D. puede utilizar los servicios de una empresa de
                    cobranza para asegurar el pago de sus servicios en caso de
                    que el cliente no pague las facturas enviadas por T.D. y los
                    montos adeudados.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    6.2.6. Una vez pagado el saldo, si el cliente no pasa la
                    primera entrevista o prueba requerida por la empresa de
                    acogida, T.D. podrá organizar un máximo de 3 entrevistas o
                    pruebas con las empresas de acogida. Sin embargo, el cliente
                    comprende y acepta que T.D. no puede realizar y garantizar
                    la organización de más entrevistas o pruebas, ya que esto
                    depende de la voluntad de las empresas de acogida y su
                    interés en el perfil del cliente.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    6.2.7. T.D. no se hace responsable si ninguna empresa de
                    acogida está interesada en el perfil del cliente (para una
                    entrevista o una prueba), pero no habiendo podido prestar el
                    servicio, T.D. se compromete a reembolsar el importe el
                    depósito recibido (30 %).
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    6.2.8. Si el cliente decide no asistir a la entrevista
                    programada con la empresa de acogida, después de recibir el
                    mensaje de T.D. para el pago del saldo, el saldo permanece
                    adeudado a T.D. por parte del cliente.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    6.2.9. Si el cliente decide abandonar el proceso de
                    colocación, por cualquier motivo (incluido haber obtenido
                    las prácticas o un contrato de aprendizaje fuera del proceso
                    de colocación de T.D.), antes del pago del saldo, el saldo a
                    pagar sigue adeudado a T.D. por parte del cliente.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    6.2.10. Si el cliente cambia de opinión tras ser aceptado
                    por una empresa de acogida (tras o sin entrevista previa) y
                    rechaza la oferta, no se procederá al reembolso de las sumas
                    ya abonadas bajo ningún concepto.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    6.2.11. La cancelación de la solicitud del cliente con base
                    en, pero no limitado a, los siguientes motivos: cambio de
                    opinión, pérdida de interés en la oferta, espera de
                    entrevistas con empresas no relacionadas con T.D., búsqueda
                    de una empresa de acogida por sí misma, cambio de fechas,
                    cambio de campo de estudio, falta de recursos económicos,
                    falta de obtención de créditos o cualquier subvención, beca
                    u otros documentos administrativos (como, pero no limitado
                    a: visa, permiso de residencia, etc.) o cualquier otro
                    motivo que no figure en la lista anterior, no se considerará
                    como motivo válido para no pagar los 30 % o para solicitar
                    el reembolso.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    6.2.12. Si el comportamiento del cliente se considera poco
                    profesional en cualquier etapa del proceso (por ejemplo,
                    envío de documentos fuera de plazo, comportamiento
                    inadecuado hacia T.D., la empresa de acogida o cualquier
                    otro socio), T.D. puede decidir romper su compromiso con el
                    cliente sin que el cliente solicite ningún reembolso.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <h5>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: "Calibri,sans-serif" }}>
            <u>
              <span style={{ fontSize: "13.5pt" }}>
                <span style={{ fontFamily: '"EB Garamond"' }}>
                  <span style={{ color: "#0b1941" }}>
                    7. Cambio de empresa de acogida o reembolso del Paquete:
                  </span>
                </span>
              </span>
            </u>
          </span>
        </span>
      </h5>
      <ul>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    7.1. En caso de un problema con la empresa de acogida, el
                    cliente debe ponerse en contacto rápidamente con T.D. para
                    que pueda encontrar soluciones.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <ul>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    7.2. Cuando el cliente aún no ha iniciado sus prácticas o su
                    contrato de aprendizaje:
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    7.2.1. La cancelación de las prácticas o un contrato de
                    aprendizaje del cliente por las siguientes razones, pero no
                    limitadas a estas: cambio de opinión, pérdida de interés en
                    la oferta, espera de entrevistas con empresas no
                    relacionadas con T.D., haber encontrado otra empresa de
                    acogida por su cuenta, cambio de fechas, cambio de campo de
                    estudio, cese o exclusión de la institución educativa, falta
                    de recursos económicos, no obtención de créditos o cualquier
                    otra subvención, beca, visa o cualquier otra razón que no
                    aparezca en la lista anterior no será considerada como una
                    razón válida para no pagar la factura del Paquete o para
                    solicitar el reembolso.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    7.2.2. El cliente entiende y acepta que al contratar a T.D.
                    para la búsqueda de una empresa de acogida y/o cualquier
                    otro servicio, T.D. prevé tiempo para completar el trabajo
                    solicitado por el cliente. Por lo tanto, T.D. solicita al
                    cliente que pague el paquete seleccionado por el trabajo
                    realizado y el tiempo invertido.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <ul>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    7.3. Cuando el cliente ya se ha comenzado en la empresa de
                    acogida:
                  </span>
                </span>
              </span>
            </span>
          </span>
          <ul style={{ listStyleType: "disc" }}>
            <li>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#0b1941" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontSize: "10.5pt" }}>
                      <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                        7.3.1. La solicitud de un cliente para cambiar la
                        Empresa de acogida solo se tendrá en cuenta en los casos
                        reconocidos por la ley, tales como: – Acoso sexual o
                        moral. – Difamación racial o religiosa. – Incapacidad
                        por parte de la empresa de acogida para continuar la
                        formación en la empresa. – Declaración de quiebra de la
                        empresa de acogida. – El incumplimiento de las medidas
                        de seguridad o salud que puedan dañar o afectar al
                        cliente. – Falta de correlación entre las misiones y
                        tareas encomendadas al cliente y las especificadas en el
                        convenio de prácticas o el contrato de aprendizaje
                        suscrito entre el cliente y la empresa de acogida.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#0b1941" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontSize: "10.5pt" }}>
                      <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                        7.3.2. En caso de insatisfacción del cliente con la
                        empresa de acogida, T.D. se compromete a escuchar los
                        motivos y hacer todo lo posible para proponer soluciones
                        viables para el cliente y para la empresa de acogida.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#0b1941" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontSize: "10.5pt" }}>
                      <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                        7.3.3. Si el cliente tiene algún problema con su
                        alojamiento o cualquier otro servicio adquirido a través
                        de T.D. durante las prácticas o el periodo de
                        aprendizaje, primero debe comunicarse con T.D. T.D.
                        investigará la situación, dará consejos para que el
                        alumno pueda contactar con su tutor en la empresa de
                        acogida o el socio que prestó un servicio al cliente.
                        T.D. se pondrá en contacto con la empresa de acogida o
                        los socios y ofrecerá concertar una reunión o una
                        conferencia telefónica entre T.D., el cliente y la
                        institución de acogida o el socio para encontrar las
                        soluciones adecuadas. Solo se tendrán en cuenta las
                        quejas comunicadas por escrito (correo postal o
                        electrónico).
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#0b1941" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontSize: "10.5pt" }}>
                      <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                        7.3.4 Si el alumno siente que el empleador lo está
                        tratando injustamente, debe solicitar al tutor o gerente
                        una cita para conversar de su desempeño en la empresa.
                        Hay que pensar que dependiendo de la cultura de cada
                        persona las cosas se pueden percibir de manera muy
                        diferente. El alumno decide venir a París para aprender
                        una profesión, pero también para comprender la cultura y
                        acostumbrarse a una forma de actuar diferente a la de su
                        país. El alumno debe escuchar los comentarios tomándolos
                        siempre de forma positiva y aceptar la multiculturalidad
                        con la mente abierta. Durante la cita, el alumno
                        preguntará a su tutor si hay áreas en las que necesita
                        mejorar y le explicará/demostrará los procedimientos en
                        los que no se sienta cómodo. Si la situación no mejora,
                        el alumno notificará a T.D. para que pueda brindar
                        asistencia.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#0b1941" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontSize: "10.5pt" }}>
                      <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                        7.3.5 Antes de emprender cualquier acción dentro de la
                        empresa, el alumno se pondrá en contacto con T.D. para
                        recibir asesoramiento. ¡Los equipos de T.D. no pueden
                        ayudar a sus clientes a resolver un problema del que no
                        estarían al tanto de antemano!
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#0b1941" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontSize: "10.5pt" }}>
                      <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                        7.3.6. Si el cliente no está satisfecho con la empresa
                        de acogida por una razón válida y justificada (vea la
                        sección 7.3.1) y no se pueden encontrar soluciones, T.D.
                        se compromete a hacer todo lo posible para encontrar
                        otra empresa de acogida correspondiente al perfil y
                        campo de actividad del cliente (dependiendo de la
                        disponibilidad de las empresas de acogida), la duración
                        restante (mínimo 3 meses) y el nivel de francés del
                        cliente.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#0b1941" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontSize: "10.5pt" }}>
                      <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                        7.3.7. En el caso de que el cliente decida terminar sus
                        prácticas o contrato de aprendizaje por su propia cuenta
                        sin comentarlo o notificarlo a T.D. y sin la validación
                        previa de T.D., o si la empresa de acogida decide
                        terminar debido a un comportamiento inadecuado
                        (comportamiento verbalmente o físicamente abusivo,
                        comportamiento racista, consumo de drogas, alcohol, mal
                        comportamiento, insubordinación, comportamiento
                        considerado inaceptable por el tutor o los gerentes de
                        la empresa de acogida, negligencia profesional de las
                        misiones de trabajo o incumplimiento de las normas de la
                        empresa de acogida), T.D. se reserva el derecho de no
                        reembolsar el paquete o servicios «a la carta» pagados
                        por el cliente y de no recolocar al cliente en otro
                        empresa de acogida.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#0b1941" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontSize: "10.5pt" }}>
                      <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                        7.3.8. El alumno se compromete a respetar la ley
                        francesa y a no ponerse en peligro a sí mismo ni a
                        terceros.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
        </li>
      </ul>
      <h5>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: "Calibri,sans-serif" }}>
            <u>
              <span style={{ fontSize: "13.5pt" }}>
                <span style={{ fontFamily: '"EB Garamond"' }}>
                  <span style={{ color: "#0b1941" }}>
                    8. Socios o empresas de servicios de Talent Developer
                  </span>
                </span>
              </span>
            </u>
          </span>
        </span>
      </h5>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Dependiendo de la situación, T.D. puede trabajar con diferentes
                socios o empresas de servicios seleccionadas para ayudar al
                alumno a establecerse en París y proporcionar otros servicios
                que faciliten su vida diaria en Francia. Puede encontrar todos
                nuestros «servicios a la carta» en el sitio web de T.D.
              </span>
            </span>
          </span>
        </span>
      </span>
      <ul>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    8.1. Socio: Socio se refiere a cualquier empresa,
                    organización o persona física o jurídica empleada o
                    utilizada por T.D. para sus servicios (por ejemplo, agencia
                    de alojamiento, empresa de traslado, empresa de servicio de
                    limpieza, clases de cocina y/o pastelería, excursiones en
                    París, cursos de vino, y cualquier otro proveedor de
                    servicios con el que T.D. pueda trabajar para proporcionar
                    servicios adicionales al cliente).
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    8.2. El cliente entiende y acepta que, dependiendo de los
                    servicios requeridos, también podrá ponerse en contacto con
                    estos socios.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    8.3. Alojamiento: La agencia de alojamiento se refiere a
                    cualquier empresa que ofrece servicios de alojamiento al
                    cliente. T.D. no incluye alojamiento para alumnos en ninguno
                    de sus servicios, pero se ofrece a ponerle en contacto con
                    sus socios y ofrecerle condiciones ventajosas. Presta
                    atención a los precios de los alojamientos en París, que
                    pueden ser más o menos costosos según el tipo de alojamiento
                    y su ubicación. En la provisión de búsqueda de vivienda en
                    París, estos dos socios se comprometen a:
                  </span>
                </span>
              </span>
            </span>
          </span>
          <ul style={{ listStyleType: "disc" }}>
            <li>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#0b1941" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontSize: "10.5pt" }}>
                      <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                        Encontrar una habitación privada o una habitación
                        compartida en un apartamento o casa compartida, una
                        habitación privada o una habitación compartida con una
                        familia de acogida o un alquiler privado.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#0b1941" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontSize: "10.5pt" }}>
                      <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                        Coordinación entre el cliente y el responsable del
                        apartamento o casa (propietario, inquilino principal,
                        agencia de vivienda o familia de acogida) antes de que
                        el cliente acceda al alojamiento.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    8.4. Agencia de corretaje de seguros: Para la seguridad del
                    alumno y la de los demás, se requiere que el alumno esté en
                    posesión de un seguro de responsabilidad civil, seguro de
                    hogar/alquiler y seguro de salud/médico como se indica en
                    los párrafos anteriores.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    8.5. Traslado ida y vuelta del aeropuerto/Paris: La empresa
                    de traslados se refiere a cualquier empresa que colabora con
                    T.D. para brindar servicios de transporte al cliente.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    8.6. Escuela de francés: La escuela de francés se refiere a
                    cualquier escuela de idiomas autorizada que colabora con
                    T.D. para proporcionar cursos de francés FLE (francés como
                    lengua extranjera) al cliente.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <h5>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: "Calibri,sans-serif" }}>
            <u>
              <span style={{ fontSize: "13.5pt" }}>
                <span style={{ fontFamily: '"EB Garamond"' }}>
                  <span style={{ color: "#0b1941" }}>
                    9. Circunstancias inevitables
                  </span>
                </span>
              </span>
            </u>
          </span>
        </span>
      </h5>
      <ul>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    9.1. T.D. y sus socios o proveedores quedan liberados de sus
                    obligaciones contractuales en caso de que se produzca un
                    evento de fuerza mayor, o cualquier acto imputable a un
                    tercero, o cualquier otra circunstancia o causa externa
                    independiente del personal de T.D., que prevenga
                    directamente o indirectamente T.D. para cumplir con sus
                    obligaciones contractuales. Además de los casos reconocidos
                    por los tribunales, los casos de fuerza mayor incluyen,
                    entre otros, los siguientes casos: todos los desastres
                    naturales, actos de guerra, alteraciones del orden público,
                    epidemias, incendios, inundaciones y otras catástrofes,
                    actos de cualquier gobierno, todas las huelgas en cualquier
                    forma (interna, externa, etc.) y cualquier mal
                    funcionamiento de Internet o red. Los servicios prestados
                    por T.D. y sus socios o proveedores no serán reembolsables.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <h5>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: "Calibri,sans-serif" }}>
            <u>
              <span style={{ fontSize: "13.5pt" }}>
                <span style={{ fontFamily: '"EB Garamond"' }}>
                  <span style={{ color: "#0b1941" }}>
                    10. Ley aplicable y resolución de disputas
                  </span>
                </span>
              </span>
            </u>
          </span>
        </span>
      </h5>
      <ul>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    10.1. Estos términos y condiciones son válidos a partir del
                    1 de octubre de 2020 y están sujetos a la ley francesa. Si
                    se realizan cambios en los términos y condiciones, serán
                    válidos cuando se publiquen en el T.D.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    10.2. Este contrato está sujeto a la ley francesa. La
                    jurisdicción competente en caso de litigio es el tribunal de
                    París.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    10.3. Estos términos y condiciones representan un contrato
                    entre el cliente y T.D.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <strong>&nbsp;</strong>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Condiciones de Talent Developer 01 octubre 2020
              </span>
            </span>
          </span>
        </span>
      </span>
    </>
  );
};

export default CGVContentES;
