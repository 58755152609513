import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  DialogContentText,
  DialogActions,
  Button,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import TalentLoader from "../core/TalentLoader";
import projectService from "../../services/project.service";
import { useAuth } from "../../context/auth-context";
import { useTranslation } from "react-i18next";
import TalentIcon from "../core/TalentIcon";
import TalentPdfViewer from "../core/TalentPdfViewer";
import Alert from "@material-ui/lab/Alert";

const useStyle = makeStyles((theme) => ({
  container: {
    width: "100%",
    padding: "1em",
    cursor: "pointer",
  },
  dialog: {
    textAlign: "center",
  },
  previewItem: {
    maxWidth: "100%",
  },
  previewMessage: {
    padding: "1em",
  },
  marginBottom: {
    marginBottom: "1em",
  },
  button: {
    cursor: "pointer",
    color: theme.palette.secondary.light,
    marginLeft: "0.5em",
    display: "flex",
    justifyContent: "center",
  },
}));

const DocumentCard = ({ name, path, reloadParent }) => {
  const classes = useStyle();
  const auth = useAuth();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [document, setDocument] = useState(null);
  const [error, setError] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setError(false);
    setLoading(true);
    setOpen(true);
    const filepath = path.replace("projet/", "") + "/" + name;
    projectService
      .getDocumentDetail(auth.user, filepath)
      .then((res) => {
        setDocument(res);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        setError(true);
      });
  };

  const handleDeleteImage = () => {
    handleClose();
    setDeleteConfirm(true);
  };

  const confirmDeleteDocument = () => {
    setDeleteConfirm(false);
    setDeleteLoading(true);
    const filepath = path.replace("projet/", "") + "/" + name;
    projectService
      .deleteDocument(auth.user, filepath)
      .then((res) => {
        setDeleteLoading(false);
        reloadParent();
      })
      .catch((err) => {
        console.error(err);
        setDeleteLoading(false);
      });
  };

  const displayFileContent = () => {
    let ret = (
      <div className={classes.previewMessage}>{t("NoFilePreview")}</div>
    );
    if (document.contentType.includes("image/")) {
      ret = (
        <img
          src={`data:${document.contentType};${document.encoding},${document.content}`}
          alt={document.filename}
          className={classes.previewItem}
        />
      );
    } else {
      switch (document.contentType) {
        case "application/pdf":
          ret = (
            <TalentPdfViewer
              pdfFile={`data:${document.contentType};${document.encoding},${document.content}`}
            />
          );
          break;
        case "application/msword":
        default:
          break;
      }
    }
    return ret;
  };

  return (
    <>
      {deleteLoading && (
        <Backdrop className={classes.backdrop} open={deleteLoading}>
          <CircularProgress color="secondary" />
        </Backdrop>
      )}
      <div onClick={handleOpen} className={classes.container}>
        {name}
      </div>
      <Dialog
        onClose={handleClose}
        open={open}
        className={classes.dialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "0.7em",
            }}
          >
            {name}
            {document && (
              <>
                <a
                  href={`data:${document.contentType};${document.encoding},${document.content}`}
                  download={document.filename}
                  className={classes.button}
                >
                  <TalentIcon icon="download" size={40} />
                </a>
                <IconButton color="secondary" onClick={handleDeleteImage}>
                  <TalentIcon icon="trash-2" size={40} />
                </IconButton>
              </>
            )}
          </div>
        </DialogTitle>
        <DialogContent>
          {loading ? <TalentLoader /> : document && displayFileContent()}
          {error && (
            <Alert severity="error">{t("ErrorFetchingDocument")}</Alert>
          )}
        </DialogContent>
      </Dialog>

      <Dialog
        onClose={() => setDeleteConfirm(false)}
        open={deleteConfirm}
        className={classes.dialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-title">
          {t("ConfirmDeleteDocument")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("ConfirmDeleteDocumentDesc")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirm(false)} color="primary">
            {t("Cancel")}
          </Button>
          <Button onClick={confirmDeleteDocument} color="primary" autoFocus>
            {t("Delete")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DocumentCard;
