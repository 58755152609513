import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../context/auth-context";
import projectService from "../../services/project.service";
import TalentLoader from "../core/TalentLoader";
import DocumentCard from "./DocumentCard";

const useStyle = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
});

const DocumentList = ({ reloadList, reloadParent }) => {
  // Load classes
  const classes = useStyle();

  // Load auth
  const auth = useAuth();

  const { t } = useTranslation();

  const [documents, setDocuments] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    projectService
      .getDocumentList(auth.user)
      .then((res) => {
        setLoading(false);
        setDocuments(res);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  }, [auth, reloadList]);

  return (
    <div className={classes.container}>
      {loading ? (
        <TalentLoader />
      ) : documents ? (
        documents.map((item) => {
          return (
            <DocumentCard
              key={item.rowid}
              name={item.filename}
              path={item.filepath}
              reloadParent={reloadParent}
            />
          );
        })
      ) : (
        <span>{t("NoDocuments")}</span>
      )}
    </div>
  );
};

export default DocumentList;
