import React, { useState, useEffect } from "react";
import { Button, makeStyles } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

const useStyle = makeStyles({
  button: {
    borderRadius: "0",
    padding: "0.5em 2em",
    fontFamily: "EBGaramond",
    fontSize: 18,
    width: "auto",
    fontWeight: "bold",
    fontStyle: "normal",
    letterSpacing: 0,
    textTransform: "none",
  },
  buttonWithIcon: {
    "& .MuiButton-label": {
      marginLeft: "-2.7rem",
      justifyContent: "flex-start",
    },
  },
  buttonWithIconContent: {
    display: "flex",
    width: "100%",
    minWidth: "calc(220px - 2.7rem)",
    alignItems: "center",
    justifyContent: "space-between",
  },
});

const TalentButton = ({ variant, withIcon, children, ...props }) => {
  const classes = useStyle();
  const [definedVariant, setDefinedVariant] = useState(
    variant ? variant : "outlined"
  );
  const [definedWithIcon, setDefinedIcon] = useState(withIcon ? true : false);

  const displayButtonContent = () => {
    return definedWithIcon ? (
      <div className={classes.buttonWithIconContent}>
        {children}
        <ArrowForwardIcon />
      </div>
    ) : (
      children
    );
  };

  // Listen for props change
  useEffect(() => {
    setDefinedVariant(variant ? variant : "outlined");
  }, [variant]);
  useEffect(() => {
    setDefinedIcon(withIcon ? true : false);
  }, [withIcon]);

  return (
    <Button
      variant={definedVariant}
      color="secondary"
      className={`${classes.button} ${
        definedWithIcon && classes.buttonWithIcon
      }`}
      {...props}
    >
      {displayButtonContent()}
    </Button>
  );
};

export default TalentButton;
