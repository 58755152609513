import React, { useState } from "react";
import TalentWhiteContainer from "../core/layout/TalentWhiteContainer";
import DocumentList from "./DocumentList";
import TalentHeaderContainer from "../core/layout/TalentHeaderContainer";
import TalentDashboardTitle from "../core/TalentDashboardTitle";
import { useTranslation } from "react-i18next";
import DocumentUpload from "./DocumentUpload";

const Document = () => {
  // Load translations
  const { t } = useTranslation();

  const [reload, setReload] = useState(false);

  return (
    <>
      <TalentHeaderContainer>
        <TalentDashboardTitle title={t("Documents")} />
      </TalentHeaderContainer>
      <TalentWhiteContainer>
        <DocumentUpload reloadParent={() => setReload(!reload)} />
        <DocumentList
          reloadList={reload}
          reloadParent={() => setReload(!reload)}
        />
      </TalentWhiteContainer>
    </>
  );
};

export default Document;
