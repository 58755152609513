import { makeStyles } from "@material-ui/core";
import React from "react";
import TalentIcon from "./TalentIcon";

const useStyle = makeStyles({
  tooltip: {
    backgroundColor: "white",
    color: "#2247ab",
    padding: "10px",
    borderRadius: "3px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: "0px 2px 2px 2px rgba(0, 0, 0, 0.03)",
    width: "300px",
    boxSizing: "border-box",
  },
  message: {
    flex: 2,
    marginLeft: 8,
    wordBreak: "break-all",
  },
  button: {
    marginLeft: 20,
    border: "none",
    backgroundColor: "transparent",
    cursor: "pointer",
    color: "#2247ab",
  },
});

const AlertTemplate = ({ style, options, message, close }) => {
  // Load classes
  const classes = useStyle();

  return (
    <div style={style} className={classes.tooltip}>
      {options.type === "info" && <TalentIcon icon="info" color="#2247ab" />}
      {options.type === "success" && (
        <TalentIcon icon="check-circle" color="green" />
      )}
      {options.type === "error" && (
        <TalentIcon icon="alert-octagon" color="#dc5145" />
      )}
      <span className={classes.message}>{message}</span>
      <button onClick={close} className={classes.button}>
        <TalentIcon icon="x" />
      </button>
    </div>
  );
};

export default AlertTemplate;
