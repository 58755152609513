import React, { useEffect, useState, useContext, createContext } from "react";
import api from "../services/api";

// Create the maintenanceContext
const maintenanceContext = createContext();

/**
 * Hook for child components to get the auth object
 * and re-render when it changes.
 */
export const useMaintenance = () => {
  return useContext(maintenanceContext);
};

/**
 * Provider component that wraps your app and makes maintenance object
 * available to any child component that calls useMaintenance().
 */
export function ProvideMaintenance({ children }) {
  const maintenance = useProvideMaintenance();
  return (
    <maintenanceContext.Provider value={maintenance}>
      {children}
    </maintenanceContext.Provider>
  );
}

/**
 * Provider hook that creates maintenance object and handles state
 */
function useProvideMaintenance() {
  const [underMaintenance, setUnderMaintenance] = useState(false);
  const [maintenanceTime, setMaintenanceTime] = useState(0);
  const [apiError, setApiError] = useState(false);
  const [exceptionIp, setExceptionIp] = useState("");
  const [requestIp, setRequestIp] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Get the maintenance values
    setApiError(false);
    api
      .get(`/talentdeveloper/maintenance`)
      .then((response) => {
        const data = response.data.success;
        if (data) {
          setUnderMaintenance(data.maintenance === "1" ? true : false);
          setMaintenanceTime(0);
          setExceptionIp(data.except_ip);
          setRequestIp(data.request_ip);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        setApiError(true);
        throw err;
      });
  }, []);

  // Return the user object and auth methods
  return {
    underMaintenance,
    maintenanceTime,
    exceptionIp,
    requestIp,
    loading,
    apiError,
  };
}
