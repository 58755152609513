import { makeStyles, TextField, Box } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ProjectService from "../../services/project.service";
import TalentButton from "../core/TalentButton";
import { useAuth } from "../../context/auth-context";
import { useAlert } from "react-alert";
import logo from "../../assets/icons/SendButton.svg";

const useStyle = makeStyles({
  root: {
    //position: "fixed",
    //bottom: 0,
    //height: "30vh",
    //width: "100vw",
    //backgroundColor: "#f2f4f8",
  },
  sendbutton: {
    height: "4em",
    width: "4em",
    padding: "0",
    borderRadius: "1em",
    background: "#0B1941",
    border: "none",
    "&:hover": {
      cursor: "pointer",
      background: "#DC5145",
    },
  },
});

const MessageEditor = ({ messages, addMessage }) => {
  // Load langs
  const { t } = useTranslation();

  // Load auth
  const auth = useAuth();

  // Load alert
  const alert = useAlert();

  // Load classes
  const classes = useStyle();

  // States
  const [content, setContent] = useState("");
  const [error, setError] = useState(false);

  // Handle send button click
  const handleClick = async () => {
    if (content !== "") {
      ProjectService.sendMessage(
        auth.user,
        content.replace("/\\r\\n/g", "<br />")
      )
        .then((createdMessage) => {
          if (createdMessage) {
            addMessage(createdMessage);
            // Clean input
            setContent("");
            setError(false);
          }
        })
        .catch((err) => {
          console.error(err);
          setError(true);
          alert.show(t("MessageNotSent"), { type: "error" });
        });
    } else {
      alert.show(t("MessageCantBeEmpty"), { type: "info" });
    }
  };

  return (
    <div id="msgEditor" className={classes.root}>
      <Box>
        <TextField
          label="Contenu du message"
          multiline
          fullWidth
          value={content}
          variant="outlined"
          error={error}
          onChange={(e) => {
            setContent(e.target.value);
            setError(false);
          }}
        />
      </Box>
      <Box
        margin="1rem 0"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <TalentButton className={classes.sendbutton} onClick={handleClick}>
          <img src={logo} alt="SEND" style={{ height: "2em" }} />
        </TalentButton>
      </Box>
    </div>
  );
};

export default MessageEditor;
