import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyle = makeStyles({
  root: {
    display: "flex",
    margin: "2em 0 0",
    width: "100%",
    justifyContent: "space-around",
    alignItems: "center",
    flexGrow: "0",
    flexWrap: "wrap",
    height: "auto",
  },
});

const TalentFooterContainer = ({ children }) => {
  const classes = useStyle();
  return <div className={classes.root}>{children}</div>;
};

export default TalentFooterContainer;
