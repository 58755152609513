import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import SigninOrRegister from "./auth/SigninOrRegister";
import SigninComponent from "./auth/SigninComponent";
import RegisterComponent from "./auth/RegisterComponent";
import TalentHeader from "./core/TalentHeader";
import AppHeader from "./core/layout/AppHeader";
import AppFooter from "./core/layout/AppFooter";
import AppContainer from "./core/layout/AppContainer";
import ForgotPassword from "./auth/ForgotPassword";
import ResetPassword from "./auth/ResetPassword";

const UnauthenticatedApp = () => {
  return (
    <div className="App">
      <Router>
        <AppHeader>
          <TalentHeader displayBtn={false} />
        </AppHeader>

        <AppContainer>
          <Switch>
            <Route path="/login">
              <SigninComponent />
            </Route>
            <Route path="/register">
              <RegisterComponent />
            </Route>
            <Route path="/forgot_password">
              <ForgotPassword />
            </Route>
            <Route path="/reset_password">
              <ResetPassword />
            </Route>
            <Route path="*">
              <SigninOrRegister />
            </Route>
          </Switch>
        </AppContainer>

        <AppFooter />
      </Router>
    </div>
  );
};

export default UnauthenticatedApp;
