import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyle = makeStyles({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    margin: "0",
    width: "100%",
    padding: "0",
    flexGrow: "0",
    height: "auto",
  },
});

const TalentContentContainer = ({ children, ...props }) => {
  const classes = useStyle();
  return (
    <div className={classes.root} {...props}>
      {children}
    </div>
  );
};

export default TalentContentContainer;
