import React from "react";
import { makeStyles } from "@material-ui/core";
import logo from "../../assets/img/talentdeveloper.png";

const useStyle = makeStyles({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100vw",
    backgroundColor: "#0b1941",
  },
  logo: {
    width: "40vw",
  },
});

const TalentSplashscreen = () => {
  const classes = useStyle();
  return (
    <div className={classes.root}>
      <img src={logo} alt="Talent Developer logo" className={classes.logo} />
    </div>
  );
};

export default TalentSplashscreen;
