import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Badge,
  AppBar,
  Toolbar,
  Hidden,
  Typography,
  useScrollTrigger,
  Slide,
} from "@material-ui/core";
import { NavLink } from "react-router-dom";
import logo from "../../assets/img/logo-talent-developer.svg";
import TalentIcon from "./TalentIcon";
import { useTranslation } from "react-i18next";

const useStyle = makeStyles((theme) => ({
  toolbar: {
    zIndex: 999,
    width: "100%",
    padding: "0",
    backgroundColor: "transparent",
  },
  rootContainer: {
    width: "90%",
    maxWidth: "1100px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#f2f4f8",
    transition: "all 1s ease-in-out",
    borderBottom: "solid 1px rgba(0,0,0, .15)",
    padding: "1.5rem 0",
    margin: "0 auto",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    flexGrow: "2",
  },
  linkContainer: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    marginLeft: "1em",
  },
  linkIcon: {
    border: "solid 1px #2247ab",
    color: "#2247ab",
    padding: "0",
    width: "2.5rem",
    height: "2.5rem",
    lineHeight: "3rem",
    fontSize: "1rem",
    borderRadius: "8px",
    textAlign: "center",
    cursor: "pointer",
  },
  linkText: {
    color: "#2247ab",
    margin: "0 1em",
  },
  activeLinkWithoutBadge: {
    "& span": {
      backgroundColor: "#2247ab",
      color: "white",
    },
  },
  activeLinkWithBadge: {
    "& span span:first-child": {
      backgroundColor: "#2247ab",
      color: "white",
    },
  },
  toolbarWithoutBorder: {
    borderBottomColor: "transparent",
    backgroundColor: "transparent",
  },
}));

function HideOnScroll({ children }) {
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const TalentHeader = (props) => {
  // Define styles
  const classes = useStyle();

  // Define translation
  const { t } = useTranslation();

  // Define props to display btn section
  const [displayBtn] = useState(
    props.displayBtn === undefined ? true : props.displayBtn
  );

  // Listen for top scroll
  const [onTop, setOnTop] = useState(true);
  useEffect(() => {
    function checkScroll() {
      const scrollCheck = window.scrollY < 94;
      if (scrollCheck !== onTop) {
        setOnTop(scrollCheck);
      }
    }
    window.addEventListener("scroll", checkScroll);
    return (_) => {
      window.removeEventListener("scroll", checkScroll);
    };
  }, [onTop]);

  // Handle notification button view
  const notificationBtn = () => {
    return props.notifications ? (
      <NavLink
        to="/messages"
        className={classes.linkContainer}
        activeClassName={classes.activeLinkWithBadge}
      >
        <Hidden smDown>
          <Typography className={classes.linkText}>{t("Messages")}</Typography>
        </Hidden>
        <Badge color="error" badgeContent={props.notifications.length} max={10}>
          <span className={classes.linkIcon}>
            <TalentIcon icon="messages" />
          </span>
        </Badge>
      </NavLink>
    ) : (
      <NavLink
        to="/messages"
        className={classes.linkContainer}
        activeClassName={classes.activeLinkWithoutBadge}
      >
        <Hidden smDown>
          <Typography className={classes.linkText}>{t("Messages")}</Typography>
        </Hidden>
        <span className={classes.linkIcon}>
          <TalentIcon icon="messages" />
        </span>
      </NavLink>
    );
  };

  return (
    <HideOnScroll>
      <AppBar style={{ boxShadow: "none" }}>
        <Toolbar className={classes.toolbar}>
          <div
            className={`${classes.rootContainer} ${
              onTop && classes.toolbarWithoutBorder
            }`}
          >
            <NavLink exact to="/">
              <img
                src={logo}
                alt="Talent developer logo"
                style={{ height: "2.5rem" }}
              />
            </NavLink>
            {displayBtn && (
              <div className={classes.btnContainer}>
                <NavLink
                  to="/profile"
                  className={classes.linkContainer}
                  activeClassName={classes.activeLinkWithoutBadge}
                  data-testid="header-navlink-profile"
                >
                  <Hidden smDown>
                    <Typography className={classes.linkText}>
                      {t("MyAccount")}
                    </Typography>
                  </Hidden>
                  <span className={classes.linkIcon}>
                    <TalentIcon icon="account" />
                  </span>
                </NavLink>

                <NavLink
                  to="/documents"
                  className={classes.linkContainer}
                  activeClassName={classes.activeLinkWithoutBadge}
                  data-testid="header-navlink-documents"
                >
                  <Hidden smDown>
                    <Typography className={classes.linkText}>
                      {t("Documents")}
                    </Typography>
                  </Hidden>
                  <span className={classes.linkIcon}>
                    <TalentIcon icon="file" />
                  </span>
                </NavLink>

                {notificationBtn()}
              </div>
            )}
          </div>
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );
};

export default TalentHeader;
