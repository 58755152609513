import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyle = makeStyles({
  root: {
    width: "100%",
    border: "none",
    height: "1px",
    backgroundColor: "#062d84",
    opacity:".6",
  }, 
});

const TalentDividerLine = () => {
  // Load classes
  const classes = useStyle();

  return <div className={classes.root}></div>;
};

export default TalentDividerLine;
