import React, { useEffect, useRef, useState } from "react";
import { Box, makeStyles } from "@material-ui/core";
import MessageEditor from "./MessageEditor";
import MessageCard from "./MessageCard";
import projectService from "../../services/project.service";
import { useAuth } from "../../context/auth-context";
import { useAlert } from "react-alert";

const useStyle = makeStyles({
  root: {
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column-reverse",
  },
});

const MessageComponent = ({ messages, setMessages, cleanNotifications }) => {
  const classes = useStyle();
  const scrollToRef = useRef(null);
  const [msgList, setMsgList] = useState(messages ? messages : []);
  const auth = useAuth();
  const alert = useAlert();

  // Component mount
  useEffect(() => {
    // Mark all notifications as read
    projectService
      .markAllNotificationsAsRead(auth.user)
      .then(() => {
        cleanNotifications();

        // Scroll to the bottom on
        if (scrollToRef.current) {
          scrollToRef.current.scrollIntoView({
            behavior: "smooth",
          });
        }
      })
      .catch((err) => {
        // No need to display the error because it's an hidden action
        console.error(err);
      });
  }, [auth, cleanNotifications, alert]);

  // Update message list if props changed
  useEffect(() => {
    setMsgList(messages);
  }, [messages]);

  // Handle message adding
  const handleMessageAdd = (message) => {
    let newMsgList = [...msgList];
    newMsgList.unshift(message);
    // Change parent message list sent in props
    setMessages(newMsgList);
  };

  return (
    <Box id="messages">
      <div id="msgList" className={classes.root}>
        {msgList &&
          msgList.map((item) => <MessageCard key={item.id} message={item} />)}
      </div>
      <MessageEditor addMessage={handleMessageAdd} />
    </Box>
  );
};

export default MessageComponent;
