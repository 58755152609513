import React from "react";
import { useTranslation } from "react-i18next";
import TalentWhiteContainer from "./core/layout/TalentWhiteContainer";
import logo from "../assets/img/settings.png";
import { Typography } from "@material-ui/core";

const UnderMaintenance = () => {
  const { t } = useTranslation();

  return (
    <>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          marginTop: "20vh",
          position: "relative",
          zIndex: "2",
        }}
      >
        <TalentWhiteContainer>
          <img height={100} alt="Under maintenance icon" src={logo} />
          <Typography variant="h2" component="h2" color="primary">
            {t("UnderMaintenance")}
          </Typography>
          <Typography
            variant="subtitle2"
            component="p"
            color="textSecondary"
            style={{ marginTop: "1em" }}
          >
            {t("UnderMaintenanceDesc")}
          </Typography>
        </TalentWhiteContainer>
      </div>
      <div id="outerCraneContainer">
        <div className="buildings">
          <div></div>
          <div className="1"></div>
          <div className="2"></div>
          <div className="3"></div>
          <div className="4"></div>
        </div>
        <div className="crane craneThree">
          <div className="line lineOne"></div>
          <div className="line lineTwo"></div>
          <div className="line lineThree"></div>
          <div className="stand"></div>
          <div className="weight"></div>
          <div className="cabin"></div>
          <div className="arm"></div>
        </div>
        <div className="crane craneTwo">
          <div className="line lineOne"></div>
          <div className="line lineTwo"></div>
          <div className="line lineThree"></div>
          <div className="stand"></div>
          <div className="weight"></div>
          <div className="cabin"></div>
          <div className="arm"></div>
        </div>
        <div className="crane craneOne">
          <div className="line lineOne"></div>
          <div className="line lineTwo"></div>
          <div className="line lineThree"></div>
          <div className="stand"></div>
          <div className="weight"></div>
          <div className="cabin"></div>
          <div className="arm"></div>
        </div>
      </div>
    </>
  );
};

export default UnderMaintenance;
