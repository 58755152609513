import React from "react";
import { useTranslation } from "react-i18next";
import sentImg from "../../assets/img/mail-sent.svg";
import TalentBlueContainer from "../core/layout/TalentBlueContainer";

const RegisterStepSuccess = () => {
  // Load translations
  const { t } = useTranslation();

  return (
    <TalentBlueContainer
      img={sentImg}
      imgAlt="Mail sent"
      title={t("RSMailWasSent")}
      slide={true}
    >
      {t("RSClickOnLink")}
    </TalentBlueContainer>
  );
};

export default RegisterStepSuccess;
