import React, { useState, useEffect } from "react";
import { Switch, Route } from "react-router";
import TalentCGV from "../core/cgv/TalentCGV";
import PaypalPaiement from "./PaypalPaiement";
import projectService from "../../services/project.service";
import { useAuth } from "../../context/auth-context";
import TalentLoader from "../core/TalentLoader";
import ReviewPaiement from "./ReviewPaiement";
import { useAlert } from "react-alert";
import DetailFlatFee from "./DetailFlatFee";

const Step5 = ({ reloadParent }) => {
  // Load auth
  const auth = useAuth();

  // Load alert
  const alert = useAlert();

  // State
  const [loading, setLoading] = useState(true);
  const [deposit, setDeposit] = useState(null);
  const [propal, setPropal] = useState(null);

  useEffect(() => {
    projectService
      .getDepositOverview(auth.user)
      .then((res) => {
        setDeposit(res.invoice);
        setPropal(res.propal);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        alert.show(err.message, { type: "error" });
      });
  }, [auth, alert]);

  return (
    <>
      {loading ? (
        <TalentLoader />
      ) : (
        <Switch>
          <Route path="/paiement">
            <PaypalPaiement deposit={deposit} reloadParent={reloadParent} />
          </Route>

          <Route path="/paiement-cgv">
            <TalentCGV />
          </Route>

          <Route path="/detail-flatfee">
            <DetailFlatFee propal={propal} />
          </Route>

          <Route path={["/", "/login"]} exact>
            <ReviewPaiement propal={propal} depositPrice={deposit.total} />
          </Route>
        </Switch>
      )}
    </>
  );
};

export default Step5;
