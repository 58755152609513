import React, { useState } from "react";
import RegisterStep1 from "./RegisterStep1";
import RegisterStep2 from "./RegisterStep2";
import RegisterStep3 from "./RegisterStep3";
import RegisterStepSuccess from "./RegisterStepSuccess";

//import TalentHeader from "../core/TalentHeader";

const RegisterComponent = () => {
  // Step number
  const [step, setStep] = useState(1);

  // Fields for step 1
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [nationality, setNationality] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [language, setLanguage] = useState("en_US");

  // Fields for step 2
  const [formTitle, setFormTitle] = useState("");
  const [formYear, setFormYear] = useState("");
  const [formCountry, setFormCountry] = useState("");
  const [formType, setFormType] = useState(false);
  const [formDuration, setFormDuration] = useState("");
  const [formDetail, setFormDetail] = useState("");

  // Fields for step 3
  const [password, setPassword] = useState("");
  const [repassword, setRepassword] = useState("");

  // Proceed to next step
  const nextStep = () => {
    setStep(step + 1);
  };

  // Go back to prev step
  const prevStep = () => {
    setStep(step - 1);
  };

  // Display the proper step component
  const displayStepComponent = () => {
    // All values
    const values = {
      firstname,
      lastname,
      nationality,
      email,
      phone,
      language,
      formTitle,
      formYear,
      formCountry,
      formType,
      formDuration,
      formDetail,
      password,
      repassword,
    };

    // Step 1 handlers
    const step1Handlers = {
      setFirstname,
      setLastname,
      setNationality,
      setEmail,
      setPhone,
      setLanguage,
    };

    // Step 2 handlers
    const step2Handlers = {
      setFormTitle,
      setFormYear,
      setFormCountry,
      setFormType,
      setFormDuration,
      setFormDetail,
    };

    // Step 3 handlers
    const step3Handlers = {
      setPassword,
      setRepassword,
    };

    switch (step) {
      case 1:
        return (
          <RegisterStep1
            nextStep={nextStep}
            stepsTotal={3}
            values={values}
            handlers={step1Handlers}
          ></RegisterStep1>
        );
      case 2:
        return (
          <RegisterStep2
            nextStep={nextStep}
            prevStep={prevStep}
            stepsTotal={3}
            values={values}
            handlers={step2Handlers}
          ></RegisterStep2>
        );
      case 3:
        return (
          <RegisterStep3
            nextStep={nextStep}
            prevStep={prevStep}
            stepsTotal={3}
            values={values}
            handlers={step3Handlers}
          ></RegisterStep3>
        );
      case 4:
        return <RegisterStepSuccess></RegisterStepSuccess>;
      default:
        console.log(step);
    }
  };

  return <>{displayStepComponent()}</>;
};

export default RegisterComponent;
