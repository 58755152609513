import React, { useState } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Redirect, useHistory } from "react-router";
import TalentIcon from "../core/TalentIcon";

const useStyle = makeStyles({
  root: {
    display: "flex",
    flexWrap: "wrap",
    margin: "0 -1em",
  },
  header: {
    flex: "1",
    background: "#FFFFFF",
    borderRadius: "1em",
    padding: "2em",
    boxShadow: "0 2em 4em -2em rgba(34,71,171, 0.2)",
    border: "solid 1px rgba(0,0,0, .15)",
    margin: "1em",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    flex: "2",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    padding: 0,
  },
  courses: {
    display: "flex",
    flex: "1",
    justifyContent: "center",
    alignItems: "flex-start",
    alignContent: "flex-start",
    flexWrap: "wrap",
    margin: "1em",
    borderRadius: ".5em",
    padding: "1em",

    boxShadow: "0 2em 4em -2em rgba(34,71,171, 0.2)",
    border: "solid 1px rgba(0,0,0, .15)",
  },
  subtitle: {
    width: "100%",
    margin: "0 0 .5em",
    display: "flex",
    alignItems: "center",
  },
  courseContainer: {
    width: "33%",
    minWidth: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: "1",
    background: "#FFFFFF",
    borderRadius: ".5em",
    boxShadow: "0 0em 2em -1em rgba(34,71,171, 0)",
    transition: "all .25s ease-in-out",
    border: "solid 1px rgba(0,0,0, .15)",
    padding: "1em 1em",
    margin: ".5em",
    color: "secondary",
    textAlign: "center",
    wordBreak: "break-word",
    "&:hover": {
      cursor: "pointer",
      boxShadow: "0 0em 2em -1em rgba(34,71,171, 0.75)",
    },
  },
  courseIcon: {
    marginRight: ".3em",
  },
});

const ELearning = () => {
  // Load styles
  const classes = useStyle();

  // Load translations
  const { t } = useTranslation();

  // Load history
  const history = useHistory();

  // State for redirections
  const [redirect, setRedirect] = useState(false);
  const [redirectTo, setRedirectTo] = useState("");

  // Function used to redirect to a course
  const prepareRedirectionTo = (to) => {
    setRedirect(true);
    setRedirectTo(to);
    history.push("/");
  };

  return (
    <>
      {redirect && <Redirect to={redirectTo} />}
      <div className={classes.root}>
        <div className={classes.header}>
          <Typography
            variant="h1"
            component="h2"
            color="primary"
            style={{ width: "100%", margin: "0em" }}
          >
            {t("Welcome")}
          </Typography>
          <Typography
            variant="body1"
            component="p"
            style={{ maxWidth: "500px", margin: "1em 0" }}
          >
            {t("ElearningDesc")}
          </Typography>
          <Typography
            variant="body2"
            component="p"
            style={{ maxWidth: "500px", margin: "0", opacity: ".6" }}
          >
            {t("ElearningMention")}
          </Typography>
        </div>

        <div className={classes.container}>
          <div className={classes.courses} style={{}}>
            <Typography
              variant="h4"
              component="h3"
              color="primary"
              className={classes.subtitle}
            >
              <TalentIcon
                icon="book-open"
                className={classes.courseIcon}
              ></TalentIcon>
              {t("CurriculumVitae")}
            </Typography>
            <div
              className={classes.courseContainer}
              onClick={() => prepareRedirectionTo("/courses/cv/format")}
            >
              {t("CourseNameCVFormat")}
            </div>
            <div
              className={classes.courseContainer}
              onClick={() => prepareRedirectionTo("/courses/cv/body")}
            >
              {t("CourseNameCVBody")}
            </div>
            <div
              className={classes.courseContainer}
              onClick={() => prepareRedirectionTo("/courses/cv/digital")}
            >
              {t("CourseNameCVDigital")}
            </div>
          </div>
          <div className={classes.courses}>
            <Typography
              variant="h4"
              component="h3"
              color="primary"
              className={classes.subtitle}
            >
              <TalentIcon
                icon="file-text"
                className={classes.courseIcon}
              ></TalentIcon>
              {t("CoverLetter")}
            </Typography>
            <div
              className={classes.courseContainer}
              onClick={() => prepareRedirectionTo("/courses/cl/format")}
            >
              {t("CourseNameCLFormat")}
            </div>
            <div
              className={classes.courseContainer}
              onClick={() => prepareRedirectionTo("/courses/cl/body")}
            >
              {t("CourseNameCLBody")}
            </div>
          </div>
          <div className={classes.courses}>
            <Typography
              variant="h4"
              component="h3"
              color="primary"
              className={classes.subtitle}
            >
              <TalentIcon
                icon="users"
                className={classes.courseIcon}
              ></TalentIcon>
              {t("CourseNameComm")}
            </Typography>
            <div
              className={classes.courseContainer}
              onClick={() => prepareRedirectionTo("/courses/comm/non-verbal")}
            >
              {t("CourseNameCommNonVerbal")}
            </div>
            <div
              className={classes.courseContainer}
              onClick={() => prepareRedirectionTo("/courses/comm/verbal")}
            >
              {t("CourseNameCommVerbal")}
            </div>
          </div>
          <div className={classes.courses}>
            <Typography
              variant="h4"
              component="h3"
              color="primary"
              className={classes.subtitle}
            >
              <TalentIcon
                icon="more-horizontal"
                className={classes.courseIcon}
              ></TalentIcon>
              {t("CourseNameOther")}
            </Typography>
            <div
              className={classes.courseContainer}
              onClick={() => prepareRedirectionTo("/courses/other/glossary")}
            >
              {t("CourseNameGlossary")}
            </div>
            <div
              className={classes.courseContainer}
              onClick={() => prepareRedirectionTo("/courses/other/interview")}
            >
              {t("CourseNameInterview")}
            </div>
            <div
              className={classes.courseContainer}
              onClick={() => prepareRedirectionTo("/courses/other/known-how")}
            >
              {t("CourseNameKnownHow")}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ELearning;
