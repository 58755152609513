import { createMuiTheme } from "@material-ui/core";

const talentTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#062d84",
    },
    secondary: {
      main: "#dc5145",
    },
    text: {
      secondary: "#858ca0",
      disabled: "white",
    },
  },
  typography: {
    h1: {
      fontSize: "3em",
      fontFamily: "EB Garamond",
      fontWeight: "600",
    },
    h2: {
      fontSize: "2.5em",
      fontFamily: "EB Garamond",
    },
    h3: {
      fontSize: "2.30em",
      fontFamily: "EB Garamond",
    },
    h4: {
      fontSize: "1.70em",
      fontFamily: "EB Garamond",
    },
    h5: {
      fontSize: "2.5em",
      fontFamily: "EB Garamond",
    },
    h6: {
      fontSize: "2.5em",
      fontFamily: "EB Garamond",
    },
    body1: {
      fontSize: "1em",
      lineHeight: "24px",
      fontWeight: 400,
    },
    body2: {
      fontSize: "0.86em",
    },
    subtitle1: {
      marginBottom: "0",
      fontSize: "1.25em",
      fontFamily: 'Open Sans Condensed',
      
    },
    subtitle2: {
      fontSize: "1em",

    },
  },
});

export default talentTheme;
