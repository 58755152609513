import React from "react";
import { useTranslation } from "react-i18next";
import TalentButton from "../core/TalentButton";
import NotificationCard from "./NotificationCard";
import { Box, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";

const NotificationList = (props) => {
  const { t } = useTranslation();
  return (
    <div>
      {props.notifications &&
        props.notifications.map((item) => (
          <NotificationCard
            key={item.id}
            content={item.content}
            date={item.date_create}
            percent={item.percent}
            notifId={item.id}
          />
        ))}

      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        style={{ textAlign: "center" }}
      >
        {!props.notifications && (
          <Box margin="1rem 0">{t("NoNotifications")}</Box>
        )}
        <Box margin="1rem 0">
          <Link to="/messages" style={{ textDecoration: "none" }}>
            <TalentButton>{t("ViewAllMessages")}</TalentButton>
          </Link>
        </Box>
      </Grid>
    </div>
  );
};

export default NotificationList;
