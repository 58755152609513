import React, { useState, useEffect } from "react";
import { useAuth } from "../../context/auth-context";
import projectService from "../../services/project.service";
import { makeStyles, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyle = makeStyles({
  root: {
    fontFamily: "OpenSans",
    fontStyle: "italic",
    letterSpacing: 0,
    textAlign: "center",
    textDecoration: "underline",
  },
});

const TalentCommercialName = ({ withLink }) => {
  // Load style
  const classes = useStyle();
  // Load auth
  const auth = useAuth();
  // Local state
  const [tdcontact, setTdcontact] = useState(null);

  // Component mount
  useEffect(() => {
    // Load the report
    projectService
      .getTDContact(auth.user)
      .then((res) => {
        setTdcontact(res);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [auth]);

  return withLink ? (
    <Link to="/messages" style={{ textDecoration: "none" }}>
      <Typography
        variant="subtitle1"
        color="secondary"
        className={classes.root}
      >
        {tdcontact && tdcontact.fullname}
      </Typography>
    </Link>
  ) : (
    <Typography variant="subtitle1" color="secondary" className={classes.root}>
      {tdcontact && tdcontact.fullname}
    </Typography>
  );
};

export default TalentCommercialName;
