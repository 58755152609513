import { makeStyles } from "@material-ui/core";
import React, { useState, useEffect } from "react";

const useStyle = makeStyles({
  messageContainer: {
    display: "flex",
    whiteSpace: "normal",
    wordBreak: "break-word",
  },
  receivedMessageContainer: {
    justifyContent: "flex-start",
    marginRight: "20vw",
  },
  sentMessageContainer: {
    justifyContent: "flex-end",
    marginLeft: "20vw",
  },
  autoMessageContainer: {
    justifyContent: "flex-end",
  },
  messageItem: {
    padding: "1rem",
    borderRadius: "10px",
    maxWidth: "100%",
  },
  receivedMessageItem: {
    margin: "0.25vh 0 0.25vh 1vw",
    backgroundColor: "rgba(34, 71, 171, 0.1)",
    color: "#2247ab",
  },
  sentMessageItem: {
    margin: "0.25vh 1vw 0.25vh 0",
    backgroundColor: "#2247ab",
    color: "white",
  },
  autoMessageItem: {
    margin: "1vh 10vw 1vh 10vw",
    backgroundColor: "white",
    border: "#dc5145 solid 1px",
    borderRadius: "5px",
    width: "100%",
    color: "#dc5145",
  },
});

const MessageCard = (props) => {
  // Define styles
  const classes = useStyle();

  // Message type
  const [sentMessage, setSentMessage] = useState(true);
  const [receivedMessageAuto, setReceivedMessageAuto] = useState(false);

  // Change the type of message
  useEffect(() => {
    if (props.message.code === "AC_MSG_SEND") {
      setSentMessage(false);
    } else if (props.message.code === "AC_MSG_AUTO") {
      setSentMessage(false);
      setReceivedMessageAuto(true);
    }
  }, [props]);

  return (
    <div
      className={`${classes.messageContainer} ${
        sentMessage
          ? classes.sentMessageContainer
          : receivedMessageAuto
          ? classes.autoMessageContainer
          : classes.receivedMessageContainer
      }`}
    >
      <div
        className={`${classes.messageItem} ${
          sentMessage
            ? classes.sentMessageItem
            : receivedMessageAuto
            ? classes.autoMessageItem
            : classes.receivedMessageItem
        }`}
      >
        <div dangerouslySetInnerHTML={{ __html: props.message.content }} />
      </div>
    </div>
  );
};

export default MessageCard;
