import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyle = makeStyles({
  root: {
    display: "flex",
    margin: "0 0 2em",
    width: "100%",
    justifyContent: "space-around",
    alignItems: "center",
    flexGrow: "0",
    height: "auto",
    flexWrap: "wrap",
  },
});

const TalentHeaderContainer = ({ children }) => {
  const classes = useStyle();
  return <div className={classes.root}>{children}</div>;
};

export default TalentHeaderContainer;
