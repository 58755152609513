import React, { useState } from "react";
import TalentButton from "../core/TalentButton";
import TalentStepper from "../core/TalentStepper";
import { useTranslation } from "react-i18next";
import { Typography, TextField, Switch } from "@material-ui/core";
import TalentHeaderContainer from "../core/layout/TalentHeaderContainer";
import TalentContentContainer from "../core/layout/TalentContentContainer";
import TalentFooterContainer from "../core/layout/TalentFooterContainer";
import TalentWhiteContainer from "../core/layout/TalentWhiteContainer";
import {
  capitalize,
  validateYear,
  isNumeric,
} from "../../services/validation.utils";

const RegisterStep2 = (props) => {
  const { t } = useTranslation();

  const { values, handlers } = props;

  // Errors
  const [fieldsError, setFieldsError] = useState({
    formTitle: false,
    formYear: false,
    formCountry: false,
    formDuration: false,
    formDetail: false,
  });

  // Errors message
  const [fieldsErrorMsg, setFieldsErrorMsg] = useState({
    formTitle: "",
    formYear: "",
    formCountry: "",
    formDuration: "",
    formDetail: "",
  });

  // Perform values check before going to the next step
  const handleNext = () => {
    let errorDetected = false;
    let localErrors = {};

    // Verify all this required values
    const step1Fields = [
      "formTitle",
      "formYear",
      "formCountry",
      "formDuration",
      "formDetail",
    ];
    step1Fields.forEach((e) => {
      if (values[e] === "") {
        localErrors[e] = true;
        errorDetected = true;
        fieldsErrorMsg[e] = t("RequiredField");
        setFieldsErrorMsg(fieldsErrorMsg);
      } else if (fieldsError[e]) {
        localErrors[e] = true;
        errorDetected = true;
      }
    });

    // Set the error state
    setFieldsError(localErrors);

    // If no error, we go to the next step
    if (!errorDetected) {
      props.nextStep();
    }
  };

  return (
    <TalentWhiteContainer>
      <TalentHeaderContainer>
        <div className="LeftCenterTxt" style={{ flex: 1 }}>
          <Typography
            variant="subtitle1"
            component="span"
            color="textSecondary"
          >
            {t("CreateAccount")}
          </Typography>
          <Typography variant="h1" component="h1" color="primary">
            {t("YourProject")}
          </Typography>
        </div>
        <TalentStepper
          activeStep={2}
          stepsTotal={props.stepsTotal}
        ></TalentStepper>
      </TalentHeaderContainer>

      <TalentContentContainer>
        <TextField
          label={t("FormationTitle")}
          onChange={(e) => {
            fieldsError.formTitle = false;
            setFieldsError(fieldsError);
            handlers.setFormTitle(capitalize(e.target.value));
          }}
          value={values.formTitle}
          required
          fullWidth
          error={fieldsError.formTitle}
          helperText={fieldsError.formTitle ? fieldsErrorMsg.formTitle : null}
          data-testid="register-input-formation-title"
        />
        <TextField
          label={t("FormationYear")}
          onChange={(e) => {
            const value = e.target.value;
            // We only accept numerical values
            if (isNumeric(value)) {
              // We only accept valide year
              if (validateYear(value)) {
                fieldsError.formYear = false;
                setFieldsError(fieldsError);
                fieldsErrorMsg.formYear = "";
                setFieldsErrorMsg(fieldsErrorMsg);
                handlers.setFormYear(value);
              } else {
                fieldsError.formYear = true;
                setFieldsError(fieldsError);
                fieldsErrorMsg.formYear = t("InvalidYear");
                setFieldsErrorMsg(fieldsErrorMsg);
                handlers.setFormYear(value);
              }
            }
          }}
          value={values.formYear}
          required
          fullWidth
          error={fieldsError.formYear}
          helperText={fieldsError.formYear ? fieldsErrorMsg.formYear : null}
          data-testid="register-input-formation-year"
        />
        <TextField
          label={t("FormationCountry")}
          onChange={(e) => {
            fieldsError.formCountry = false;
            setFieldsError(fieldsError);
            handlers.setFormCountry(capitalize(e.target.value));
          }}
          value={values.formCountry}
          required
          fullWidth
          error={fieldsError.formCountry}
          helperText={
            fieldsError.formCountry ? fieldsErrorMsg.formCountry : null
          }
          data-testid="register-input-formation-country"
        />
        <div
          className="MuiFormControl-root MuiTextField-root MuiFormControl-fullWidth"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span>{t("Internship")}</span>
          <Switch
            checked={values.formType}
            onChange={(e) => {
              handlers.setFormType(e.target.checked);
            }}
            name={t("FormationType")}
            data-testid="register-chkbx-formation-type"
          />
          <span>{t("Apprenticeship")}</span>
        </div>
        <TextField
          label={t("FormationDuration")}
          onChange={(e) => {
            const value = e.target.value;
            // We only accept numerical values
            if (isNumeric(value)) {
              fieldsError.formDuration = false;
              setFieldsError(fieldsError);
              handlers.setFormDuration(value);
            }
          }}
          value={values.formDuration}
          required
          fullWidth
          error={fieldsError.formDuration}
          helperText={
            fieldsError.formDuration ? fieldsErrorMsg.formDuration : null
          }
          data-testid="register-input-formation-duration"
        />
        <TextField
          label={t("FormationDetail")}
          onChange={(e) => {
            fieldsError.formDetail = false;
            setFieldsError(fieldsError);
            handlers.setFormDetail(capitalize(e.target.value));
          }}
          value={values.formDetail}
          required
          fullWidth
          multiline
          rows={4}
          variant="outlined"
          error={fieldsError.formDetail}
          helperText={fieldsError.formDetail ? fieldsErrorMsg.formDetail : null}
          data-testid="register-input-formation-detail"
        />
      </TalentContentContainer>

      <TalentFooterContainer>
        <TalentButton onClick={() => props.prevStep()}>
          {t("Previous")}
        </TalentButton>
        <TalentButton
          withIcon={true}
          onClick={handleNext}
          data-testid="register-btn-next"
        >
          {t("Continue")}
        </TalentButton>
      </TalentFooterContainer>
    </TalentWhiteContainer>
  );
};

export default RegisterStep2;
