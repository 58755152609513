import React, { useState, useEffect } from "react";
import TalentPdfViewer from "../core/TalentPdfViewer";
import CLFormatEn from "../../assets/documents/en_US/CL_FORMAT.pdf";
import CLBodyEn from "../../assets/documents/en_US/CL_BODY.pdf";
import CVFormatEn from "../../assets/documents/en_US/CV_FORMAT.pdf";
import CVBodyEn from "../../assets/documents/en_US/CV_BODY.pdf";
import CVDigitalEn from "../../assets/documents/en_US/CV_DIGITAL.pdf";
import CommNonVerbalEn from "../../assets/documents/en_US/COMM_NON_VERBAL.pdf";
import CommVerbalEn from "../../assets/documents/en_US/COMM_VERBAL.pdf";
import GlossaryEn from "../../assets/documents/en_US/GLOSSARY.pdf";
import InterviewEn from "../../assets/documents/en_US/INTERVIEW.pdf";
import KnownHowEn from "../../assets/documents/en_US/KNOWN_HOW.pdf";
import CLFormatFr from "../../assets/documents/fr_FR/CL_FORMAT.pdf";
import CLBodyFr from "../../assets/documents/fr_FR/CL_BODY.pdf";
import CVFormatFr from "../../assets/documents/fr_FR/CV_FORMAT.pdf";
import CVBodyFr from "../../assets/documents/fr_FR/CV_BODY.pdf";
import CVDigitalFr from "../../assets/documents/fr_FR/CV_DIGITAL.pdf";
import CommNonVerbalFr from "../../assets/documents/fr_FR/COMM_NON_VERBAL.pdf";
import CommVerbalFr from "../../assets/documents/fr_FR/COMM_VERBAL.pdf";
import GlossaryFr from "../../assets/documents/fr_FR/GLOSSARY.pdf";
import InterviewFr from "../../assets/documents/fr_FR/INTERVIEW.pdf";
import KnownHowFr from "../../assets/documents/fr_FR/KNOWN_HOW.pdf";
import CLFormatEs from "../../assets/documents/es_ES/CL_FORMAT.pdf";
import CLBodyEs from "../../assets/documents/es_ES/CL_BODY.pdf";
import CVFormatEs from "../../assets/documents/es_ES/CV_FORMAT.pdf";
import CVBodyEs from "../../assets/documents/es_ES/CV_BODY.pdf";
import CVDigitalEs from "../../assets/documents/es_ES/CV_DIGITAL.pdf";
import CommNonVerbalEs from "../../assets/documents/es_ES/COMM_NON_VERBAL.pdf";
import CommVerbalEs from "../../assets/documents/es_ES/COMM_VERBAL.pdf";
import GlossaryEs from "../../assets/documents/es_ES/GLOSSARY.pdf";
import InterviewEs from "../../assets/documents/es_ES/INTERVIEW.pdf";
import KnownHowEs from "../../assets/documents/es_ES/KNOWN_HOW.pdf";
import TalentGoBackButton from "../core/TalentGoBackButton";
import { useTranslation } from "react-i18next";
import TalentLoader from "../core/TalentLoader";

// Map document and languages
const documents = {
  en_US: {
    CV_FORMAT: CVFormatEn,
    CV_BODY: CVBodyEn,
    CV_DIGITAL: CVDigitalEn,
    CL_FORMAT: CLFormatEn,
    CL_BODY: CLBodyEn,
    COMM_NON_VERBAL: CommNonVerbalEn,
    COMM_VERBAL: CommVerbalEn,
    GLOSSARY: GlossaryEn,
    INTERVIEW: InterviewEn,
    KNOWN_HOW: KnownHowEn,
  },
  es_ES: {
    CV_FORMAT: CVFormatEs,
    CV_BODY: CVBodyEs,
    CV_DIGITAL: CVDigitalEs,
    CL_FORMAT: CLFormatEs,
    CL_BODY: CLBodyEs,
    COMM_NON_VERBAL: CommNonVerbalEs,
    COMM_VERBAL: CommVerbalEs,
    GLOSSARY: GlossaryEs,
    INTERVIEW: InterviewEs,
    KNOWN_HOW: KnownHowEs,
  },
  fr_FR: {
    CV_FORMAT: CVFormatFr,
    CV_BODY: CVBodyFr,
    CV_DIGITAL: CVDigitalFr,
    CL_FORMAT: CLFormatFr,
    CL_BODY: CLBodyFr,
    COMM_NON_VERBAL: CommNonVerbalFr,
    COMM_VERBAL: CommVerbalFr,
    GLOSSARY: GlossaryFr,
    INTERVIEW: InterviewFr,
    KNOWN_HOW: KnownHowFr,
  },
};

const ELearningCourse = ({ title, documentName }) => {
  // Load langs
  const { i18n } = useTranslation();

  const [loading, isLoading] = useState(true);
  const [document, setDocument] = useState(null);

  // Load the correct document
  useEffect(() => {
    let documentLanguageFolder;
    switch (i18n.language) {
      case "fr-FR":
        documentLanguageFolder = "fr_FR";
        break;
      case "es-ES":
        documentLanguageFolder = "es_ES";
        break;
      default:
        documentLanguageFolder = "en_US";
        break;
    }
    const documentPath = documents[documentLanguageFolder][documentName];
    isLoading(false);
    setDocument(documentPath);
  }, [i18n, documentName]);

  return (
    <>
      <TalentGoBackButton />
      {loading ? <TalentLoader /> : <TalentPdfViewer pdfFile={document} />}
    </>
  );
};

export default ELearningCourse;
