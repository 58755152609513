import React, { useState } from "react";
import TalentWhiteContainer from "../core/layout/TalentWhiteContainer";
import TalentButton from "../core/TalentButton";
import TalentGoBackButton from "../core/TalentGoBackButton";
import { Typography, TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { validateEmail } from "../../services/validation.utils";
import TalentLoader from "../core/TalentLoader";
import authService from "../../services/auth.service";
import { useAlert } from "react-alert";

const ForgotPassword = () => {
  const { t } = useTranslation();
  const alert = useAlert();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState("");
  const [apiError, setApiError] = useState(false);

  const handleClick = () => {
    setLoading(true);
    authService
      .sendResetPassLink(email)
      .then((res) => {
        setLoading(false);
        alert.show(t("ResetPasswordEmailSent"), { type: "success" });
      })
      .catch((err) => {
        setLoading(false);
        setApiError(true);
        let errormsg = "";
        switch (err.response.status) {
          case 404:
            errormsg = t("EmailNotFound");
            break;
          default:
            errormsg = t("ResetPasswordEmailNotSent");
            break;
        }
        setApiErrorMessage(errormsg);
        console.error(err);
        alert.show(errormsg, { type: "error" });
      });
  };

  return (
    <TalentWhiteContainer
      style={{
        color: "white",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <TalentGoBackButton />
      <Typography variant="h2" component="h2" color="primary">
        {t("ForgotPassword")}
      </Typography>
      <Typography
        variant="subtitle2"
        component="p"
        color="textSecondary"
        style={{ marginTop: "1em" }}
      >
        {t("ForgotPasswordDesc")}
      </Typography>
      <TextField
        label={t("Email")}
        onChange={(e) => {
          const value = e.target.value;
          if (validateEmail(value)) {
            setEmailErrorMsg("");
            setEmailError(false);
            setEmail(value);
          } else {
            setEmailErrorMsg(t("InvalidEmail"));
            setEmailError(true);
            setEmail(value);
          }
          setApiError(false);
          setApiErrorMessage("");
        }}
        defaultValue={email}
        required
        fullWidth
        error={emailError || apiError}
        helperText={emailErrorMsg ? emailErrorMsg : apiErrorMessage}
      />

      {loading && <TalentLoader />}

      <TalentButton
        variant="contained"
        onClick={handleClick}
        disabled={loading || emailError || email === ""}
      >
        {t("Send")}
      </TalentButton>
    </TalentWhiteContainer>
  );
};

export default ForgotPassword;
