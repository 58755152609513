import React from "react";

const CGVContentEN = () => {
  return (
    <>
      <h2>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: "Calibri,sans-serif" }}>
            <span style={{ fontSize: "27.0pt" }}>
              <span style={{ fontFamily: '"EB Garamond"' }}>
                <span style={{ color: "#0b1941" }}>
                  Term and Conditions and Legal Agreement between:
                </span>
              </span>
            </span>
          </span>
        </span>
      </h2>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <strong>
            <span style={{ fontSize: "10.5pt" }}>
              <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                <span style={{ color: "#0b1941" }}>The company:</span>
              </span>
            </span>
          </strong>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                &nbsp;TALENT DEVELOPER&nbsp;with registered office at 36 rue du
                Mont Thabor 75001 Paris, registration number 889 114 328 00012
                R.C.S. Paris.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>TALENT DEVELOPER</span>
            </span>
          </span>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                &nbsp;shall also be referred to as T.D.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <strong>
            <span style={{ fontSize: "10.5pt" }}>
              <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                <span style={{ color: "#0b1941" }}>The client:</span>
              </span>
            </span>
          </strong>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                &nbsp;A natural person or legal entity, individual or collective
                who uses the services or advice of the&nbsp;TALENT
                DEVELOPER&nbsp;company. The client may be: an educational
                institution, a student, the student’s legal representatives,
                etc.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <strong>
            <span style={{ fontSize: "10.5pt" }}>
              <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                <span style={{ color: "#0b1941" }}>
                  The clients shall be referred to as:
                </span>
              </span>
            </span>
          </strong>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                &nbsp;school, university, educational establishment, intern’s
                school, Alternative Training Centre (Centre de Formation en
                Alternance, CFA) (or any other name given to the training
                establishment), intern, apprentice and/or student.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                The student is 18 years or older and confirms that he/she has
                the full capacity to be take responsibility for his/her actions.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                The general conditions established by&nbsp;TALENT
                DEVELOPER&nbsp;are intended to define the contractual
                obligations established between the client and&nbsp;TALENT
                DEVELOPER, as well as the conditions of sale. This document will
                be available at all times on the T.D. website.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                We ask you to read this document carefully and then confirm that
                you have read it by agreeing to said contract.
              </span>
            </span>
          </span>
        </span>
      </span>
      <h2>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: "Calibri,sans-serif" }}>
            <span style={{ fontSize: "27.0pt" }}>
              <span style={{ fontFamily: '"EB Garamond"' }}>
                <span style={{ color: "#0b1941" }}>
                  TALENT DEVELOPER Terms and Conditions:
                </span>
              </span>
            </span>
          </span>
        </span>
      </h2>
      <h3>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: "Calibri,sans-serif" }}>
            <span style={{ fontSize: "16.5pt" }}>
              <span style={{ fontFamily: '"EB Garamond"' }}>
                <span style={{ color: "#0b1941" }}>General&nbsp;:</span>
              </span>
            </span>
          </span>
        </span>
      </h3>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                The general conditions established by Talent Developer are
                intended to define the contractual obligations established
                between the client and T.D., as well as the conditions of sale.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                The general conditions are available through your client account
                on the website and can also be requested at any time.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Please read these terms and conditions carefully.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                All services, offers and/or reservations at T.D. are subject to
                these general conditions. Carrying out an internship or an
                apprenticeship contract at a company, or any other service
                requested from T.D. implies agreement with the following general
                conditions.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                All T.D. clients are required to act in accordance with the
                rules and regulations established by this legal agreement.
              </span>
            </span>
          </span>
        </span>
      </span>
      <h3>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: "Calibri,sans-serif" }}>
            <span style={{ fontSize: "16.5pt" }}>
              <span style={{ fontFamily: '"EB Garamond"' }}>
                <span style={{ color: "#0b1941" }}>Definitions&nbsp;:</span>
              </span>
            </span>
          </span>
        </span>
      </h3>
      <h4>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: "Calibri,sans-serif" }}>
            <u>
              <span style={{ fontSize: "13.5pt" }}>
                <span style={{ fontFamily: '"EB Garamond"' }}>
                  <span style={{ color: "#0b1941" }}>
                    Host Organisation or Host Company :
                  </span>
                </span>
              </span>
            </u>
          </span>
        </span>
      </h4>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                The host organisation or host company refers to any legal entity
                where the client carries out his/her period at the company. The
                host organisation can be a company, a Governmental Organisation
                (GO), a Non-Governmental Organisation (NGO), or any organisation
                with a legal status authorising it to host the client.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                These will always be establishments within the scope of all
                areas of expertise related to the hospitality, food service,
                and/or culinary arts and all departments therein (operations,
                business, client relations, marketing, HR, finance, etc.).
              </span>
            </span>
          </span>
        </span>
      </span>
      <h4>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: "Calibri,sans-serif" }}>
            <u>
              <span style={{ fontSize: "13.5pt" }}>
                <span style={{ fontFamily: '"EB Garamond"' }}>
                  <span style={{ color: "#0b1941" }}>
                    Period at Company: Internship or Apprenticeship Contract at
                    Company :
                  </span>
                </span>
              </span>
            </u>
          </span>
        </span>
      </h4>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                The period at a company refers to a practical apprenticeship
                experience in which the host organisation and the student
                participate. The internship or apprenticeship contract at a
                company must be an integral part of the training program and a
                mandatory component of the student’s educational curriculum for
                the current year.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                The period at a company lasts from 2 to 6 months for the
                internship, shall be annual for the apprenticeship contract, and
                is carried out in the fields of hospitality, food service,
                culinary arts or any other department related therewith,
                including: business, support services, administrative services,
                etc.).
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                The main objective of the period at a company is to allow
                students to have an experience in a professional context, to
                develop their interpersonal skills and know-how, to practice the
                techniques acquired in theoretical lessons, to communicate in
                French, and to integrate and develop a professional network.
              </span>
            </span>
          </span>
        </span>
      </span>
      <h4>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: "Calibri,sans-serif" }}>
            <u>
              <span style={{ fontSize: "13.5pt" }}>
                <span style={{ fontFamily: '"EB Garamond"' }}>
                  <span style={{ color: "#0b1941" }}>
                    Internship Agreement :
                  </span>
                </span>
              </span>
            </u>
          </span>
        </span>
      </h4>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                The internship agreement refers to the tripartite contract
                established between: the host company (and not by T.D.), the
                intern’s school, and the intern.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                The internship is not governed by an employment contract. The
                intern is not an employee of the company and cannot act as
                substitute for such.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                In France, an internship does not imply remuneration but
                compensation may be provided. This compensation becomes
                automatic and compulsory once the internship exceeds 2 months.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                The internship is governed by the following legal texts:
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Intern status reform by Law No. 2014 -788 of 10 July 2014.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>Reference texts:</span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                – &nbsp;Law No. 2013-660 of 22 July 2013 on higher education and
                research
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                – &nbsp;Law No. 2014-788 of 10 July 2014
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                – &nbsp;Decree No. 2014-1420 of 27 November 2014 relating to the
                supervision of training periods in a professional environment
                and internships.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                – Order of 29 December 2014 relating to internship agreements in
                higher education.
              </span>
            </span>
          </span>
        </span>
      </span>
      <h4>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: "Calibri,sans-serif" }}>
            <u>
              <span style={{ fontSize: "13.5pt" }}>
                <span style={{ fontFamily: '"EB Garamond"' }}>
                  <span style={{ color: "#0b1941" }}>
                    Apprenticeship Contract :
                  </span>
                </span>
              </span>
            </u>
          </span>
        </span>
      </h4>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                The apprenticeship contract refers to the employment contract
                executed between a host company (and not by T.D.) and an
                employee who is also undergoing apprenticeship training. It is
                governed by the labour code and by collective agreement with the
                branch of the host company. It is signed by the host company
                (and not by T.D.) and by the student, subject to verification
                from his/her training centre.
              </span>
            </span>
          </span>
        </span>
      </span>
      <h4>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: "Calibri,sans-serif" }}>
            <u>
              <span style={{ fontSize: "13.5pt" }}>
                <span style={{ fontFamily: '"EB Garamond"' }}>
                  <span style={{ color: "#0b1941" }}>Company Offer :</span>
                </span>
              </span>
            </u>
          </span>
        </span>
      </h4>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                The company offer refers to the job description/job posting for
                which a host company is looking for candidates. It includes the
                description of the tasks and duties for the position as well as
                the skills and qualifications required by the host company to
                fill the position.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                The company offer will be recommended to the student, depending
                on his/her skills, the level of French required by the host
                company (which shall be at least B1 according to European
                standards), the course of the student’s educational training,
                and the student’s professional plan. T.D’s objective is to adapt
                the company offer to the student’s plan and profile.
              </span>
            </span>
          </span>
        </span>
      </span>
      <h4>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: "Calibri,sans-serif" }}>
            <u>
              <span style={{ fontSize: "13.5pt" }}>
                <span style={{ fontFamily: '"EB Garamond"' }}>
                  <span style={{ color: "#0b1941" }}>
                    Description of Company Package :
                  </span>
                </span>
              </span>
            </u>
          </span>
        </span>
      </h4>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                The Company Package refers to the various services provided by
                T.D. and/or its partners. T.D. acts as an intermediary between
                the host company and the client.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                The package, also known as Service Provision, is divided into
                three distinct periods: before, during, and after the period at
                the company.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                T.D. offers 3 packages, available in all of its marketing
                materials:
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>Future Talent Package</span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Future Talent Premium Package
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Future Talent Premium Plus Package
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <strong>
            <em>
              <span style={{ fontSize: "10.5pt" }}>
                <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                  <span style={{ color: "#0b1941" }}>
                    « Futur Talent Package
                  </span>
                </span>
              </span>
            </em>
          </strong>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <em>
            <span style={{ fontSize: "10.5pt" }}>
              <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                <span style={{ color: "#0b1941" }}>
                  For whom: French students looking for an internship or an
                  apprenticeship
                </span>
              </span>
            </span>
          </em>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <em>
            <u>
              <span style={{ fontSize: "10.5pt" }}>
                <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                  <span style={{ color: "#0b1941" }}>
                    To support you before your internship or apprenticeship
                    contract
                  </span>
                </span>
              </span>
            </u>
          </em>
        </span>
      </span>
      <ol>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      First interview, with no obligation on your part, in order
                      to: Understand your expectations, your personality, your
                      work skills and, most importantly, your professional plan.
                      At the end of this meeting, we offer you a career plan to
                      best achieve your goals.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      To continue with the process, you must: Complete your
                      online profile, fill out and mail the requested documents,
                      and pay the deposit for the selected package.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Examination of your training program in detail in order
                      to: Understand the skills that you will develop during the
                      period at the company, then we contact the person in
                      charge of your training, if necessary.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Exclusive educational content: Access to the online course
                      catalogue to help you create your CV, cover letter, and
                      develop your knowledge of professional culture.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Personality test: To be completed on your personal account
                      to help us identify the company and mentor profile that
                      suit you best.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Application to company: – Examination and correction of
                      your CV and cover letter so that they perfectly match the
                      recruiters’ expectations – We select the company from our
                      network that best suits the needs we have identified for
                      you – We submit your application to the company,
                      highlighting your interpersonal skills and your know-how –
                      The company confirms its interest in your profile, then we
                      ask you to pay the balance of the package – We provide you
                      with the company’s contact information and set up an
                      interview with its recruiter – We help you prepare for the
                      interview by providing you with a list of typical
                      questions, as well as the philosophy and expectations
                      specific to that establishment.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
      </ol>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <em>
            <u>
              <span style={{ fontSize: "10.5pt" }}>
                <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                  <span style={{ color: "#0b1941" }}>
                    To guide you during your internship or apprenticeship
                    contract:
                  </span>
                </span>
              </span>
            </u>
          </em>
        </span>
      </span>
      <ol>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Your future network: We invite you to a breakfast with all
                      the students who are new during the same period as you.
                      This will allow you to build a network of future
                      international and French talents and to have an enriching
                      and unforgettable experience.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Company visit: We organise a visit to your host company
                      with your mentor to take stock of your progress at the
                      company.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      We support you throughout your period at the company: We
                      contact you at the beginning, in the middle, and at the
                      end to take stock; we remain entirely at your disposal
                      throughout your period at the company.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
      </ol>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <em>
            <u>
              <span style={{ fontSize: "10.5pt" }}>
                <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                  <span style={{ color: "#0b1941" }}>
                    To move forward after your internship or apprenticeship
                    contract
                  </span>
                </span>
              </span>
            </u>
          </em>
        </span>
      </span>
      <ol>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Meeting at the end of your company training: Organised on
                      our premises to debrief you about your experience, we help
                      you synthesise the skills acquired and promote them for
                      your professional future, then we guide you in the
                      development of your career and your future professional
                      goals.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      The TALENT DEVELOPER website: You retain privileged access
                      to your personal profile and to the TALENT DEVELOPER
                      website and, as such, access to our exclusive job offers.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
      </ol>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <strong>
            <em>
              <span style={{ fontSize: "10.5pt" }}>
                <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                  <span style={{ color: "#0b1941" }}>
                    « Futur Talent Premium Package
                  </span>
                </span>
              </span>
            </em>
          </strong>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <em>
            <span style={{ fontSize: "10.5pt" }}>
              <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                <span style={{ color: "#0b1941" }}>
                  For whom:&nbsp;Foreign students with a level of French above
                  B1
                </span>
              </span>
            </span>
          </em>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <em>
            <u>
              <span style={{ fontSize: "10.5pt" }}>
                <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                  <span style={{ color: "#0b1941" }}>
                    To support you before your internship or apprenticeship
                    contract
                  </span>
                </span>
              </span>
            </u>
          </em>
        </span>
      </span>
      <ol>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      First interview, with no obligation on your part, in order
                      to: Understand your expectations, your personality, your
                      work skills and, most importantly, your professional plan.
                      At the end of this meeting, we offer you a career plan to
                      best achieve your goals.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      To continue with the process, you must: Complete your
                      online profile, fill out and mail the requested documents,
                      and pay the deposit for the selected package.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Examination of your training program in detail&nbsp;in
                      order&nbsp;to: Understand the skills that you will develop
                      during the period at the company, then we contact the
                      person in charge of your training, if necessary.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Exclusive educational content: Access to the online course
                      catalogue to help you create your CV, cover letter, and
                      develop your knowledge of professional culture, plus a
                      “Welcome to Paris” booklet to best prepare you for your
                      stay (helpful addresses, useful information, etc.).
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Personality test: To be completed on your personal account
                      to help us identify the company and mentor profile that
                      suit you best.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Application to company: –&nbsp;Examination and correction
                      of your CV and cover letter so that they perfectly match
                      the recruiters’ expectations&nbsp;– We select the company
                      from our network that best suits the needs we have
                      identified for you – We submit your application to the
                      company, highlighting your interpersonal skills and your
                      know-how – The company confirms its interest in your
                      profile, then we ask you to pay the balance of the package
                      – We provide you with the company’s contact information
                      and set up an interview with its recruiter – We help you
                      prepare for the interview by providing you with a list of
                      typical questions, as well as the philosophy and
                      expectations specific to that establishment.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
      </ol>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <em>
            <u>
              <span style={{ fontSize: "10.5pt" }}>
                <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                  <span style={{ color: "#0b1941" }}>
                    To guide you during your internship or apprenticeship
                    contract:
                  </span>
                </span>
              </span>
            </u>
          </em>
        </span>
      </span>
      <ol>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Your future network: We invite you to a breakfast with all
                      the students who are new during the same period as you.
                      This will allow you to build a network of future
                      international and French talents and to have an enriching
                      and unforgettable experience.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Company visit: We organise two visits to your host company
                      with your mentor to take stock of your progress at the
                      company.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Support during your period at the company: We contact you
                      at the beginning, in the middle, and at the end of your
                      period at the company to take stock; we remain entirely at
                      your disposal and a member of the TALENT DEVELOPER staff
                      is always available in case of emergency.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
      </ol>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <em>
            <u>
              <span style={{ fontSize: "10.5pt" }}>
                <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                  <span style={{ color: "#0b1941" }}>
                    To move forward after your internship or apprenticeship
                    contract
                  </span>
                </span>
              </span>
            </u>
          </em>
        </span>
      </span>
      <ol>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Meeting at the end of your company training: Organised on
                      our premises to debrief you about your experience, we help
                      you synthesise the skills acquired and promote them for
                      your professional future, guide you in the development of
                      your career and your future professional goals, then
                      provide you with an internship certificate attesting to
                      your experience in Paris.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      The TALENT DEVELOPER website: You retain privileged access
                      to your personal profile and to the TALENT DEVELOPER
                      website and, as such, access to our exclusive job offers.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
      </ol>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <strong>
            <em>
              <span style={{ fontSize: "10.5pt" }}>
                <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                  <span style={{ color: "#0b1941" }}>
                    « Futur Talent Premium Plus Package
                  </span>
                </span>
              </span>
            </em>
          </strong>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <em>
            <span style={{ fontSize: "10.5pt" }}>
              <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                <span style={{ color: "#0b1941" }}>
                  For whom: Foreign students with a level of French below B1
                </span>
              </span>
            </span>
          </em>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <em>
            <span style={{ fontSize: "10.5pt" }}>
              <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                <span style={{ color: "#0b1941" }}>
                  If you are not too familiar with the language of Molière, our
                  Premium Plus package has been designed for you!
                </span>
              </span>
            </span>
          </em>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <em>
            <u>
              <span style={{ fontSize: "10.5pt" }}>
                <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                  <span style={{ color: "#0b1941" }}>
                    To support you before your internship or apprenticeship
                    contract
                  </span>
                </span>
              </span>
            </u>
          </em>
        </span>
      </span>
      <ol>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      First interview, with no obligation on your part, in order
                      to: Understand your expectations, your personality, your
                      work skills and, most importantly, your professional plan.
                      At the end of this meeting, we offer you a career plan to
                      best achieve your goals.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      To continue with the process, you must: Complete your
                      online profile, fill out and mail the requested documents,
                      and pay the deposit for the selected package.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Examination of your training program in detail&nbsp;in
                      order&nbsp;to: Understand the skills that you will develop
                      during the period at the company, then we contact the
                      person in charge of your training, if necessary.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Exclusive educational content: Access to the online course
                      catalogue to help you create your CV, cover letter, and
                      develop your knowledge of professional culture, plus a
                      “Welcome to Paris” booklet to best prepare you for your
                      stay (helpful addresses, useful information, etc.).
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Personality test: To be completed on your personal account
                      to help us identify the company and mentor profile that
                      suit you best.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Application to company: –&nbsp;Examination and correction
                      of your CV and cover letter so that they perfectly match
                      the recruiters’ expectations&nbsp;– We select the company
                      from our network that best suits the needs we have
                      identified for you – We submit your application to the
                      company, highlighting your interpersonal skills and your
                      know-how – The company confirms its interest in your
                      profile, then we ask you to pay the balance of the package
                      – We provide you with the company’s contact information
                      and set up an interview with its recruiter – We help you
                      prepare for the interview by providing you with a list of
                      typical questions, as well as the philosophy and
                      expectations specific to that establishment.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
      </ol>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <em>
            <u>
              <span style={{ fontSize: "10.5pt" }}>
                <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                  <span style={{ color: "#0b1941" }}>
                    To guide you during your internship or apprenticeship
                    contract:
                  </span>
                </span>
              </span>
            </u>
          </em>
        </span>
      </span>
      <ol>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Your future network: We invite you to a breakfast with all
                      the students who are new during the same period as you.
                      This will allow you to build a network of future
                      international and French talents and to have an enriching
                      and unforgettable experience.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      French lessons: You will take two very intensive weeks of
                      French as a foreign language (FLE) courses in order to
                      reach the level of comprehension and communication
                      necessary to successfully carry out your internship.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Company visit: We organise two visits to your host company
                      with your mentor to take stock of your progress at the
                      company.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Support during your period at the company: We contact you
                      at the beginning, in the middle, and at the end of your
                      period at the company to take stock; we remain entirely at
                      your disposal and a member of the TALENT DEVELOPER staff
                      is always available in case of emergency.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
      </ol>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <em>
            <u>
              <span style={{ fontSize: "10.5pt" }}>
                <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                  <span style={{ color: "#0b1941" }}>
                    To move forward after your internship or apprenticeship
                    contract
                  </span>
                </span>
              </span>
            </u>
          </em>
        </span>
      </span>
      <ol>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      Meeting at the end of your company training: Organised on
                      our premises to debrief you about your experience, we help
                      you synthesise the skills acquired and promote them for
                      your professional future, guide you in the development of
                      your career and your future professional goals, then
                      provide you with an internship certificate attesting to
                      your experience in Paris.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <em>
                  <span style={{ fontSize: "10.5pt" }}>
                    <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                      The TALENT DEVELOPER website: You retain privileged access
                      to your personal profile and to the TALENT DEVELOPER
                      website and, as such, access to our exclusive job offers.
                    </span>
                  </span>
                </em>
              </span>
            </span>
          </span>
        </li>
      </ol>
      <h4>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: "Calibri,sans-serif" }}>
            <u>
              <span style={{ fontSize: "13.5pt" }}>
                <span style={{ fontFamily: '"EB Garamond"' }}>
                  <span style={{ color: "#0b1941" }}>Language school :</span>
                </span>
              </span>
            </u>
          </span>
        </span>
      </h4>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Language schools refer to any accredited language school that
                works with T.D. to provide language courses to the client. T.D.
                has selected a language school that provides intensive courses
                for two weeks and has set very precise specifications so that
                the student can benefit from intensive and relevant training
                during those two weeks.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                T.D. requires a level of French of B1 or equivalent,
                demonstrated with a certificate. Level B1 allows students to
                communicate easily with their contact person. It is essential
                for the student to communicate well with his/her environment,
                his/her mentor, and the host institution’s staff. Communication
                will allow the student to fully understand the mentor’s
                expectations and to maximally develop professional skills during
                this period at the company. He/she will also benefit from the
                professional network in a much more active way by communicating
                in the same language.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                If the student’s level of French is not sufficient to
                communicate fluently, but he/she has basic French, the FLE
                courses are compulsory. Two weeks of intensive courses are
                required of the student upon his/her arrival in Paris and before
                starting his/her period at a company.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                The cost of the course is included in the Future Talent Premium
                Plus Package.
              </span>
            </span>
          </span>
        </span>
      </span>
      <h4>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: "Calibri,sans-serif" }}>
            <u>
              <span style={{ fontSize: "13.5pt" }}>
                <span style={{ fontFamily: '"EB Garamond"' }}>
                  <span style={{ color: "#0b1941" }}>
                    Partner or Service Company :
                  </span>
                </span>
              </span>
            </u>
          </span>
        </span>
      </h4>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                The Partner refers to any company, organisation, natural person,
                or legal entity employed or used by T.D. for its services. The
                objective is to help the student with his/her arrival and
                integration in France and to facilitate his/her daily life
                beyond the host company. T.D. is not responsible for these
                services, conditions, or prices. The services offered are:
                apartment rental agency, airport transport, insurance policy
                subscription, housekeeping/cleaning service company, SIM card,
                internet subscription, transport pass, cuisine courses, pastry
                courses, etc.
              </span>
            </span>
          </span>
        </span>
      </span>
      <h4>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: "Calibri,sans-serif" }}>
            <u>
              <span style={{ fontSize: "13.5pt" }}>
                <span style={{ fontFamily: '"EB Garamond"' }}>
                  <span style={{ color: "#0b1941" }}>Mentor :</span>
                </span>
              </span>
            </u>
          </span>
        </span>
      </h4>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                The mentor refers to any natural person appointed within the
                host company who will monitor and supervise the student
                throughout his/her period at the company. The mentor will assign
                tasks and duties to the student and provide him/her with
                support, explain techniques, and give him/her regular feedback
                on his/her work in order to allow him/her to develop and acquire
                skills.
              </span>
            </span>
          </span>
        </span>
      </span>
      <h3>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: "Calibri,sans-serif" }}>
            <span style={{ fontSize: "16.5pt" }}>
              <span style={{ fontFamily: '"EB Garamond"' }}>
                <span style={{ color: "#0b1941" }}>Agreement :</span>
              </span>
            </span>
          </span>
        </span>
      </h3>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                The client acknowledges that he/she has read, understood, and
                agreed to the terms and conditions.
              </span>
            </span>
          </span>
        </span>
      </span>
      <h4>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: "Calibri,sans-serif" }}>
            <span style={{ fontSize: "16.5pt" }}>
              <span style={{ fontFamily: '"EB Garamond"' }}>
                <span style={{ color: "#0b1941" }}>Articles</span>
              </span>
            </span>
          </span>
        </span>
      </h4>
      <h5>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: "Calibri,sans-serif" }}>
            <u>
              <span style={{ fontSize: "13.5pt" }}>
                <span style={{ fontFamily: '"EB Garamond"' }}>
                  <span style={{ color: "#0b1941" }}>
                    1. Client registration
                  </span>
                </span>
              </span>
            </u>
          </span>
        </span>
      </h5>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                1.1. Free registration with no obligation to purchase via the
                T.D. website or the school.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                1.2. The student must provide his/her CV and a cover letter in
                an editable format (Word) in French or English and follow the
                steps listed on his/her client account on the website.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                1.3. The client’s level of French must be sufficient to
                communicate during his/her period at a company in France. The
                client must provide proof valid within the last 2 years
                demonstrating his/her level of French (DELF B1 or DALF, TCF, TFI
                or TEF level B1). Failing this, the client must purchase the
                option that includes compulsory French language courses in order
                to reach level B1. T.D. cannot guarantee the internship if the
                client does not reach the required level of French, even if the
                client has purchased the option that includes FLE courses.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                1.4. The client undertakes to provide all the information
                necessary for T.D. to carry out its duties and to communicate
                any information that may affect the successful completion of the
                internship or apprenticeship period at a company, his/her stay,
                or the placement process.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                1.5. A health insurance certificate and a civil liability
                insurance certificate, a copy of the passport, visa, residence
                permit and/or valid OFFI sticker, as well as any other official
                documents may be required. Students from outside the European
                community must be in possession of a VLS-TS Visa allowing them
                to carry out their internship in France. Upon their arrival on
                French territory and depending on the type of visa in their
                possession, these students must register at the Police
                Prefecture to validate their visa by following OFII procedures.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Each student is responsible for this legal and official process
                and should be informed of and follow the requirements provided
                by their embassy and the relevant French authorities.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Any student who does not comply with the legal requirements
                related to a visa will not be able to start his/her period at a
                company in France.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Any student who does not validate his/her visa within 3 months
                of his/her arrival will be considered to have illegal status and
                will be subject to a fine.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                1.6. T.D. reserves the right to refuse applications if: – the
                client does not provide the information requested within an
                acceptable timeframe – the client has been the subject of a
                criminal conviction for a serious offense or one incompatible
                with his/her entry into France or his/her offered period at a
                host company, or for any other reason that makes the client
                unfit for the period at the company – the client does not speak
                enough French to be able to communicate, even if the client has
                purchased the option that includes FLE courses.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                1.7. T.D. reserves the right to reject the application of any
                person deemed unfit for the company internship program, at its
                sole discretion.
              </span>
            </span>
          </span>
        </span>
      </span>
      <h5>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: "Calibri,sans-serif" }}>
            <u>
              <span style={{ fontSize: "13.5pt" }}>
                <span style={{ fontFamily: '"EB Garamond"' }}>
                  <span style={{ color: "#0b1941" }}>
                    2. Talent Developer’s (T.D.) Obligations and
                    Responsibilities
                  </span>
                </span>
              </span>
            </u>
          </span>
        </span>
      </h5>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                2.1. T.D. acts as an intermediary between host companies,
                partners, and the client.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                2.2. T.D. undertakes to put the client in contact with the host
                companies according to his/her expectations, profile, area of
                expertise, program of study, skills, and professional plan, but
                also depending on the requirements, profile, and expectations of
                host companies.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                2.3. T.D. undertakes to do its best to help the client secure
                their host company with actions such as: – training on the
                creation of the CV, cover letter, and expectations of companies
                in France, -reviewing and correcting the client’s CV and cover
                letter, – sending the client’s application to the host
                companies, – scheduling interviews with host companies
                interested in the client’s profile and willing to interview
                him/her, provided that the client has paid the initial deposit
                corresponding to the selected package, – reviewing and
                correcting any specific work required by host companies before
                giving an interview to the client or substituting the interview,
                – where appropriate, providing advice on how to prepare for a
                professional interview in order to maximise the client’s chances
                of obtaining an internship or an apprenticeship contract
                (coaching session and/or advice booklet).
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                2.4. T.D. assists the client with the signing of his/her
                internship agreement.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                2.5. Once the student starts at the host company, T.D.
                undertakes to monitor the student throughout the period at the
                company by answering any questions and advising him/her as well
                as possible.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                2.6. T.D. cannot be held responsible for the conduct of
                employees or the working conditions at the host company.
                However, T.D. undertakes to help the client resolve, to the best
                of its abilities, any potential communication problems that may
                arise with the host company and, in particular, concerning:
                duties and/or working conditions (See Article 7).
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                2.7. T.D. cannot be held responsible for the services provided
                by its partners. However, T.D. undertakes to help the client
                resolve, to the best of its abilities, any potential issues that
                may arise with its partners.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                2.8. T.D. undertakes to be available for any of the client’s
                requests for relevant information.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                2.9. T.D. cannot guarantee any placement at a company within a
                defined period. External factors (such as interest or
                availability of host companies, client conduct and speech during
                interviews, availability of accommodations, obtaining visa and
                approval, etc.) prevent T.D. from guaranteeing a specific date
                or deadline for placement. T.D. advises its clients to make
                their arrangements according to the time allocated for this
                search and to plan ahead in order to ensure every chance of
                success.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                2.10. T.D. cannot guarantee the compensation of internships by
                host companies but undertakes to help the client resolve
                potential communication problems with host companies on this
                subject.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                2.11. T.D. reserves the right to cease all cooperation with the
                client in the event that his/her profile, conduct, expectations,
                or plan do not correspond to the expectations and requirements
                of the host companies.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                2.12. Once the client has accepted the company offer and paid
                the balance of the corresponding Package, T.D. undertakes not to
                make this offer to any other client.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                2.13. T.D. undertakes not to disclose the client’s personal
                information to commercial institutions or to any other person or
                entity without their consent.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                2.14. T.D. is not legally responsible for the client, neither on
                the premises of the host company, nor outside the premises of
                the host company.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                2.15. T.D. is not responsible for any accident, injury, theft,
                damage, illness, loss, expense or cost incurred, or any risk
                that may arise during the internship or apprenticeship contract
                at a company or at the residence of a third party, including but
                not limited to the premises of the host company and the
                student’s accommodations.
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                2.16. T.D.’s maximum liability is limited to the amount paid by
                the client for the service provided by T.D. T.D. is not
                responsible for any additional costs that may be incurred by the
                client with external organisations before, during, or after
                his/her internship or apprenticeship contract within the host
                company or outside of it.
              </span>
            </span>
          </span>
        </span>
      </span>
      <h5>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: "Calibri,sans-serif" }}>
            <u>
              <span style={{ fontSize: "13.5pt" }}>
                <span style={{ fontFamily: '"EB Garamond"' }}>
                  <span style={{ color: "#0b1941" }}>
                    3. Client’s Obligations, Responsibilities, and Conduct
                  </span>
                </span>
              </span>
            </u>
          </span>
        </span>
      </h5>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                3.1. Internship or apprenticeship contract placement process:
              </span>
            </span>
          </span>
        </span>
      </span>
      <ul>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.1.1. The client understands and accepts that by
                    commissioning T.D. to find an internship or apprenticeship
                    contract and any other related service, the client agrees to
                    pay for the Package to which he/she has subscribed while
                    respecting the deadlines provided for in the contract for
                    the time T.D. spends carrying out the duties for which it
                    was commissioned.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.1.2. The client agrees to follow the process step-by-step
                    as described by T.D.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.1.3. The client undertakes to pay each invoice received
                    from T.D. on time and within the jointly set deadlines for
                    each service provided.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.1.4. The client acknowledges that any estimated timeframe
                    provided by T.D. for his/her placement is for information
                    purposes only.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.1.5. The client undertakes to inform T.D. of the start and
                    end dates of the internship or apprenticeship contract
                    provided for by his/her training when registering and/or
                    during the first interview one of T.D.’s staff. In the event
                    of a subsequent change of these dates, T.D cannot guarantee
                    the offer initially found and will not be held responsible
                    in the event that the host company rejects the new dates.
                    T.D will not issue any refunds.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.1.6. The client agrees not to bypass the T.D. recruitment
                    process to obtain an internship or apprenticeship contract
                    directly from the host companies in the T.D. network within
                    5 years. This deadline applies to both the school client as
                    well as to the students.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.1.7. The client agrees not to contact the host company
                    directly without T.D.’s consent before paying the total
                    amount due to T.D. for its services.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.1.8. T.D. understands that the client may be required to
                    share certain information relating to his/her internship,
                    apprenticeship contract, or accommodations with third
                    parties (for example: the client’s training centre and/or
                    parents). However, the client may not under any
                    circumstances disclose the contact information of host
                    companies or partners to any third party for purposes other
                    than those necessary for the successful completion of the
                    internship or apprenticeship contract (e.g.: friends,
                    classmates, publication on social networks), whether before,
                    during, or after the internship, apprenticeship period, or
                    stay in the accommodations. Otherwise, T.D. reserves the
                    right to cancel the contract that binds it to the client and
                    to inform its partners in order to invite them to do the
                    same for the services the client has commissioned from them
                    (in particular, for housing.). T.D. further reserves the
                    right to take any legal action necessary to protect the
                    company’s interests.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.1.9. Once the client accepts the internship, he/she agrees
                    to send his/her internship agreement in French or English by
                    email to T.D. within 2 days of its acceptance by the host
                    company so that T.D. can assist with signing. Otherwise,
                    T.D. cannot be held responsible in the event of cancellation
                    of the internship by the host company or non-signature of
                    the internship agreement by the host company. If the student
                    or the school does not have an internship agreement, T.D.
                    can offer them a standard agreement in French/English.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.1.10. If the client (or their school or university or any
                    other third-party representative) sends the internship
                    agreement directly to the host company (by e-mail or by
                    post), T.D. cannot be held responsible for the non-signing
                    of the internship agreement or the cancellation of the
                    internship by the host company.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.1.11. The client understands and agrees that there may be
                    certain delays in the internship agreement being returned by
                    the host company. It may take time for the host company to
                    read the agreement, have it proofread by a lawyer, for the
                    director to accept it, or to confirm the final internship
                    dates, etc. T.D. cannot be held responsible for these
                    delays.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.1.12. Once the internship agreement has been signed by the
                    host company, it is the client’s responsibility to have
                    their internship agreement signed with their university,
                    school, or educational organisation and to upload a scanned
                    copy to their personal profile. T.D. can in no way be held
                    responsible for any failure on the part of the client or
                    his/her school concerning the non-signature of the
                    internship agreement.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                3.2&nbsp;<em>Client Financial Resources:</em>
              </span>
            </span>
          </span>
        </span>
      </span>
      <ul>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.2.1. It is the client’s responsibility to have the
                    necessary financial resources to pay for T.D.’s services.
                    The client must ensure they have sufficient financial
                    resources before starting the process with T.D.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.2.2. It is the client’s responsibility to be aware that
                    traveling abroad carries certain costs and that one must
                    have the financial resources necessary to live in the
                    country. It is advisable to research in advance the
                    financial resources needed to live in Paris.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.2.3. The client agrees that T.D. cannot be held
                    responsible for any inconvenience or loss of income due to
                    the placement process.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.2.4. It is the client’s responsibility to ensure that any
                    compensation mentioned in the company’s offer during the
                    interview and/or when signing the internship agreement, or
                    when signing the apprenticeship contract, is obtained from
                    the host company and not from T.D.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.2.5. In the case of applying for a Visa, and depending on
                    different nationalities, the competent authorities may
                    request a certificate of sufficient resources from the
                    student.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                3.3. Insurance and Client Conduct:
              </span>
            </span>
          </span>
        </span>
      </span>
      <ul>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.3.1. It is the client’s responsibility to purchase
                    liability insurance, medical insurance, travel insurance
                    and/or comprehensive insurance. It is also the client’s
                    responsibility to purchase home insurance when renting an
                    apartment with one of our partners.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.3.2. The client is responsible for his/her own safety
                    during the trip and during his/her stay.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.3.3. The client undertakes to behave respectfully during
                    his/her time at the company, actively participating in the
                    company’s activities by adopting a professional, efficient,
                    and productive attitude and by representing the best
                    interests of the host company, fulfilling all duties set
                    forth in the offer.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.3.4. The client must comply with the host company’s code
                    of conduct throughout the duration of his/her internship or
                    apprenticeship contract. The student is subject to the
                    disciplinary measures and applicable clauses of the host
                    company’s internal regulations, and which are brought to
                    his/her attention before the start of the internship or
                    apprenticeship contract, in particular with regard to
                    schedules and health and safety rules in effect at the host
                    organisation. In addition, the client is required to respect
                    the company’s culture and internal policies, which may be
                    listed in a personnel manual or communicated verbally.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.3.5 The student agrees to fulfil his/her duties and to be
                    available for the tasks entrusted to him/her, to respect the
                    company’s rules and codes, its culture, and the company’s
                    confidentiality requirements. The student agrees not to copy
                    any internal documents belonging to the company without
                    having obtained authorisation and not to disseminate the
                    information he/she collects, except with the express
                    agreement of the company.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.3.6. It is the student’s responsibility to respect the
                    schedule set forth by the host company and to follow it.
                    This may be updated regularly and modified according to the
                    company’s needs. The student is prepared to be flexible and
                    aware of the demands of the profession. The host company may
                    ask him/her to change his/her schedule or adapt to its needs
                    by working a few extra hours, as needed, and with little
                    notice.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.3.7. The student is fully responsible for his/her actions
                    throughout his/her period at the company and/or stay in the
                    accommodations. He/she is responsible for any loss or damage
                    resulting from his/her actions. The student agrees to
                    indemnify T.D., the host company, owners, main tenants,
                    apartment rental agencies, other service providers (taxis,
                    cleaning services, etc.), any other third party who may
                    suffer loss or damage due to the student’s conduct. The
                    student must be insured for any damage or loss that may
                    occur during while at the company or during his/her stay.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.3.8. The student undertakes to respect French law and not
                    to endanger or cause harm to him/herself and/or endanger or
                    cause harm to any third party. If the student does not act
                    in accordance with the information stated above, he/she will
                    be considered fully responsible for the consequences of
                    his/her actions.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>3.4. Documents:</span>
            </span>
          </span>
        </span>
      </span>
      <ul>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    3.4.1. It is the student’s responsibility to purchase their
                    ticket for travel and to obtain all the necessary documents
                    for traveling abroad (a visa and/or a residence permit and
                    insurance) and to be available on the start date of the
                    internship or apprenticeship contract. T.D. cannot be held
                    responsible if the client does not obtain their visa or any
                    other document necessary to travel/reside abroad and no T.D.
                    fees will be refunded.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <h5>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: "Calibri,sans-serif" }}>
            <u>
              <span style={{ fontSize: "13.5pt" }}>
                <span style={{ fontFamily: '"EB Garamond"' }}>
                  <span style={{ color: "#0b1941" }}>4. The Host Company</span>
                </span>
              </span>
            </u>
          </span>
        </span>
      </h5>
      <ul>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    4.1. The host company is the sole decisionmaker regarding
                    the selection of candidates for interviews. In order to be
                    eligible for the internship or apprenticeship contract, the
                    student must have met all of the receiving company’s
                    criteria.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    4.2. The host company must provide work duties and tasks
                    corresponding to those provided for in the internship
                    agreement or apprenticeship contract, which are established
                    before starting at the host company.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    4.3. Host companies are subject to and must comply with the
                    law. The benefits provided by the host companies
                    (compensation, accommodations, reimbursement of transport
                    tickets, meals, etc.) differ and cannot be guaranteed by
                    T.D. before signing the internship agreement or
                    apprenticeship contract.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    4.4. The host company is liable for any bodily harm or
                    material damages suffered by the student during his/her
                    working hours or on the commute from home to the company. If
                    the client caused the damage, he/she is liable. Some schools
                    may also provide third party liability coverage for the
                    student. As part of an apprenticeship contract, the client
                    is covered by the same social security coverage as an
                    employee of the host company, who is subject to the social
                    security contributions provided for by law. The host
                    company’s civil liability extends to the apprentice whether
                    or not the latter caused the damage.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    4.5. All negotiations concerning schedules, bonuses, and
                    holidays are held directly between the client and the host
                    company. However, T.D. requests to be informed before any
                    negotiations to ensure compliance with the request and to
                    advise the client, if necessary.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    4.6. The student is responsible for his/her uniform for the
                    duration of the internship or apprenticeship contract.
                    Students must report to the host company with their own full
                    school uniform (unless the host company provides its own
                    uniform).
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    4.7. The host company and T.D. confirm that no absences will
                    be tolerated during the period at the company. If the
                    student is really unable to go to the host company, whether
                    for medical reasons or due to extenuating circumstances,
                    he/she must notify the company as soon as possible so that
                    it can find an alternative. Any medical reason must be
                    demonstrated with a visit to the doctor and a medical
                    certificate sent to the company as soon as possible. The
                    host company will regard repeated, unauthorised, or
                    unjustified absences in a very negative light and penalties
                    may be determined, such as the termination of the internship
                    or apprenticeship contract. Under these circumstances, the
                    student will not be able to obtain any refunds from T.D.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <h5>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: "Calibri,sans-serif" }}>
            <u>
              <span style={{ fontSize: "13.5pt" }}>
                <span style={{ fontFamily: '"EB Garamond"' }}>
                  <span style={{ color: "#0b1941" }}>5. Price</span>
                </span>
              </span>
            </u>
          </span>
        </span>
      </h5>
      <ul>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    5.1. T.D. reserves the right to modify its prices. However,
                    services are invoiced based on the rates in effect on the
                    date the client’s contract is signed.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    5.2. Prices are displayed in Euros and include all taxes.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    5.3. Prices do not include airline tickets, insurance, visa
                    fees, excess baggage, airport taxes, food, public transport,
                    excursions, additional activities, taxi rides, monthly rent
                    for housing, pocket money, or any other expenses not listed
                    in T.D.’s package.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    5.4. The student agrees to make the payments according to a
                    specific timeline: a deposit corresponding to the selected
                    package must be paid to T.D. upon signing of the contract.
                    The balance of the selected package is due before the
                    contact information of the host company chosen for the
                    client is communicated and an appointment for the interview
                    with said company is made.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    5.5. T.D. Prices:
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Future Talent Package: 500 Euros, tax included&nbsp;
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Future Talent Premium Package: 900 Euros, tax included&nbsp;
              </span>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Future Talent Premium Plus Package: 1500 Euros, tax
                included&nbsp;
              </span>
            </span>
          </span>
        </span>
      </span>
      <h5>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: "Calibri,sans-serif" }}>
            <u>
              <span style={{ fontSize: "13.5pt" }}>
                <span style={{ fontFamily: '"EB Garamond"' }}>
                  <span style={{ color: "#0b1941" }}>
                    6.&nbsp;Payment for Package: Deposit and the Balance
                  </span>
                </span>
              </span>
            </u>
          </span>
        </span>
      </h5>
      <ul>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    6.1. Process
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    6.1.1. T.D. receives the client’s request to search for an
                    internship or apprenticeship contract through his/her
                    registration on the T.D. website or via the student’s school
                    or CFA. The student receives an offer for an appointment
                    with T.D. to talk about his/her expectations and so that
                    T.D. may propose the most suitable service to the client. If
                    the client is interested in the service, he/she pays the
                    deposit corresponding to the selected package after his/her
                    interview. The client must also follow the steps and provide
                    the documents requested on his/her personal profile.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    6.1.2. T.D. corrects the client’s CV and cover letter,
                    prepares, and sends the client’s application to the host
                    companies corresponding to the company offers identified and
                    selected for the client.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    6.1.3. When a first host company shows interest in the
                    client’s application and in order to facilitate contact,
                    T.D. requests payment of the balance of the Package selected
                    for and by the client. T.D. will not release the contact
                    information or name of the pre-selected host company to the
                    client until they have received the balance due for the
                    package. The host company may express interest in the
                    client’s profile and ask that an initial face-to-face or
                    remote interview (Skype, WhatsApp, phone, etc.) with the
                    client be arranged. Based on its internal recruitment
                    process, the host company may also request from the client
                    other interviews, an introductory video, written test, or
                    other aptitude or skill test before making its decision.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    6.1.4. If several companies are interested in the client’s
                    profile, T.D. undertakes to select the company corresponding
                    to the client’s best interests based on the information
                    provided by the latter during the various stages of the
                    service.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    6.1.5. Some host companies accept applications from clients
                    without a prior interview. In this case, T.D. requests
                    payment of the balance from the client as soon as the
                    client’s application has caught the attention of the host
                    company. Once the client has paid, T.D. communicates
                    information about the company who approved his/her
                    application. This information will allow the client to make
                    a decision regarding the host company’s offering.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    6.1.6. The client agrees to pay the deposit and the balance
                    upon receipt of the invoice from T.D.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    6.1.7. T.D. sends all the necessary information to the
                    client to enable him/her to prepare for his/her interview
                    with the host company (advice booklet, name of HR contacts
                    and mentor, website, and the host company’s contact
                    information) upon receipt of payment for the client’s
                    balance.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    6.1.8. It is the client’s responsibility to ask all
                    necessary questions about his/her internship or
                    apprenticeship contract (duties, start and end dates at the
                    company, compensation, working conditions, working hours,
                    place of work) during the interview with the host company or
                    before accepting the internship.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    6.2. Payment and refund of the deposit and/or the balance of
                    the Package:
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    6.2.1. All payments are secure and must be made through the
                    student website or via bank transfer. Bank cheques or cash
                    are not accepted.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    6.2.2. Payment of the deposit and then the balance must be
                    made within two days (48 hours) of receipt of the invoice.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    6.2.3. It is the client’s responsibility to ensure that
                    payment is possible and can be made on time.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    6.2.4. As the prices of the services offered are in Euros
                    (€), changes in exchange rates are not refundable or
                    attributable to T.D.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    6.2.5. T.D. may use the services of a collection firm to
                    secure payment for its services in the event that the client
                    does not pay invoices sent by T.D. and the amounts due.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    6.2.6. Once the balance has been paid, if the client fails
                    the first interview or test required by the host company,
                    T.D. may arrange a maximum of 3 interviews or tests with
                    host companies. However, the client understands and accepts
                    that T.D. cannot undertake and guarantee the arrangement of
                    further interviews or tests, as this depends on the
                    willingness of host companies and their interest in the
                    client’s profile.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    6.2.7. T.D. cannot be held responsible if no host company is
                    interested in the client’s profile (for an interview or a
                    test), but not having been able to provide the service, T.D.
                    undertakes to refund the amount of the deposit received.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    6.2.8. If the client decides not to attend the scheduled
                    interview with the host company, upon receipt of notice from
                    T.D. for payment of the balance, the balance remains due to
                    T.D. from the client.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    6.2.9. If the client decides to abandon the placement
                    process for any reason (including because they have obtained
                    an internship or apprenticeship contract outside of the T.D.
                    placement process) before payment of the balance, the
                    balance remains due to T.D. from the client.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    6.2.10. If the client changes his/her mind after being
                    accepted by a host company (after or without prior
                    interview) and refuses the offer, no sums already paid will
                    be refunded under any circumstances.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    6.2.11. Cancellation of the client’s application for reasons
                    including but not limited to: change of mind, loss of
                    interest in the offer, waiting for interviews with companies
                    not affiliated with T.D., finding a host company
                    independently, change of dates, change of field of study,
                    lack of financial resources, failure to obtain credits or
                    any grant, scholarship, or other administrative documents
                    (including but not limited to: visa, residence permit,
                    etc.), or any other reason not appearing in the
                    aforementioned list will not be considered a valid reason
                    for not paying the deposit corresponding to the selected
                    package or for requesting a refund.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    6.2.12. If the client’s conduct is deemed unprofessional at
                    any stage of the process (e.g. sending documents late,
                    improper conduct towards T.D., the host company, or any
                    other partner), T.D. may decide to sever its undertaking
                    with the client without the client being able to request a
                    refund.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <h5>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: "Calibri,sans-serif" }}>
            <u>
              <span style={{ fontSize: "13.5pt" }}>
                <span style={{ fontFamily: '"EB Garamond"' }}>
                  <span style={{ color: "#0b1941" }}>
                    7. Change of Host Company or Package Refund:
                  </span>
                </span>
              </span>
            </u>
          </span>
        </span>
      </h5>
      <ul>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    7.1. In the event of a problem with the host company, the
                    client must contact T.D. right away in order to allow it to
                    find solutions.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    7.2. If the client has not yet started his/her internship or
                    apprenticeship contract:
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    7.2.1. Cancellation of the client’s internship or
                    apprenticeship contract for reasons including but not
                    limited to: change of mind, loss of interest in the offer,
                    waiting for interviews with companies not affiliated with
                    T.D., finding a host company independently, change of dates,
                    change of field of study, dismissal or expulsion from the
                    educational institution, lack of financial resources,
                    failure to obtain credits or any grant, scholarship, visa,
                    or any other reason not appearing in the aforementioned list
                    will not be considered a valid reason for not paying the
                    invoice for the Package or for requesting a refund.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    7.2.2. The client understands and accepts that by
                    contracting T.D. to find a host company and/or for any other
                    service, T.D. allows for time to complete the work requested
                    by the client. Therefore, T.D. asks the client to pay the
                    selected package for the work completed and the time spent.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    7.3. If the client has already started at the host company:
                  </span>
                </span>
              </span>
            </span>
          </span>
          <ul style={{ listStyleType: "disc" }}>
            <li>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#0b1941" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontSize: "10.5pt" }}>
                      <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                        7.3.1. A client’s request to change the Host Company
                        will only be considered in cases recognised by law, such
                        as: – Sexual or psychological harassment. – Racial or
                        religious defamation. – Inability of the host company to
                        continue the training at the company. – Declaration of
                        bankruptcy by the host company. – Failure to comply with
                        safety or health measures likely to harm or affect the
                        client. – Lack of correlation between the duties and
                        tasks entrusted to the client and those specified in the
                        internship agreement or the apprenticeship contract
                        signed between the client and the host company.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#0b1941" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontSize: "10.5pt" }}>
                      <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                        7.3.2. In the event of client dissatisfaction with the
                        host company, T.D. undertakes to listen to the reason(s)
                        and do its best to provide viable solutions for the
                        client and the host company.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#0b1941" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontSize: "10.5pt" }}>
                      <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                        7.3.3. If the client has any problems during his/her
                        internship or apprenticeship period with his/her
                        accommodations or any other service purchased through
                        T.D., he/she must first contact T.D.,T.D. will
                        investigate the situation and give advice so that the
                        student can contact his/her mentor at the host company
                        or the partner who provided a service to the client.
                        T.D. will contact the host company or partners and offer
                        to arrange a meeting or conference call between T.D.,
                        the client, and the host institution or partner to find
                        appropriate solutions. Only complaints communicated in
                        written form (letter or e-mail) will be taken into
                        account.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#0b1941" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontSize: "10.5pt" }}>
                      <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                        7.3.4 If the student feels that the employer is treating
                        them unfairly, they should ask the mentor or manager for
                        a meeting to discuss their performance at the company.
                        One must consider that, depending on the culture of each
                        person, things may be perceived very differently. The
                        student has decided to come to Paris to learn a
                        profession, but also to understand the culture and adapt
                        to operating in a manner that is different from his/her
                        country. The student should be attentive to the comments
                        by always taking them in a positive way and by accepting
                        multiculturality while keeping an open mind. During the
                        meeting, the student will ask his/her mentor if there
                        are any areas he/she needs to improve on and for an
                        explanation/demonstration of the procedures with which
                        he/she does not feel comfortable. If the situation does
                        not improve, the student will notify T.D. so that it can
                        provide assistance.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#0b1941" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontSize: "10.5pt" }}>
                      <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                        7.3.5 Before taking any action within the company, the
                        student shall contact T.D. for advice. T.D. staff cannot
                        help their client solve a problem if they are not aware
                        of it!
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#0b1941" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontSize: "10.5pt" }}>
                      <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                        7.3.6. If the client is not satisfied with the host
                        company for a valid and justified reason (see section
                        7.3.1) and solutions cannot be found, T.D. undertakes to
                        do its best to find another host company that matches
                        the client’s profile and field of activity depending on
                        the availability of host companies, the remaining
                        duration (minimum 3 months), and the client’s level of
                        French.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#0b1941" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontSize: "10.5pt" }}>
                      <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                        7.3.7. In the event that the client decides to terminate
                        his/her internship or apprenticeship contract
                        independently without discussing or notifying T.D. and
                        without prior approval from T.D., or if the host company
                        decides to terminate due to inappropriate conduct
                        (verbally or physically abusive behaviour, racist
                        behaviour, consumption of drugs, alcohol, poor conduct,
                        insubordination, behaviour deemed unacceptable by the
                        mentor or the managers of the host company, professional
                        neglect of work duties or non-compliance with the host
                        company’s rules), T.D. reserves the right not to refund
                        the package or “à la carte” services paid by the client
                        and not to place the client at another host company.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#0b1941" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontSize: "10.5pt" }}>
                      <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                        7.3.8. The student undertakes to respect French law and
                        not to put him/herself or any third party in danger.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
        </li>
      </ul>
      <h5>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: "Calibri,sans-serif" }}>
            <u>
              <span style={{ fontSize: "13.5pt" }}>
                <span style={{ fontFamily: '"EB Garamond"' }}>
                  <span style={{ color: "#0b1941" }}>
                    8. Talent Developer Service Partners or Firms
                  </span>
                </span>
              </span>
            </u>
          </span>
        </span>
      </h5>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              <span style={{ color: "#0b1941" }}>
                Depending on the situation, T.D. may sometimes work with
                different partners or selected service companies to help the
                student settle in Paris and provide other services that make
                their daily life in France easier. You can find all of our “à la
                carte services” on the T.D. website.
              </span>
            </span>
          </span>
        </span>
      </span>
      <ul>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    8.1. Partner: Partner refers to any company, organisation,
                    natural person or legal entity employed or used by T.D. for
                    its services (e.g. apartment rental agency, transport
                    company, cleaning service company, cuisine and/or pastry
                    courses, tours of Paris, wine courses, and any other service
                    provider that T.D. may collaborate with to provide
                    additional services to the client).
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    8.2. The client understands and agrees that, depending on
                    the services required, he/she may also be put in touch with
                    these partners.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    8.3. Accommodations: The apartment rental agency refers to
                    any company offering accommodation services to the client.
                    T.D. does not include student accommodations in any of its
                    services but offers to put you in contact with its partners
                    and provide you with special offers. Be aware of the prices
                    of accommodations in Paris, which may be more or less
                    expensive depending on the type of accommodation and its
                    location. To provide for finding housing in Paris, these two
                    partners undertake to:
                  </span>
                </span>
              </span>
            </span>
          </span>
          <ul style={{ listStyleType: "disc" }}>
            <li>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#0b1941" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontSize: "10.5pt" }}>
                      <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                        Find a private room or a shared room in an apartment or
                        a shared house, a private room or a shared room with a
                        Host Family or a private rental.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#0b1941" }}>
                  <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontSize: "10.5pt" }}>
                      <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                        Coordinate between the client and the person in charge
                        of the apartment or house (owner, main tenant, apartment
                        rental agency, or host family) before the client enters
                        the accommodations.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    8.4. Insurance Brokerage Agency: For the safety of the
                    student and others, the student is required to be in
                    possession of liability insurance, home/rental insurance,
                    and health/medical insurance as indicated in the previous
                    paragraphs.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    8.5. Transport to/from airport/Paris: The transport company
                    refers to any company that collaborates with T.D. in order
                    to provide transport services to the client.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    8.6. French School: The French school refers to any
                    authorised language school that collaborates with T.D. to
                    provide French FLE (French as a Foreign Language) courses to
                    the client.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <h5>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: "Calibri,sans-serif" }}>
            <u>
              <span style={{ fontSize: "13.5pt" }}>
                <span style={{ fontFamily: '"EB Garamond"' }}>
                  <span style={{ color: "#0b1941" }}>
                    9. Unavoidable Circumstances
                  </span>
                </span>
              </span>
            </u>
          </span>
        </span>
      </h5>
      <ul>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    9.1. T.D. and its partners or suppliers are released from
                    their contractual obligations in the event of the occurrence
                    of a force majeure event, or any act attributable to a third
                    party, or any other circumstance or external cause
                    independent of T.D. staff, which directly or indirectly
                    prevents T.D. from fulfilling its contractual obligations.
                    In addition to the cases recognised by the courts, cases of
                    force majeure include but are not limited to: all natural
                    disasters, acts of war, disturbances of public order,
                    epidemics, fires, floods, and other catastrophes, acts of
                    any government, all strikes in any form whatsoever
                    (internal, external, etc.), and any Internet or network
                    malfunction. The services provided by T.D. and its partners
                    or suppliers will not be refundable in these instances.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <h5>
        <span style={{ fontSize: "12pt" }}>
          <span style={{ fontFamily: "Calibri,sans-serif" }}>
            <u>
              <span style={{ fontSize: "13.5pt" }}>
                <span style={{ fontFamily: '"EB Garamond"' }}>
                  <span style={{ color: "#0b1941" }}>
                    10. Applicable Law and Dispute Resolution
                  </span>
                </span>
              </span>
            </u>
          </span>
        </span>
      </h5>
      <ul>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    10.1. These terms and conditions are valid as of 01 October
                    2020 and are subject to French law. If any changes are made
                    to the terms and conditions, they shall take effect at the
                    time of their posting on the T.D. website
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    10.2. This contract is subject to French law. In the event
                    of a dispute, the courts of Paris have jurisdiction.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
        <li>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ color: "#0b1941" }}>
              <span style={{ fontFamily: "Calibri,sans-serif" }}>
                <span style={{ fontSize: "10.5pt" }}>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    10.3. These terms and conditions represent a contract
                    between the client and T.D.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>&nbsp;</span>
      </span>
      <br />
      <span style={{ fontSize: "12pt" }}>
        <span style={{ fontFamily: "Calibri,sans-serif" }}>
          <em>
            <span style={{ fontSize: "10.5pt" }}>
              <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                <span style={{ color: "#0b1941" }}>
                  Talent Developer Conditions 01 October 2020
                </span>
              </span>
            </span>
          </em>
        </span>
      </span>
    </>
  );
};

export default CGVContentEN;
