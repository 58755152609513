import React, { useState } from "react";
import TalentButton from "../core/TalentButton";
import { useTranslation } from "react-i18next";
import { Typography, TextField, MenuItem, makeStyles } from "@material-ui/core";
import TalentStepper from "../core/TalentStepper";
import frenchFlag from "../../assets/img/flags/france.png";
import spainFlag from "../../assets/img/flags/spain.png";
import englishFlag from "../../assets/img/flags/united-kingdom.png";
import TalentHeaderContainer from "../core/layout/TalentHeaderContainer";
import TalentContentContainer from "../core/layout/TalentContentContainer";
import TalentFooterContainer from "../core/layout/TalentFooterContainer";
import TalentWhiteContainer from "../core/layout/TalentWhiteContainer";
import { capitalize, validateEmail } from "../../services/validation.utils";

const useStyles = makeStyles((theme) => ({
  img: {
    width: 15,
    marginRight: 5,
    marginLeft: 5,
  },
}));

const RegisterStep1 = ({ values, handlers, stepsTotal, nextStep }) => {
  // Load translation
  const { t } = useTranslation();

  // Styles
  const classes = useStyles();

  // Errors
  const [fieldsError, setFieldsError] = useState({
    lastname: false,
    firstname: false,
    nationality: false,
    email: false,
    phone: false,
    language: false,
  });

  // Errors message
  const [fieldsErrorMsg, setFieldsErrorMsg] = useState({
    lastname: "",
    firstname: "",
    nationality: "",
    email: "",
    phone: "",
    language: "",
  });

  // Available languages
  const languages = [
    { value: "fr_FR", label: t("French"), image: frenchFlag },
    { value: "en_US", label: t("English"), image: englishFlag },
    { value: "es_ES", label: t("Spanish"), image: spainFlag },
  ];

  // Perform values check before going to the next step
  const handleNext = () => {
    let errorDetected = false;
    let localErrors = {};

    // Verify all this required values
    const step1Fields = [
      "lastname",
      "firstname",
      "nationality",
      "email",
      "phone",
      "language",
    ];

    step1Fields.forEach((e) => {
      // Set the local error state
      if (values[e] === "") {
        localErrors[e] = true;
        errorDetected = true;
        fieldsErrorMsg[e] = t("RequiredField");
        setFieldsErrorMsg(fieldsErrorMsg);
      } else if (fieldsError[e]) {
        localErrors[e] = true;
        errorDetected = true;
      }
    });
    setFieldsError(localErrors);

    // If no error, we go to the next step
    if (!errorDetected) {
      nextStep();
    }
  };

  return (
    <TalentWhiteContainer>
      <TalentHeaderContainer>
        <div className="LeftCenterTxt" style={{ flex: 1 }}>
          <Typography
            variant="subtitle1"
            component="span"
            color="textSecondary"
            xs={{ fontSize: 12 }}
          >
            {t("CreateAccount")}
          </Typography>
          <Typography variant="h1" component="h1" color="primary">
            {t("AboutYou")}
          </Typography>
        </div>
        <TalentStepper activeStep={1} stepsTotal={stepsTotal}></TalentStepper>
      </TalentHeaderContainer>

      <TalentContentContainer>
        <TextField
          label={t("Lastname")}
          onChange={(e) => {
            fieldsError.lastname = false;
            setFieldsError(fieldsError);
            handlers.setLastname(e.target.value.toUpperCase());
          }}
          value={values.lastname}
          required
          fullWidth
          error={fieldsError.lastname}
          helperText={fieldsError.lastname ? fieldsErrorMsg.lastname : null}
          data-testid="register-input-lastname"
        />

        <TextField
          label={t("Firstname")}
          onChange={(e) => {
            fieldsError.firstname = false;
            setFieldsError(fieldsError);
            handlers.setFirstname(capitalize(e.target.value));
          }}
          value={values.firstname}
          required
          fullWidth
          error={fieldsError.firstname}
          helperText={fieldsError.firstname ? fieldsErrorMsg.firstname : null}
          data-testid="register-input-firstname"
        />

        <TextField
          label={t("Nationnality")}
          onChange={(e) => {
            fieldsError.nationality = false;
            setFieldsError(fieldsError);
            handlers.setNationality(capitalize(e.target.value));
          }}
          value={values.nationality}
          required
          fullWidth
          error={fieldsError.nationality}
          helperText={
            fieldsError.nationality ? fieldsErrorMsg.nationality : null
          }
          data-testid="register-input-nationality"
        />

        <TextField
          label={t("Email")}
          onChange={(e) => {
            const value = e.target.value;
            if (validateEmail(value)) {
              fieldsError.email = false;
              setFieldsError(fieldsError);
              fieldsErrorMsg.email = "";
              setFieldsErrorMsg(fieldsErrorMsg);
              handlers.setEmail(value);
            } else {
              fieldsError.email = true;
              setFieldsError(fieldsError);
              fieldsErrorMsg.email = t("InvalidEmail");
              setFieldsErrorMsg(fieldsErrorMsg);
              handlers.setEmail(value);
            }
          }}
          value={values.email}
          required
          fullWidth
          error={fieldsError.email}
          helperText={fieldsError.email ? fieldsErrorMsg.email : null}
          data-testid="register-input-email"
        />

        <TextField
          label={t("Phone")}
          onChange={(e) => {
            fieldsError.phone = false;
            setFieldsError(fieldsError);
            handlers.setPhone(e.target.value);
          }}
          value={values.phone}
          required
          fullWidth
          error={fieldsError.phone}
          helperText={fieldsError.phone ? fieldsErrorMsg.phone : null}
          data-testid="register-input-phone"
        />

        <TextField
          label={t("Language")}
          onChange={(e) => {
            fieldsError.language = false;
            setFieldsError(fieldsError);
            handlers.setLanguage(e.target.value);
          }}
          value={values.language}
          required
          select
          fullWidth
          error={fieldsError.language}
          helperText={fieldsError.language ? fieldsErrorMsg.language : null}
          data-testid="register-input-language"
        >
          {languages.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <img
                src={option.image}
                alt={option.value + " flag"}
                className={classes.img}
              ></img>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </TalentContentContainer>

      <TalentFooterContainer>
        <TalentButton
          withIcon={true}
          onClick={handleNext}
          data-testid="register-btn-next"
        >
          {t("Continue")}
        </TalentButton>
      </TalentFooterContainer>
    </TalentWhiteContainer>
  );
};

export default RegisterStep1;
