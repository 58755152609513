import React from "react";
import { useTranslation } from "react-i18next";
import sentImg from "../../assets/img/assets-img-lobby.svg";
import TalentBlueContainer from "../core/layout/TalentBlueContainer";
import { makeStyles } from "@material-ui/core";
import TalentCommercialName from "../core/TalentCommercialName";

const useStyle = makeStyles({
  firstLine: {
    marginBottom: "1em",
  },
});

const Step3 = () => {
  // Load translation
  const { t } = useTranslation();

  // Load classes
  const classes = useStyle();

  return (
    <TalentBlueContainer
      img={sentImg}
      imgAlt="Waiting for report"
      title={t("S3WaitingReport")}
    >
      <>
        <div className={classes.firstLine}>{t("S3WaitingReportLine1")}</div>
        <div
          style={{
            color: "#DC5145",
          }}
        >
          {t("S3WaitingReportLine2")}
          <TalentCommercialName withLink={true} />
        </div>
      </>
    </TalentBlueContainer>
  );
};

export default Step3;
