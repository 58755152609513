import API from "./api";
import AuthService from "./auth.service";

class ProjectService {
  /**
   * Method used to get user notifications.
   * Uses the /project/{projectId}/notifications endpoint.
   *
   * @param {Object} user       User of the context provider
   */
  getNotifications = async (user) => {
    const response = await API.get(
      `/talentdeveloper/project/${user.info.project}/notifications`,
      {
        headers: { DOLAPIKEY: AuthService.getToken(user) },
      }
    ).catch((err) => {
      throw err;
    });

    return response.data.success.messages;
  };

  /**
   * Method used to get project messages.
   * Uses the /project/{projectId}/messages endpoint.
   *
   * @param {Object} user       User of the context provider
   */
  getNotifications = async (user) => {
    const response = await API.get(
      `/talentdeveloper/project/${user.info.project}/messages`,
      {
        headers: { DOLAPIKEY: AuthService.getToken(user) },
      }
    ).catch((err) => {
      throw err;
    });

    return response.data.success.messages;
  };

  /**
   * Method used to send a message for the project.
   * Uses the /project/{projectId}/messages endpoint.
   *
   * @param {Object} user       User of the context provider
   * @param {String} message    Message content sended
   */
  sendMessage = async (user, message) => {
    const response = await API.post(
      `/talentdeveloper/project/${user.info.project}/messages`,
      { content: message },
      {
        headers: { DOLAPIKEY: AuthService.getToken(user) },
      }
    ).catch((err) => {
      throw err;
    });

    return response.data.success.message;
  };

  /**
   * Method used to get the step 2 appointment date for the project.
   * Uses the /project/{projectId}/tasks/2/appointment endpoint.
   *
   * @param {Object} user       User of the context provider
   */
  getStep2Appointment = async (user) => {
    const response = await API.get(
      `/talentdeveloper/project/${user.info.project}/tasks/2/appointment`,
      {
        headers: { DOLAPIKEY: AuthService.getToken(user) },
      }
    ).catch((err) => {
      throw err;
    });

    return response.data.success.appointment;
  };

  /**
   * Method used to get the td contact for the project.
   * Uses the /project/{projectId}/contact endpoint.
   *
   * @param {Object} user       User of the context provider
   */
  getTDContact = async (user) => {
    const response = await API.get(
      `/talentdeveloper/project/${user.info.project}/tdcontact`,
      {
        headers: { DOLAPIKEY: AuthService.getToken(user) },
      }
    ).catch((err) => {
      throw err;
    });

    return response.data.success.contact;
  };

  /**
   * Method used to get the report content for the project.
   * Uses the /project/{projectId}/report endpoint.
   *
   * @param {Object} user       User of the context provider
   */
  getReport = async (user) => {
    const response = await API.get(
      `/talentdeveloper/project/${user.info.project}/report`,
      {
        headers: { DOLAPIKEY: AuthService.getToken(user) },
      }
    ).catch((err) => {
      throw err;
    });

    return response.data.success.report;
  };

  /**
   * Method used to validate the report content for the project.
   * Uses the /project/{projectId}/report endpoint.
   *
   * @param {Object} user       User of the context provider
   */
  validateReport = async (user) => {
    const response = await API.put(
      `/talentdeveloper/project/${user.info.project}/report`,
      {},
      {
        headers: { DOLAPIKEY: AuthService.getToken(user) },
      }
    ).catch((err) => {
      throw err;
    });

    return response.data.success.message;
  };

  /**
   * Method to encode on base64 a File object
   *
   * @param {File} file           File object to encode
   */
  toBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  /**
   * Method used to upload documents for the project.
   * Uses the /documents endpoint.
   *
   * @param {Object} user       User of the context provider
   */
  uploadDocument = async (user, file) => {
    let formData = new FormData();

    // Get the file content formated as base64
    let fileContent = await this.toBase64(file);

    // Parameters to send to the api
    formData.append("projectId", user.info.project);
    formData.append("modulepart", "project");
    formData.append("filename", file.name);
    formData.append("filecontent", fileContent);
    formData.append("fileencoding", "base64");
    formData.append("overwriteifexists", 1);

    const response = await API.post(`/talentdeveloper/documents`, formData, {
      headers: { DOLAPIKEY: AuthService.getToken(user) },
    }).catch((err) => {
      throw err;
    });

    return response.data;
  };

  /**
   * Method used to get the documents overview of the project.
   * Uses the /project/{projectId}/documents endpoint.
   *
   * @param {Object} user       User of the context provider
   */
  getDocumentsOverview = async (user) => {
    const response = await API.get(
      `/talentdeveloper/project/${user.info.project}/documents`,
      {
        headers: { DOLAPIKEY: AuthService.getToken(user) },
      }
    ).catch((err) => {
      throw err;
    });

    return response.data.success.overview;
  };

  /**
   * Method used to change the actual step progress.
   * Uses the /project/{projectId}/tasks/current/ endpoint.
   *
   * @param {Object} user       User of the context provider
   * @param {int} progress      Progress for the task
   */
  changeTaskProgress = async (user, progress) => {
    const response = await API.put(
      `/talentdeveloper/project/${user.info.project}/tasks/current`,
      { progress: progress },
      {
        headers: { DOLAPIKEY: AuthService.getToken(user) },
      }
    ).catch((err) => {
      throw err;
    });

    return response.data.success;
  };

  /**
   * Method used to get the deposit overview of the project.
   * Uses the /project/{projectId}/paiement/deposit endpoint.
   *
   * @param {Object} user       User of the context provider
   */
  getDepositOverview = async (user) => {
    const response = await API.get(
      `/talentdeveloper/project/${user.info.project}/paiement/deposit`,
      {
        headers: { DOLAPIKEY: AuthService.getToken(user) },
      }
    ).catch((err) => {
      throw err;
    });

    return response.data.success.deposit;
  };

  /**
   * Method used to get the deposit CMCIC payment link of the project.
   * Uses the /project/{projectId}/paiement/link endpoint.
   *
   * @param {Object} user       User of the context provider
   */
  getDepositLink = async (user) => {
    const response = await API.get(
      `/talentdeveloper/project/${user.info.project}/paiement/link`,
      {
        headers: { DOLAPIKEY: AuthService.getToken(user) },
      }
    ).catch((err) => {
      throw err;
    });

    return response.data.success.link;
  };

  /**
   * Method used to mark the deposit as paid.
   * Uses the /project/{projectId}/paiement/deposit endpoint.
   *
   * @param {Object} user                       User of the context provider
   * @param {string} paiementInformations       Paiement informations
   */
  markDepositAsPaid = async (user, paiementInformations) => {
    const response = await API.put(
      `/talentdeveloper/project/${user.info.project}/paiement/deposit`,
      { paiementInformations: paiementInformations },
      {
        headers: { DOLAPIKEY: AuthService.getToken(user) },
      }
    ).catch((err) => {
      throw err;
    });

    return response.data.success;
  };

  /**
   * Method used to validate the file to generate the deposit.
   * Uses the /project/{projectId}/tasks/4/finalize endpoint.
   *
   * @param {Object} user       User of the context provider
   */
  validateFile = async (user) => {
    const response = await API.post(
      `/talentdeveloper/project/${user.info.project}/tasks/4/finalize`,
      {},
      {
        headers: { DOLAPIKEY: AuthService.getToken(user) },
      }
    ).catch((err) => {
      throw err;
    });

    return response.data.success.message;
  };

  /**
   * Method used to mark all notifications as read.
   * Uses the /project/{projectId}/notifications endpoint.
   *
   * @param {Object} user       User of the context provider
   */
  markAllNotificationsAsRead = async (user) => {
    const response = await API.put(
      `/talentdeveloper/project/${user.info.project}/notifications`,
      {},
      {
        headers: { DOLAPIKEY: AuthService.getToken(user) },
      }
    ).catch((err) => {
      throw err;
    });

    return response.data.success;
  };

  /**
   * Method used to get the list of documents of a project.
   * Uses the /project/{projectId}/documents endpoint.
   *
   * @param {Object} user       User of the context provider
   */
  getDocumentList = async (user) => {
    const response = await API.get(
      `/talentdeveloper/project/${user.info.project}/documents`,
      {
        headers: { DOLAPIKEY: AuthService.getToken(user) },
      }
    ).catch((err) => {
      throw err;
    });

    return response.data.success.documents;
  };

  /**
   * Method used to get the detail of a document.
   * Uses the /project/{projectId}/documents/download endpoint.
   *
   * @param {Object} user       User of the context provider
   * @param {string} filepath   File path
   */
  getDocumentDetail = async (user, filepath) => {
    const response = await API.get(
      `/talentdeveloper/project/${user.info.project}/documents/download`,
      {
        headers: { DOLAPIKEY: AuthService.getToken(user) },
        params: { original_file: filepath },
      }
    ).catch((err) => {
      throw err;
    });

    return response.data;
  };

  /**
   * Method used to delete a document.
   * Uses the /project/{projectId}/documents endpoint.
   *
   * @param {Object} user       User of the context provider
   * @param {string} filepath   File path
   */
  deleteDocument = async (user, filepath) => {
    const response = await API.delete(
      `/talentdeveloper/project/${user.info.project}/documents`,
      {
        headers: { DOLAPIKEY: AuthService.getToken(user) },
        params: { original_file: filepath },
      }
    ).catch((err) => {
      throw err;
    });

    return response.data;
  };
}

export default new ProjectService();
