import { makeStyles } from "@material-ui/core";
import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Document, Page, pdfjs } from "react-pdf";
import TalentIcon from "./TalentIcon";
import TalentLoader from "./TalentLoader";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const options = {
  cMapUrl: "cmaps/",
  cMapPacked: true,
};

// Debounce method to avoid overloading resize event
// See (https://www.pluralsight.com/guides/re-render-react-component-on-window-resize)
function debounce(fn, ms) {
  let timer;
  return (_) => {
    clearTimeout(timer);
    timer = setTimeout((_) => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

const useStyle = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: "15px",
    overflow: "hidden",
    boxShadow: "0 2em 4em -2em rgba(34,71,171, 0.2)",
    border: "solid 1px rgba(0,0,0, .15)",
  },
  document: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    backgroundColor: "white",
    "&:hover > div:nth-child(2)": {
      display: "flex",
    },
  },
  page: {
    width: "100%",
    borderRadius: "8px",
    padding: "0",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    flexGrow: "1",
  },
  buttonContainer: {
    display: "none",
    marginBottom: "1rem",
    position: "absolute",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: "8px",
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    "& > button": {
      border: "none",
      backgroundColor: "transparent",
      padding: "0.5rem",
      "&:hover": {
        cursor: "pointer",
      },
      "&:focus": {
        outline: "none",
      },
    },
  },
});

const TalentPdfViewer = ({ pdfFile }) => {
  // Load classes
  const classes = useStyle();

  // Load translations
  const { t } = useTranslation();

  // Ref used to calculate document container width
  const documentRef = useRef(null);

  const [pageWidth, setPageWidth] = useState(300);
  const [numPages, setNumPages] = useState(null);
  const [activePage, setActivePage] = useState(1);

  const onDocumentLoadSuccess = ({ numPages: nextNumPages }) => {
    setNumPages(nextNumPages);
  };

  const changePage = (offset) => {
    setActivePage((prevPageNumber) => prevPageNumber + offset);
  };

  const goNextPage = () => {
    changePage(1);
  };

  const goPrevPage = () => {
    changePage(-1);
  };

  // Listen to document container ref
  useEffect(() => {
    if (documentRef.current) {
      setPageWidth(documentRef.current.clientWidth);
    }
  }, [documentRef]);

  // Listen to resize event toresize the pages
  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setPageWidth(documentRef.current.clientWidth);
    }, 1000);

    window.addEventListener("resize", debouncedHandleResize);

    return (_) => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  });

  return (
    <div className={classes.container} ref={documentRef}>
      <Document
        file={pdfFile}
        onLoadSuccess={onDocumentLoadSuccess}
        options={options}
        className={classes.document}
        loading={<TalentLoader />}
      >
        {Array.from(
          new Array(numPages),
          (el, index) =>
            activePage === index + 1 && (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                className={classes.page}
                loading={<TalentLoader />}
                width={pageWidth}
              />
            )
        )}
        <div className={classes.buttonContainer}>
          <button disabled={activePage <= 1} onClick={goPrevPage}>
            <TalentIcon icon="chevron-left" />
          </button>
          <span>
            {t("Page")} {activePage || (numPages ? 1 : "--")} {t("PageOf")}{" "}
            {numPages || "--"}
          </span>
          <button disabled={activePage >= numPages} onClick={goNextPage}>
            <TalentIcon icon="chevron-right" />
          </button>
        </div>
      </Document>
    </div>
  );
};

export default TalentPdfViewer;
