import { TextField, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import TalentContentContainer from "../core/layout/TalentContentContainer";
import TalentFooterContainer from "../core/layout/TalentFooterContainer";
import { Link, Redirect } from "react-router-dom";
import TalentButton from "../core/TalentButton";
import TalentIcon from "../core/TalentIcon";
import { capitalize, validateEmail } from "../../services/validation.utils";
import authService from "../../services/auth.service";
import { useAlert } from "react-alert";
import TalentLoader from "../core/TalentLoader";

const useStyle = makeStyles({
  btnIcon: {
    marginRight: "0.5em",
  },
});

const EditProfile = ({ auth }) => {
  // Load styles
  const classes = useStyle();

  // Load translation
  const { t } = useTranslation();

  // Load alert provider
  const alert = useAlert();

  // Input states
  const [lastname, setLastname] = useState(auth.user.info.lastname);
  const [firstname, setFirstname] = useState(auth.user.info.firstname);
  const [phone, setPhone] = useState(auth.user.info.phone);
  const [email, setEmail] = useState(auth.user.info.email);

  // Redirection state
  const [redirect, setRedirect] = useState(false);

  // Loading state
  const [loading, setLoading] = useState(false);

  // Errors
  const [fieldsError, setFieldsError] = useState({
    lastname: false,
    firstname: false,
    email: false,
    phone: false,
  });

  // Errors message
  const [fieldsErrorMsg, setFieldsErrorMsg] = useState({
    lastname: "",
    firstname: "",
    email: "",
    phone: "",
  });

  /**
   * Method to check if button should be disabled
   * @returns   bool
   */
  const disableButton = () => {
    return (
      fieldsError.lastname ||
      fieldsError.firstname ||
      fieldsError.email ||
      fieldsError.phone
    );
  };

  /**
   * Method to handle save click
   */
  const handleSave = () => {
    setLoading(true);
    const informations = {
      firstname: firstname,
      lastname: lastname,
      email: email,
      phone: phone,
    };
    authService
      .editMe(auth.user, informations)
      .then((res) => {
        setLoading(false);
        // Update info
        if (res) {
          auth.updateUserInfo(res);
        }
        // Alert
        alert.show(t("ProfileModified"), { type: "success" });
        // Redirect
        setRedirect(true);
      })
      .catch((err) => {
        setLoading(false);
        alert.show(t("ProfileNotModified"), { type: "error" });
        console.error(err);
      });
  };

  return (
    <>
      {redirect && <Redirect to="/profile" />}
      <TalentContentContainer>
        <TextField
          label={t("Lastname")}
          onChange={(e) => {
            if (e.target.value === "") {
              fieldsError.lastname = true;
              setFieldsError(fieldsError);
              fieldsErrorMsg.lastname = t("RequiredField");
              setFieldsErrorMsg(fieldsErrorMsg);
            } else {
              fieldsError.lastname = false;
              setFieldsError(fieldsError);
              fieldsErrorMsg.lastname = "";
              setFieldsErrorMsg(fieldsErrorMsg);
            }
            setLastname(e.target.value.toUpperCase());
          }}
          value={lastname}
          required
          fullWidth
          autoFocus
          error={fieldsError.lastname}
          helperText={fieldsError.lastname ? fieldsErrorMsg.lastname : null}
          data-testid="edit-profile-input-lastname"
        />
        <TextField
          label={t("Firstname")}
          onChange={(e) => {
            if (e.target.value === "") {
              fieldsError.firstname = true;
              setFieldsError(fieldsError);
              fieldsErrorMsg.firstname = t("RequiredField");
              setFieldsErrorMsg(fieldsErrorMsg);
            } else {
              fieldsError.firstname = false;
              setFieldsError(fieldsError);
              fieldsErrorMsg.firstname = "";
              setFieldsErrorMsg(fieldsErrorMsg);
            }
            setFirstname(capitalize(e.target.value));
          }}
          value={firstname}
          required
          fullWidth
          error={fieldsError.firstname}
          helperText={fieldsError.firstname ? fieldsErrorMsg.firstname : null}
          data-testid="edit-profile-input-firstname"
        />
        <TextField
          label={t("Email")}
          onChange={(e) => {
            const value = e.target.value;
            if (value === "") {
              fieldsError.email = true;
              setFieldsError(fieldsError);
              fieldsErrorMsg.email = t("RequiredField");
              setFieldsErrorMsg(fieldsErrorMsg);
            } else {
              if (validateEmail(value)) {
                fieldsError.email = false;
                setFieldsError(fieldsError);
                fieldsErrorMsg.email = "";
                setFieldsErrorMsg(fieldsErrorMsg);
              } else {
                fieldsError.email = true;
                setFieldsError(fieldsError);
                fieldsErrorMsg.email = t("InvalidEmail");
                setFieldsErrorMsg(fieldsErrorMsg);
              }
            }
            setEmail(value);
          }}
          value={email}
          required
          fullWidth
          error={fieldsError.email}
          helperText={fieldsError.email ? fieldsErrorMsg.email : null}
          data-testid="edit-profile-input-email"
        />
        <TextField
          label={t("Phone")}
          onChange={(e) => {
            if (e.target.value === "") {
              fieldsError.phone = true;
              setFieldsError(fieldsError);
              fieldsErrorMsg.phone = t("RequiredField");
              setFieldsErrorMsg(fieldsErrorMsg);
            } else {
              fieldsError.phone = false;
              setFieldsError(fieldsError);
              fieldsErrorMsg.phone = "";
              setFieldsErrorMsg(fieldsErrorMsg);
            }
            setPhone(e.target.value);
          }}
          value={phone}
          required
          fullWidth
          error={fieldsError.phone}
          helperText={fieldsError.phone ? fieldsErrorMsg.phone : null}
          data-testid="edit-profile-input-phone"
        />
      </TalentContentContainer>
      <TalentFooterContainer>
        {loading && (
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <TalentLoader />
          </div>
        )}
        <Link to="/profile" style={{ textDecoration: "none" }}>
          <TalentButton
            data-testid="edit-profile-btn-cancel"
            style={{ margin: "0.5em" }}
          >
            <TalentIcon icon="x-circle" className={classes.btnIcon} />
            {t("Cancel")}
          </TalentButton>
        </Link>
        <TalentButton
          data-testid="edit-profile-btn-save"
          disabled={disableButton() || loading}
          onClick={() => {
            handleSave();
          }}
        >
          <TalentIcon
            icon="check-circle"
            className={classes.btnIcon}
            style={{ margin: "0.5em" }}
          />
          {t("Save")}
        </TalentButton>
      </TalentFooterContainer>
    </>
  );
};

export default EditProfile;
