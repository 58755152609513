import React from "react";
import { useTranslation } from "react-i18next";
import ELearning from "./ELearning";
import { Switch, Route } from "react-router-dom";
import ELearningCourse from "./ELearningCourse";
import TalentContentContainer from "../core/layout/TalentContentContainer";

const Dashboard = () => {
  // Load translation
  const { t } = useTranslation();

  return (
    <Switch>
      <Route path="/courses/cv/format">
        <ELearningCourse
          title={t("CourseNameCVFormat")}
          documentName="CV_FORMAT"
        />
      </Route>
      <Route path="/courses/cv/body">
        <ELearningCourse title={t("CourseNameCVBody")} documentName="CV_BODY" />
      </Route>
      <Route path="/courses/cv/digital">
        <ELearningCourse
          title={t("CourseNameCVDigital")}
          documentName="CV_DIGITAL"
        />
      </Route>
      <Route path="/courses/cl/format">
        <ELearningCourse
          title={t("CourseNameCLFormat")}
          documentName="CL_FORMAT"
        />
      </Route>
      <Route path="/courses/cl/body">
        <ELearningCourse title={t("CourseNameCLBody")} documentName="CL_BODY" />
      </Route>
      <Route path="/courses/comm/verbal">
        <ELearningCourse
          title={t("CourseNameCommVerbal")}
          documentName="COMM_VERBAL"
        />
      </Route>
      <Route path="/courses/comm/non-verbal">
        <ELearningCourse
          title={t("CourseNameCommNonVerbal")}
          documentName="COMM_NON_VERBAL"
        />
      </Route>
      <Route path="/courses/other/glossary">
        <ELearningCourse
          title={t("CourseNameGlossary")}
          documentName="GLOSSARY"
        />
      </Route>
      <Route path="/courses/other/interview">
        <ELearningCourse
          title={t("CourseNameInterview")}
          documentName="INTERVIEW"
        />
      </Route>
      <Route path="/courses/other/known-how">
        <ELearningCourse
          title={t("CourseNameKnownHow")}
          documentName="KNOWN_HOW"
        />
      </Route>

      <Route path={["/", "/login"]} exact>
        <TalentContentContainer>
          <ELearning />
        </TalentContentContainer>
      </Route>
    </Switch>
  );
};

export default Dashboard;
