import { makeStyles, Button, Box } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useTranslation } from "react-i18next";

const useStyle = makeStyles({
  btnContainer: {
    display: "flex",
    justifyContent: "center",
    padding: "1rem 0",
  },
  btn: {
    color: "#858ca0",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    letterSpacing: 3,
  },
});

const TalentGoBackButton = (props) => {
  // Load classes
  const classes = useStyle();

  // Load translation
  const { t } = useTranslation();

  // Load history
  const history = useHistory();

  return (
    <Box className={classes.btnContainer}>
      <Button
        className={classes.btn}
        startIcon={<ArrowBackIcon />}
        onClick={() => history.goBack()}
      >
        {t("GoBack")}
      </Button>
    </Box>
  );
};

export default TalentGoBackButton;
