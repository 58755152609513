import React, { useState, useEffect } from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import TalentGoBackButton from "../core/TalentGoBackButton";
import TalentSubtitle from "../core/TalentSubtitle";
import { useTranslation } from "react-i18next";
import TalentCommercialName from "../core/TalentCommercialName";
import TalentDividerLine from "../core/TalentDividerLine";
import { useAuth } from "../../context/auth-context";
import projectService from "../../services/project.service";
import TalentButton from "../core/TalentButton";
import { Redirect } from "react-router";
import TalentWhiteContainer from "../core/layout/TalentWhiteContainer";
import TalentHeaderContainer from "../core/layout/TalentHeaderContainer";
import TalentFooterContainer from "../core/layout/TalentFooterContainer";

const useStyle = makeStyles({
  root: {},
  propalContainer: {
    wordBreak: "break-all",
    padding: "2em 0",
  },
  propalSubtitle: {
    marginTop: "1em",
  },
  subbox: {
    margin: "1em .5em",
  },
});

const PropalViewer = ({ propal, reloadParent }) => {
  // Load style
  const classes = useStyle();

  // Load translation
  const { t } = useTranslation();

  // Load auth
  const auth = useAuth();

  // Appointment date
  const [redirect, setRedirect] = useState(false);

  // Deposit firstline
  const [firstline, setFirstline] = useState(null);

  // Method used to mark report as validated
  const validateReport = () => {
    projectService.validateReport(auth.user).then(() => {
      reloadParent();
      setRedirect(true);
    });
  };

  // Load firstline of the propal
  useEffect(() => {
    if (propal && propal.lines && propal.lines[0]) {
      setFirstline(propal.lines[0]);
    }
  }, [propal]);

  return (
    <>
      {redirect && <Redirect to="/" />}
      <TalentGoBackButton />

      <TalentWhiteContainer>
        <TalentHeaderContainer>
          <TalentSubtitle title={t("Propal")} />

          <Box className={classes.propalSubtitle}>
            {t("RVReportDesc")} <TalentCommercialName withLink={true} />
          </Box>
        </TalentHeaderContainer>
        <TalentDividerLine />
        <div className={classes.propalContainer}>
          <TalentHeaderContainer>
            <Typography variant="h3" color="primary" component="h2">
              {firstline && firstline.label}
            </Typography>
          </TalentHeaderContainer>
          <div
            dangerouslySetInnerHTML={{
              __html: firstline && firstline.short_description,
            }}
            className={`${classes.reportContainer} overInlineCss`}
          />

          <div
            dangerouslySetInnerHTML={{
              __html: firstline && firstline.description,
            }}
            className={`${classes.reportContainer} overInlineCss`}
          />
        </div>
        <TalentDividerLine />
        <TalentFooterContainer>
          <Box className={classes.subbox}>
            <Typography variant="subtitle1" color="secondary" component="p">
              {t("TotalPrice")}
            </Typography>
            <Typography variant="h4" color="secondary" component="p">
              {propal && propal.total} €
            </Typography>
          </Box>
          <Box className={classes.subbox}>
            <Typography variant="subtitle1" color="primary" component="p">
              {t("FirstDepositOf")}
            </Typography>
            <Typography variant="h4" color="primary" component="p">
              {propal && propal.deposit} €
            </Typography>
          </Box>
          <Box className={classes.subbox}>
            <TalentButton variant="contained" onClick={validateReport}>
              {t("ValidateReport")}
            </TalentButton>
          </Box>
        </TalentFooterContainer>
      </TalentWhiteContainer>
      {redirect && <Redirect to="/" />}
      <TalentGoBackButton />
    </>
  );
};

export default PropalViewer;
