import React, { useState, useEffect } from "react";
import { makeStyles, Box } from "@material-ui/core";
import TalentGoBackButton from "../core/TalentGoBackButton";
import TalentSubtitle from "../core/TalentSubtitle";
import { useTranslation } from "react-i18next";
import TalentCommercialName from "../core/TalentCommercialName";
import TalentDividerLine from "../core/TalentDividerLine";
import { useAuth } from "../../context/auth-context";
import projectService from "../../services/project.service";
import moment from "../../services/moment.js";
import TalentButton from "../core/TalentButton";
import { Link } from "react-router-dom";
import TalentWhiteContainer from "../core/layout/TalentWhiteContainer";
import TalentHeaderContainer from "../core/layout/TalentHeaderContainer";
import TalentFooterContainer from "../core/layout/TalentFooterContainer";

const useStyle = makeStyles({
  reportContainer: {
    padding: "2em 0",
  },
  reportSubtitle: {
    marginTop: "1em",
  },
});

const ReportViewer = ({ report, reloadParent }) => {
  // Load style
  const classes = useStyle();

  // Load translation
  const { t, i18n } = useTranslation();

  // Load auth
  const auth = useAuth();

  // Appointment date
  const [formatedDate, setFormatedDate] = useState(null);

  // Component mount
  useEffect(() => {
    projectService
      .getStep2Appointment(auth.user)
      .then((res) => {
        if (res != null) {
          moment.locale(i18n.language);
          setFormatedDate(moment(res.date_start).fromNow());
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [auth, i18n]);

  return (
    <>
      <TalentGoBackButton />

      <TalentWhiteContainer>
        <TalentHeaderContainer>
          <TalentSubtitle title={t("AppoitmentReport")} />

          <Box className={classes.reportSubtitle}>
            {t("RVReportDesc") + (formatedDate !== null ? formatedDate : "")}
            <TalentCommercialName withLink={true} />
          </Box>
        </TalentHeaderContainer>

        <TalentDividerLine />

        <div
          dangerouslySetInnerHTML={{ __html: report && report.content }}
          className={`${classes.reportContainer} overInlineCss`}
        />

        <TalentDividerLine />

        <TalentFooterContainer>
          <Link to="/propal" style={{ textDecoration: "none" }}>
            <TalentButton>{t("Next")}</TalentButton>
          </Link>
        </TalentFooterContainer>
      </TalentWhiteContainer>
      <TalentGoBackButton />
    </>
  );
};

export default ReportViewer;
