import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyle = makeStyles({
  root: {
    backgroundColor: "white",
    margin: "0",
    borderRadius: "15px",
    textAlign: "center",
    maxWidth: "100%",
    //height: "100%",
    padding: "2em",
    boxShadow: "0 2em 4em -2em rgba(34,71,171, 0.2)",
    border: "solid 1px rgba(0,0,0, .15)",
  },
});

const TalentWhiteContainer = ({ children, ...props }) => {
  const classes = useStyle();
  return (
    <div className={classes.root} {...props}>
      {children}
    </div>
  );
};

export default TalentWhiteContainer;
