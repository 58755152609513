import React, { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
import TalentGoBackButton from "../core/TalentGoBackButton";
import TalentWhiteContainer from "../core/layout/TalentWhiteContainer";
import TalentHeaderContainer from "../core/layout/TalentHeaderContainer";

const DetailFlatFee = ({ propal }) => {
  const [firstline, setFirstline] = useState(null);

  useEffect(() => {
    if (propal.lines !== null) {
      setFirstline(propal.lines[0]);
    }
  }, [propal]);
  return (
    <>
      <TalentGoBackButton />
      <TalentWhiteContainer>
        <TalentHeaderContainer>
          <Typography variant="h3" color="primary" component="h2">
            {firstline && firstline.label}
          </Typography>
        </TalentHeaderContainer>
        {firstline && (
          <div
            dangerouslySetInnerHTML={{ __html: firstline.description }}
            className="overInlineCss"
          />
        )}
      </TalentWhiteContainer>
      <TalentGoBackButton />
    </>
  );
};

export default DetailFlatFee;
