/**
 * Capitalize a string.
 *
 * @param   {String}  s       Capitalize a string
 * @returns {String}          Capitalized string
 */
export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

/**
 * Verify if an email is valide
 *
 * @param   {String}  email        Email to verify
 * @returns {boolean}
 */
export const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

/**
 * Verify if a year is valide
 *
 * @param   {String}  year        Year to verify
 * @returns {boolean}
 */
export const validateYear = (year) => {
  const re = /^\d{4}$/;
  return re.test(year);
};

/**
 * Verify if a string is numeric
 *
 * @param   {String}  num         String to verify
 * @returns {boolean}
 */
export const isNumeric = (num) => {
  const re = /^[0-9]*$/;
  return re.test(num);
};

/**
 * Verify if a password is valid.
 *
 * @param   {String}  password    Password to verify
 * @returns {boolean}
 */
export const validatePassword = (password) => {
  return password.length >= 6;
};
