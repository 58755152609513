import React from "react";
import { makeStyles, Typography, Fade, Slide } from "@material-ui/core";

const useStyle = makeStyles({
  root: {
    backgroundColor: "#0b1941",
    margin: "0",
    borderRadius: "15px",
    textAlign: "center",
    width: "100%",
    //height: "100%",
    flex: "1",
    padding: "0 2em 2em",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0 2em 4em -2em rgba(34,71,171, 0.2)",
    border: "solid 1px rgba(0,0,0, .15)",
  },
  firstText: {
    color: "white",
    margin: ".6em 0",
  },
  secondText: {
    color: "white",
    margin: "0",
  },
  img: {
    height: "20vh",
    maxHeight: "384px",
    margin: "0",
  },
});

const TalentBlueContainer = ({
  img,
  imgAlt,
  title,
  slide,
  children,
  ...props
}) => {
  // Load styles
  const classes = useStyle();

  return (
    <div className={classes.root} {...props}>
      {slide ? (
        <Slide direction="right" in={true}>
          <img src={img} alt={imgAlt} className={classes.img} />
        </Slide>
      ) : (
        <Fade in={true}>
          <img src={img} alt={imgAlt} className={classes.img} />
        </Fade>
      )}

      <Typography variant="h1" component="h1" className={classes.firstText}>
        {title}
      </Typography>
      <Typography variant="body1" component="p" className={classes.secondText}>
        {children}
      </Typography>
    </div>
  );
};

export default TalentBlueContainer;
