import { makeStyles, Checkbox, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, useHistory } from "react-router";
import TalentDashboardTitle from "../TalentDashboardTitle";
import TalentButton from "../TalentButton";
import CGVContentFR from "./CGVContentFR";
import CGVContentEN from "./CGVContentEN";
import CGVContentES from "./CGVContentES";
import TalentWhiteContainer from "../layout/TalentWhiteContainer";
import TalentHeaderContainer from "../layout/TalentHeaderContainer";
import TalentFooterContainer from "../layout/TalentFooterContainer";

const useStyle = makeStyles({
  cgvContainer: {},
  cgvCheckbox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "2rem",
  },
});

const TalentCGV = ({ buttonAction }) => {
  const { t, i18n } = useTranslation();
  const classes = useStyle();
  const history = useHistory();

  const [activeButton, setActiveButton] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const handleClick = () => {
    setRedirect(true);
    history.push("/paiement-cgv");
  };

  // Handle CGV checkbox change
  const handleChange = (e) => {
    setActiveButton(e.target.checked);
  };

  // Load content in function of languages
  const loadContent = () => {
    switch (i18n.language) {
      case "fr-FR":
        return <CGVContentFR />;
      case "es-ES":
        return <CGVContentES />;
      default:
        return <CGVContentEN />;
    }
  };

  return (
    <>
      <TalentHeaderContainer>
        {redirect && <Redirect to="paiement" />}
        <TalentDashboardTitle title={t("Contract")} />
      </TalentHeaderContainer>

      <TalentWhiteContainer>
        <div className={`${classes.cgvContainer} overInlineCss`}>
          {loadContent()}
        </div>

        <div className={classes.cgvCheckbox}>
          <Checkbox onChange={handleChange} />
          <Typography variant="button" component="span" color="secondary">
            {t("AcceptCGV")}
          </Typography>
        </div>

        <TalentFooterContainer>
          <TalentButton
            onClick={handleClick}
            variant={activeButton ? "contained" : "outlined"}
            disabled={!activeButton}
          >
            {t("Finalize")}
          </TalentButton>
        </TalentFooterContainer>
      </TalentWhiteContainer>
    </>
  );
};

export default TalentCGV;
