import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  makeStyles,
} from "@material-ui/core";
import AuthService from "../../services/auth.service";
import { useAuth } from "../../context/auth-context";
import { useTranslation } from "react-i18next";
import TalentButton from "../core/TalentButton";
import TalentDashboardTitle from "../core/TalentDashboardTitle";
import TalentContentContainer from "../core/layout/TalentContentContainer";
import TalentWhiteContainer from "../core/layout/TalentWhiteContainer";
import TalentFooterContainer from "../core/layout/TalentFooterContainer";
import TalentHeaderContainer from "../core/layout/TalentHeaderContainer";
import TalentIcon from "../core/TalentIcon";
import { Route, Switch, useRouteMatch } from "react-router";
import EditProfile from "./EditProfile";
import TalentLoader from "../core/TalentLoader";
import EditPassword from "./EditPassword";
import { Link } from "react-router-dom";

const useStyle = makeStyles({
  profile: {
    width: "100%",
  },
  btnIcon: {
    marginRight: "0.5em",
  },
});

const Profile = () => {
  const classes = useStyle();
  const match = useRouteMatch();

  // Load translation
  const { t } = useTranslation();

  // Load auth
  const auth = useAuth();

  const [alert, setAlert] = useState(false);
  const [loading, setLoading] = useState(true);

  // Action on component mount
  useEffect(() => {
    // Get the user informations
    AuthService.getMe(auth.user)
      .then((res) => {
        setLoading(false);
        // Update user datas from local storage and context
        if (res.data.success.me) {
          auth.updateUserInfo(res.data.success.me);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  }, [auth]);

  // Handle delete click
  const handleDeleteClick = () => {
    AuthService.delete(auth.user)
      .then((res) => {
        // Logout
        auth.signout();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <>
      <TalentHeaderContainer>
        <TalentDashboardTitle title={t("MyProfile")} />
      </TalentHeaderContainer>

      <TalentWhiteContainer>
        {loading ? (
          <TalentLoader />
        ) : (
          <Switch>
            <Route path={`${match.url}/edit`} exact>
              <EditProfile auth={auth} />
            </Route>
            <Route path={`${match.url}/edit-password`} exact>
              <EditPassword />
            </Route>
            <Route path={`${match.url}`} exact>
              <TalentContentContainer>
                <div className={classes.profile}>
                  <p>
                    {t("Lastname")} : {auth.user.info.lastname}
                  </p>
                  <p>
                    {t("Firstname")} : {auth.user.info.firstname}
                  </p>
                  <p>
                    {t("Email")} : {auth.user.info.email}
                  </p>
                  <p>
                    {t("Phone")} : {auth.user.info.phone}
                  </p>
                </div>
              </TalentContentContainer>

              <TalentFooterContainer>
                <TalentButton
                  onClick={() => auth.signout()}
                  style={{ margin: "0.5em" }}
                >
                  <TalentIcon icon="log-out" className={classes.btnIcon} />
                  {t("Disconnect")}
                </TalentButton>
                <TalentButton
                  onClick={() => setAlert(true)}
                  data-testid="profile-btn-delete"
                  style={{ margin: "0.5em" }}
                >
                  <TalentIcon icon="trash-2" className={classes.btnIcon} />
                  {t("DeleteAccount")}
                </TalentButton>
                <Link
                  to={`${match.url}/edit`}
                  style={{ textDecoration: "none" }}
                >
                  <TalentButton
                    data-testid="profile-btn-edit"
                    style={{ margin: "0.5em" }}
                  >
                    <TalentIcon icon="edit-3" className={classes.btnIcon} />
                    {t("EditProfile")}
                  </TalentButton>
                </Link>
                <Link
                  to={`${match.url}/edit-password`}
                  style={{ textDecoration: "none" }}
                >
                  <TalentButton
                    data-testid="profile-btn-edit-password"
                    style={{ margin: "0.5em" }}
                  >
                    <TalentIcon icon="lock" className={classes.btnIcon} />
                    {t("EditPassword")}
                  </TalentButton>
                </Link>
              </TalentFooterContainer>

              <Dialog
                open={alert}
                onClose={() => setAlert(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {t("DeleteAccountConfirmation")}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {t("DeleteAccountConfirmationDesc")}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <TalentButton onClick={() => setAlert(false)}>
                    {t("Cancel")}
                  </TalentButton>
                  <TalentButton
                    onClick={handleDeleteClick}
                    data-testid="profile-confirm-btn-delete"
                  >
                    {t("ConfirmDeleteAccount")}
                  </TalentButton>
                </DialogActions>
              </Dialog>
            </Route>
          </Switch>
        )}
      </TalentWhiteContainer>
    </>
  );
};

export default Profile;
