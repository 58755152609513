import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../context/auth-context";
import sentImg from "../../assets/img/assets-img-lobby.svg";
import projectService from "../../services/project.service";
import moment from "../../services/moment.js";
import TalentBlueContainer from "../core/layout/TalentBlueContainer";
import TalentLoader from "../core/TalentLoader";

const Step2Confirmed = () => {
  // Load translation
  const { t, i18n } = useTranslation();

  // Load auth
  const auth = useAuth();

  // Appointment date
  const [formatedDate, setFormatedDate] = useState(null);

  // Laoding state
  const [loading, setLoading] = useState(true);

  // Component mount
  useEffect(() => {
    projectService
      .getStep2Appointment(auth.user)
      .then((res) => {
        if (res != null) {
          moment.locale(i18n.language);
          setFormatedDate(moment(res.date_start).fromNow());
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [auth, i18n]);

  return (
    <TalentBlueContainer
      img={sentImg}
      imgAlt="Appointment confirmed"
      title={t("S2AppointmentConfirmed")}
    >
      {loading ? (
        <TalentLoader />
      ) : (
        t("S2AppointmentConfirmedDate") +
        (formatedDate !== null ? formatedDate : "")
      )}
    </TalentBlueContainer>
  );
};

export default Step2Confirmed;
