import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import TalentWhiteContainer from "../core/layout/TalentWhiteContainer";
import TalentButton from "../core/TalentButton";
import { Typography, TextField } from "@material-ui/core";
import TalentLoader from "../core/TalentLoader";
import { validatePassword } from "../../services/validation.utils";
import authService from "../../services/auth.service";
import { useAlert } from "react-alert";
import Alert from "@material-ui/lab/Alert";

const ResetPassword = () => {
  const { t } = useTranslation();
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorMsg, setPasswordErrorMsg] = useState("");
  const [repassword, setRepassword] = useState("");
  const [repasswordError, setRepasswordError] = useState(false);
  const [repasswordErrorMsg, setRepasswordErrorMsg] = useState("");
  const [apiError, setApiError] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState("");

  /**
   * Method called after reset button click
   */
  const handleClick = () => {
    setApiErrorMessage("");
    setApiError(false);
    setLoading(true);
    // Get url parameter used for the api call
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get("email");
    const token = urlParams.get("token");
    authService
      .resetPassword(email, password, token)
      .then((res) => {
        alert.show(t("PasswordReseted"), { type: "success" });
        setLoading(false);
        // We disable the button to avoid pressing it again
        setDisabled(true);
      })
      .catch((err) => {
        setLoading(false);
        let errormsg = "";
        switch (err.response.status) {
          case 400:
            errormsg = t("WrongPasswordOrToken");
            break;
          case 404:
            errormsg = t("EmailNotFound");
            break;
          default:
            errormsg = t("ResetPasswordNotWorking");
            break;
        }
        console.error(err);
        setApiErrorMessage(errormsg);
        setApiError(true);
        alert.show(errormsg, { type: "error" });
      });
  };

  /**
   * Method to manage password error and message state
   *
   * @param {String} inputPassword        Password input value
   * @param {String} inputRepassword      Repassword input value
   */
  const managePasswordError = (inputPassword, inputRepassword) => {
    let tmpPasswordError = false;
    let tmpPasswordErrorMessage = "";
    if (!validatePassword(inputPassword)) {
      tmpPasswordError = true;
      tmpPasswordErrorMessage = t("PasswordValidationError");
    }
    setPasswordError(tmpPasswordError);
    setPasswordErrorMsg(tmpPasswordErrorMessage);

    // Manage button diable state

    if (
      tmpPasswordError ||
      repasswordError ||
      inputPassword === "" ||
      inputRepassword === "" ||
      inputPassword !== inputRepassword
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  /**
   * Method to manage repassword error and message state
   *
   * @param {String} inputPassword        Password input value
   * @param {String} inputRepassword      Repassword input value
   */
  const manageRepasswordError = (inputPassword, inputRepassword) => {
    let tmpRepasswordError = false;
    let tmpRepasswordErrorMessage = "";
    if (inputPassword !== inputRepassword) {
      tmpRepasswordError = true;
      tmpRepasswordErrorMessage = t("RePasswordNotCorresponding");
    }

    setRepasswordError(tmpRepasswordError);
    setRepasswordErrorMsg(tmpRepasswordErrorMessage);

    // Manage button diable state
    if (
      tmpRepasswordError ||
      passwordError ||
      inputPassword === "" ||
      inputRepassword === "" ||
      inputPassword !== inputRepassword
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  return (
    <TalentWhiteContainer
      style={{
        color: "white",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="h2" component="h2" color="primary">
        {t("ResetPassword")}
      </Typography>
      <Typography
        variant="subtitle2"
        component="p"
        color="textSecondary"
        style={{ marginTop: "1em" }}
      >
        {t("ResetPasswordDesc")}
      </Typography>
      <TextField
        label={t("Password")}
        onChange={(e) => {
          const value = e.target.value;
          setPassword(value);
          managePasswordError(value, repassword);
        }}
        defaultValue={password}
        required
        fullWidth
        type="password"
        error={passwordError}
        helperText={passwordErrorMsg ? passwordErrorMsg : null}
      />
      <TextField
        label={t("RePassword")}
        onChange={(e) => {
          const value = e.target.value;
          setRepassword(value);
          manageRepasswordError(password, value);
        }}
        defaultValue={repassword}
        required
        fullWidth
        type="password"
        error={repasswordError}
        helperText={repasswordErrorMsg ? repasswordErrorMsg : null}
      />

      {loading && <TalentLoader />}
      {apiError && (
        <Alert severity="error" style={{ marginBottom: "1em" }}>
          {apiErrorMessage}
        </Alert>
      )}

      <TalentButton
        variant="contained"
        onClick={handleClick}
        disabled={disabled}
      >
        {t("Reset")}
      </TalentButton>
    </TalentWhiteContainer>
  );
};

export default ResetPassword;
