import React, { useState } from "react";
import TalentButton from "../core/TalentButton";
import TalentStepper from "../core/TalentStepper";
import { useTranslation } from "react-i18next";
import {
  Typography,
  TextField,
  Checkbox,
  Box,
  CircularProgress,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import AuthService from "../../services/auth.service";
import TalentHeaderContainer from "../core/layout/TalentHeaderContainer";
import TalentContentContainer from "../core/layout/TalentContentContainer";
import TalentFooterContainer from "../core/layout/TalentFooterContainer";
import TalentWhiteContainer from "../core/layout/TalentWhiteContainer";

const RegisterStep3 = (props) => {
  const { t } = useTranslation();

  const [checked, setChecked] = useState(false);
  const [passError, setPassErrors] = useState(false);
  const [requiredPassError, setRequiredPassError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState("");

  const { values, handlers } = props;

  const upperFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const formatValuesForApi = () => {
    let register = {};
    register.lastname = values.lastname.toUpperCase();
    register.firstname = upperFirstLetter(values.firstname);
    register.phone = values.phone;
    register.email = values.email;
    register.password = values.password;
    register.language = values.language;
    register.nationality = upperFirstLetter(values.nationality);
    register.formation_title = upperFirstLetter(values.formTitle);
    register.formation_year = values.formYear;
    register.formation_country = upperFirstLetter(values.formCountry);
    register.formation_type = values.formType ? "2" : "1";
    register.formation_duration = values.formDuration;
    register.formation_detail = values.formDetail;

    return register;
  };

  // Handle registration form submit
  const handleSubmit = async () => {
    if (values.password === "") {
      setRequiredPassError(true);
    } else {
      setLoading(true);
      setApiError(false);
      setApiErrorMessage("");

      // API call
      try {
        await AuthService.register(formatValuesForApi());
        // Next step if ok
        props.nextStep();
      } catch (err) {
        let errormsg = "";
        if (err.response) {
          errormsg = err.message;
        } else {
          errormsg = t("ConnectionProblem");
        }
        console.error(err);
        setApiError(true);
        setApiErrorMessage(errormsg);
        setLoading(false);
      }
    }
  };

  // Handle checkbox change
  const handleChange = () => {
    setChecked(!checked);
  };

  // Submit btn visual
  const submitBtn =
    checked && !passError ? (
      <TalentButton
        variant="contained"
        onClick={handleSubmit}
        disabled={loading}
        data-testid="register-btn-register"
      >
        {t("Finish")}
      </TalentButton>
    ) : (
      <TalentButton disabled>{t("Finish")}</TalentButton>
    );

  // Handle repassword input change
  const handleRepasswordChange = (e) => {
    handlers.setRepassword(e.target.value);
    if (values.password !== e.target.value) {
      setPassErrors(true);
    } else {
      setPassErrors(false);
    }
  };

  // Handle password input change
  const handlePasswordChange = (e) => {
    handlers.setPassword(e.target.value);
    setRequiredPassError(false);
  };

  // Set conditional visual for repassword input
  const repasswordInput = !passError ? (
    <TextField
      label={t("RePassword")}
      onChange={handleRepasswordChange}
      value={values.repassword}
      required
      type="password"
      fullWidth
      data-testid="register-input-repassword"
    />
  ) : (
    <TextField
      label={t("RePassword")}
      onChange={handleRepasswordChange}
      value={values.repassword}
      required
      error
      helperText={t("RePasswordNotCorresponding")}
      type="password"
      fullWidth
      data-testid="register-input-repassword"
    />
  );

  return (
    <TalentWhiteContainer>
      <TalentHeaderContainer>
        <div className="LeftCenterTxt" style={{ flex: 1 }}>
          <Typography
            variant="subtitle1"
            component="span"
            color="textSecondary"
          >
            {t("CreateAccount")}
          </Typography>

          <Typography variant="h1" component="h1" color="primary">
            {t("StepEnd")}
          </Typography>
        </div>

        <TalentStepper
          activeStep={3}
          stepsTotal={props.stepsTotal}
        ></TalentStepper>
      </TalentHeaderContainer>

      <TalentContentContainer>
        <TextField
          label={t("Password")}
          onChange={handlePasswordChange}
          value={values.password}
          required
          type="password"
          fullWidth
          error={requiredPassError}
          helperText={requiredPassError ? t("RequiredField") : null}
          data-testid="register-input-password"
        />

        {repasswordInput}
      </TalentContentContainer>

      <TalentFooterContainer>
        <Box margin="2rem 0" color="secondary.main">
          {t("AcceptCGU")}
          <Checkbox
            checked={checked}
            onChange={handleChange}
            data-testid="register-chkbx-cgv"
          />
        </Box>

        {loading && <CircularProgress size={24} />}
        {apiError && <Alert severity="error">{apiErrorMessage}</Alert>}

        <TalentButton onClick={() => props.prevStep()} disabled={loading}>
          {t("Previous")}
        </TalentButton>
        {submitBtn}
      </TalentFooterContainer>
    </TalentWhiteContainer>
  );
};

export default RegisterStep3;
