import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

const useStyle = makeStyles({
  rootContainer: {
    width: "100%",
    flexGrow: "0",
    margin: "1em 0 0",
    textAlign: "center",
  },
  title: {
    textAlign: "center",
    marginBottom: "0",
  },
  lineContainer: {
    height: "3vh",
    display: "flex",
    justifyContent: "center",
  },
  line: {
    height: "100%",
    width: "1px",
    backgroundColor: "#858ca0",
  },
});

const TalentDashboardTitle = (props) => {
  // Load style
  const classes = useStyle();

  return (
    <div className={classes.rootContainer}>
      <Typography
        variant="subtitle1"
        component="h1"
        color="textSecondary"
        className={classes.title}
      >
        {props.title.toUpperCase()}
      </Typography>
      <div className={classes.lineContainer}>
        <hr className={classes.line} />
      </div>
    </div>
  );
};

export default TalentDashboardTitle;
