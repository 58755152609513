// Hook (useAuth.js)
import React, { useState, useContext, createContext } from "react";
import AuthService from "../services/auth.service";

// Create the authContext
const authContext = createContext();

/**
 * Provider component that wraps your app and makes auth object
 * available to any child component that calls useAuth().
 */
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

/**
 * Hook for child components to get the auth object
 * and re-render when it changes.
 */
export const useAuth = () => {
  return useContext(authContext);
};

/**
 * Provider hook that creates auth object and handles state
 */
function useProvideAuth() {
  const [user, setUser] = useState(
    localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null
  );

  // Method to signin
  const signin = async (email, password) => {
    try {
      // Connect to the api
      const response = await AuthService.connect(email, password);
      // Encrypt the token
      const token = AuthService.encrypt(response.data.success.token);
      let tmpUser = { token: token };

      // Get the /me additional information to locally store user informations
      try {
        const response = await AuthService.getMe(tmpUser);
        tmpUser.info = response.data.success.me;
        // Save the user to the local storage
        localStorage.setItem("user", JSON.stringify(tmpUser));
        // Update state
        setUser(tmpUser);
      } catch (err) {
        throw new Error(err);
      }
    } catch (err) {
      throw new Error(err);
    }
  };

  // Method to signup
  const signup = (email, password) => {
    // TODO : remove the fake auth
    return setUser({ email: email, password: password });
  };

  // Method to signout
  const signout = () => {
    localStorage.removeItem("user");
    return setUser(null);
  };

  // Method to reset password
  const sendPasswordResetEmail = (email) => {
    return;
  };

  // Method to update user info
  const updateUserInfo = (newInfo) => {
    let newUser = user;
    newUser.info = newInfo;
    setUser(newUser);
    localStorage.setItem("user", JSON.stringify(newUser));
  };

  // Return the user object and auth methods
  return {
    user,
    signin,
    signup,
    signout,
    sendPasswordResetEmail,
    updateUserInfo,
  };
}
