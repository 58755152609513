import "./App.scss";
import "./services/multilingual";
import { useAuth } from "./context/auth-context";
import { Suspense } from "react";
import AuthenticatedApp from "./components/AuthenticatedApp";
import UnauthenticatedApp from "./components/UnauthenticatedApp";
import TalentSplashscreen from "./components/core/TalentSplashscreen";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import TalentAlert from "./components/core/TalentAlert";
import { useMaintenance } from "./context/maintenance-context";
import UnderMaintenance from "./components/UnderMaintenance";

function App() {
  // Get auth state
  const auth = useAuth();

  // Get maintenance state
  const maintenance = useMaintenance();

  // Alert configuration
  const alertOptions = {
    position: positions.TOP_RIGHT,
    timeout: 5000,
    transition: transitions.FADE,
  };

  /**
   * Check if the site is under maintenance
   *
   * @returns boolean
   */
  const isSiteUnderMaintenance = () => {
    // If we have api error, we put the site in maintenance
    if (maintenance.apiError) {
      return maintenance.apiError;
    } else {
      // If site is under maintenance, we can authorize one ip set with dolibarr
      return (
        maintenance.underMaintenance &&
        maintenance.requestIp !== maintenance.exceptionIp
      );
    }
  };

  return (
    <Suspense fallback={<TalentSplashscreen />}>
      <AlertProvider template={TalentAlert} {...alertOptions}>
        {maintenance.loading ? (
          <TalentSplashscreen />
        ) : isSiteUnderMaintenance() ? (
          <UnderMaintenance />
        ) : auth.user ? (
          <AuthenticatedApp />
        ) : (
          <UnauthenticatedApp />
        )}
      </AlertProvider>
    </Suspense>
  );
}

export default App;
